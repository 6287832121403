import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReactDOMServer from "react-dom/server";
import Report from "./ErrorReport";
import Axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../ScreenSize/ScreenSize";

import { setInfoClicked } from "../../../ToolKit/Slices/ShopSlice";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { Send, Close } from "@mui/icons-material";
import Reaptcha from "reaptcha";
import { superSocket } from "../../../context/socket";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function ReportDialog() {
  const { screenHeight } = useWindowDimensions();
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop.shopData);
  const device = useSelector((state) => state.user.userDevice);
  const clicked = useSelector((state) => state.shop.infoClicked);
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(setInfoClicked(false));
  };
  const onVerify = (e) => {
    setVerified(true);
  };
  const reportHandler = () => {
    if (message === "") {
      return;
    }
    if (contact === "") {
      return;
    }
    setLoading(true);

    const reportHtml = ReactDOMServer.renderToString(
      <Report shop={shop} device={device} message={message} contact={contact} />
    );

    const data = { reportHtml, userMessage: true, message, contact };


    Axios.post(`${import.meta.env.VITE_API_URL}/shop/report_error`, data)
      .then((res) => {
        console.log("report_error sent", res);
        setMsgSent(true);
        setLoading(false);
        superSocket.emit("bugReport");
      })
      .catch((err) => {
        console.log("report_error failed", err);
        setLoading(false);
      });
  };
  const theme = useTheme();

  return (
    <div>
      <Dialog
        fullScreen={screenHeight < 400}
        open={clicked}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t("reporting.reportBugOnWebsite")}
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              top: "3px",
              right: "3px",
              color: "grey",
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ width: "100%", textAlign: "center", margin: "auto" }}>
              <PulseLoader color={theme.palette.primary.main} />
            </div>
          ) : (
            <React.Fragment>
              <DialogContentText id="alert-dialog-slide-description">
                {msgSent
                  ? t("reporting.messageSentThankyou")
                  : t("reporting.haveYouDiscoveredError")}
              </DialogContentText>
              {msgSent ? null : (
                <React.Fragment>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    color="secondary"
                    label={t("reporting.myMessage")}
                    placeholder={t("reporting.myMessage")}
                    style={{ minWidth: "300px" }}
                    fullWidth
                    autoFocus
                    multiline
                    rows={2}
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    color="secondary"
                    label={t("reporting.contactInfo")}
                    placeholder={t("reporting.emailOrPhone")}
                    style={{ minWidth: "300px" }}
                    fullWidth
                    required
                    rows={1}
                    size="small"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <Reaptcha
                    className="my-2"
                    sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                    onVerify={onVerify}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </DialogContent>

        <DialogActions style={{ minWidth: "340px" }}>
          <Button
            fullWidth
            disabled={message === "" || msgSent || loading || !verified ? true : false}
            variant="outlined"
            onClick={reportHandler}
            color="primary"
            endIcon={<Send />}
          >
            {t("button.send")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
