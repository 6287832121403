import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {  useTheme } from "@mui/material";

const ShowLimitedDesc = (props) => {
  const { item, showAll, setShowAll } = props;
  const theme = useTheme();

  // const descriptionLength = item?.description?.length;

  return (
    <div
      style={{
        height: showAll ? "60px" : "20px",
        transition: "height 500ms ease",
        color: theme.palette.mode === "dark" ? "white" : "grey",
        background: theme.palette.mode === "dark" ? theme.palette.background.paper : "white",
      }}
      className={` ${
        item?.image !== "" && item?.image !== null && "absolute  top-0 left-0 z-[9]"
      } w-full cursor-pointer   `}
      onClick={() => {
        setShowAll(!showAll);
      }}
    >
      <div className="flex items-start   w-full pl-[50px] ">

        {showAll ? (
          <span className="tracking-tight ">{item.description}</span>
        ) : (
          <span className="text-ellipsis tracking-tight ">{item.description.slice(0, 126)}</span>
        )}

          <span className="">{!showAll ? <KeyboardArrowDown sx={{
            fontSize: "1.1rem"
          }} /> : <KeyboardArrowUp />}</span>

      </div>

 

    </div>
  );


};

export default ShowLimitedDesc;
