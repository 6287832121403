import "./ItemTable.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemNew from "./Item/ItemNew";
import { Box, Button, Card, Fab, Skeleton, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  viewBestCategory,
  viewCategory,
  viewFavCategory,
  viewSearchCategory,
} from "../../ToolKit/Slices/MenuSlice";
import { resetCustomize } from "../../ToolKit/Slices/ItemSlice";
import { selectAreaToggle } from "../../ToolKit/Slices/OrderSlice";
import { Element, scroller } from "react-scroll";
import { ArrowUpward, FoodBank } from "@mui/icons-material";
import useWindowDimensions from "../UI/ScreenSize/ScreenSize";
const pattSrc = "../../../media/pattern.jpg";
const pattSrc2 = "../../../media/pattern2.jpg";

// const ExpensiveItem = ({
//   item,
//   getOfferTimeBoolean,
//   index1,
//   bannerImage,
//   theme,
//   orderType,
//   selectedCat,
//   t,
// }) => {
//   const categories = useSelector((state) => state.menu.categories);
//   const objTemp = getOfferTimeBoolean(categories?.find((el) => el.id === item.catId.id));
//   return (
//     <React.Fragment>
//       <div
//         className="my-10 relative "
//         name={"category-main-" + item.catId.id}
//         id={"category-main-" + item.catId.id}
//       >
//         <Element
//           name={"category-main-" + item.catId.id}
//           className={`element`}
//           style={{
//             paddingTop: index1 === 0 ? "0px" : "100px",
//           }}
//         >
//           {item.catId.name !== "best" && item.catId.name !== "Favorites" ? (
//             <div className=" relative mb-5 lg:mb-0   text-white w-full flex flex-col-reverse lg:grid  lg:grid-cols-[30%_70%]">
//               <Card
//                 className="flex flex-col justify-center items-center min-h-[80px]"
//                 sx={{
//                   boxShadow: 0,
//                   borderRadius: 0,
//                 }}
//               >
//                 <p
//                   className="text-center font-bold text-[18px] lg:text-[25px]"
//                   style={{
//                     color: theme.palette.primary.main,
//                   }}
//                 >
//                   {item.catId.name === "best" ? t("best") : item.catId.name}
//                 </p>
//                 <p className=" w-full text-[12px] lg:text-[14px] ">{item.catId.description}</p>
//                 {item.catId.type === "offer" ? (
//                   <p className=" w-full text-[12px] lg:text-[14px] italic text-gray-500">
//                     {t("mittagMessage.itemsOnlyAvailable")} <br></br>
//                     {objTemp.offerMessage}
//                   </p>
//                 ) : null}
//               </Card>

//               <div
//                 className="h-[150px]"
//                 style={{
//                   backgroundImage:
//                     item.catId.image && item.catId.image !== "default.jpg"
//                       ? `url('${item.catId.image}')`
//                       : // : "url('/media/default.jpg')",
//                         // `url('${bannerImage}')`,
//                         `url('')`,

//                   backgroundPosition: "center",
//                   backgroundSize: "cover",
//                   backgroundRepeat: "no-repeat",
//                   width: "100%",
//                 }}
//               ></div>
//             </div>
//           ) : (
//             <Card
//               className="flex flex-col justify-center items-center min-h-[80px]"
//               sx={{
//                 boxShadow: 0,
//                 borderRadius: 0,
//               }}
//             >
//               <p
//                 className="text-center font-bold text-[18px] lg:text-[25px]"
//                 style={{
//                   color: theme.palette.primary.main,
//                 }}
//               >
//                 {item.catId.name === "best" ? t("best") : item.catId.name}
//               </p>
//               <p className=" w-full text-[12px] lg:text-[14px] ">{item.catId.description}</p>
//               {item.catId.type === "offer" ? (
//                 <p className=" w-full text-[12px] lg:text-[14px] italic text-gray-500">
//                   {t("mittagMessage.itemsOnlyAvailable")} <br></br>
//                   {objTemp.offerMessage}
//                 </p>
//               ) : null}
//             </Card>
//           )}

//           <div className="flex flex-wrap justify-center ">
//             {item.items.map((el2, index2) => {
//               return (
//                 <ItemNew
//                   orderType={orderType}
//                   key={el2.id}
//                   item={el2}
//                   category={selectedCat}
//                   offerItemAvailable={objTemp.offerItemAvailable}
//                   offerMessage={objTemp.offerMessage}
//                 />
//               );
//             })}
//           </div>
//         </Element>
//       </div>
//     </React.Fragment>
//   );
// };

const ItemTable = (props) => {
  // useEffect(() => {
  //   console.log("window.scrollY", window.scrollY);
  // }, [window.scrollY]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  // redux selectors
  const categories = useSelector((state) => state.menu.categories);
  const items = useSelector((state) => state.menu.items);
  const currCat = useSelector((state) => state.menu.selectedCategory);
  const currItem = useSelector((state) => state.item.currItem);
  const favItems = useSelector((state) => state.user.favItems);
  const bestSellers = useSelector((state) => state.menu.bestSellers);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const currDateLocal = useSelector((state) => state.shop.serverDate);

  const bannerImage = useSelector((state) => state.shop.homepage?.bannerImage);

  const categoryProgress = useSelector((state) => state.menu.categoryProgress);
  const cartVisible = useSelector((state) => state.cart.cartVisible);

  // local states

  const [onload, setOnload] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredProd, setFilteredProd] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const orderType = useSelector((state) => state.cart.orderType);
  const [state, setState] = useState({
    filteredItems: [],
    selectedCatHasSize: false,
    selectedCatSizes: [],
    sizedItemPrices: [],
    searchingName: "",
    searchingArticle: "",
    byArticle: false,
  });
  const [currentY, setCurrentY] = useState(0);

  useEffect(() => {
    if (!cartVisible) {
      // console.log(",window.pageYOffset", window.pageYOffset);
      setCurrentY(window.pageYOffset);
    }
  }, [window.pageYOffset]);
  useEffect(() => {
    if (!cartVisible) {
      // console.log("currentY", currentY);
      // window.scrollTo({ top: -currentY, behavior: "smooth" });
      window.scrollTo(0, currentY);
    }
  }, [cartVisible]);

  const { searchingName, searchingArticle, byArticle } = state;

  useEffect(() => {
    if (items) {
      let temp2 = [...categories]
        .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        .map((el) => {
          return {
            catId: el,
            items: items
              .filter((p) => p.catId === el.id)
              .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)),
          };
        });

      if (bestSellers?.length > 0) {
        temp2.unshift({
          catId: {
            id: 999,
            name: "best",
            description: "",
            hasSize: true,
            image: null,
          },
          items: bestSellers.slice(0, 10),
        });
      }

      if (favItems?.length > 0) {
        temp2.unshift({
          catId: {
            id: 123456,
            name: "Favorites",
            description: "",
            hasSize: true,
            image: null,
          },
          items: items?.filter((el) => favItems.includes(el.id)),
        });
      }

      setFilteredItems(temp2);
    }
  }, [items, bestSellers, favItems]);

  //   local functions
  const searchInputHandler = (event, byArticle) => {
    setState({
      ...state,
      searchingArticle: byArticle ? event.target.value : "",
      searchingName: byArticle ? "" : event.target.value,
      byArticle: byArticle,
    });
  };

  useEffect(() => {
    if (currCat?.name === "Favorites") {
      if (favItems) {
        setFilteredProd(items.filter((i) => favItems.includes(i.id)));
      }
      setSelectedCat(currCat);
    } else if (currCat?.name === "best") {
      setFilteredProd(bestSellers);
      setSelectedCat(currCat);
    } else {
      setFilteredProd(items?.filter((item) => item.catId === currCat?.id));
      setSelectedCat(categories?.find((category) => category.id === currCat?.id));
    }
  }, [currCat]);

  useEffect(() => {
    if (currCat?.name === "search") {
      setSelectedCat(currCat);
      setFilteredProd(
        items
          ?.filter((i) => {
            return byArticle
              ? i.articleNo === null
                ? ""
                : // : i.articleNo.toLowerCase().indexOf(searchingArticle.toLowerCase()) !== -1
                  i.articleNo.toLowerCase().replace(".", "") === searchingArticle.toLowerCase()
              : i.name.toLowerCase().indexOf(searchingName.toLowerCase()) !== -1;
          })
          .sort((a, b) => a.articleNo?.localeCompare(b.articleNo))
          .slice(0, 20)
      );
    }
  }, [currCat?.name, searchingName, searchingArticle, byArticle]);

  useEffect(() => {
    if (currCat?.name === "search") {
      setOnload(true);
    }
  }, [currCat]);

  const getOfferTimeBoolean = (currCat) => {
    let offerMessage = "";
    let offerItemAvailable = false;
    let categoryTime = "";
    let categoryOfferTimes = null;
    if (currCat?.type === "offer") {
      let filteredDays = null;
      let offerDate = false;
      let offerDay = false;
      let offerTime = false;
      const days = [
        t("days.Sunday"),
        t("days.Monday"),
        t("days.Tuesday"),
        t("days.Wednesday"),
        t("days.Thursday"),
        t("days.Friday"),
        t("days.Saturday"),
      ];
      filteredDays = days.filter((day, index) =>
        currCat?.offerDays.some((offerDay) => offerDay - 1 === index)
      );

      // today day
      // let temp = currDateLocal
      const d = new Date(currDateLocal);

      const today = d.getDay() + 1; //0-6

      offerDay = currCat?.offerDays.some((day) => {
        if (day === today) return true;
        else return false;
      });

      // offer days
      if (filteredDays.length > 0) {
        offerMessage += `${filteredDays.map((day) => day + " ")} `;
      }

      // offer dates
      if (currCat.offerDates !== null) {
        const startDate =
          currCat?.offerDates[0].slice(8, 10) +
          "-" +
          currCat?.offerDates[0].slice(5, 7) +
          "-" +
          currCat?.offerDates[0].slice(0, 4);
        const endDate =
          currCat?.offerDates[1].slice(8, 10) +
          "-" +
          currCat?.offerDates[1].slice(5, 7) +
          "-" +
          currCat?.offerDates[1].slice(0, 4);

        offerMessage += ` ${t("mittagMessage.from")} ${startDate} ${t(
          "mittagMessage.to"
        )} ${endDate}`;

        const offerStartDate = new Date(currCat?.offerDates[0]);
        const offerEndDate = new Date(currCat?.offerDates[1]);
        const todayDate = new Date(currDateLocal);

        if (todayDate >= offerStartDate && todayDate <= offerEndDate) {
          // offerItemAvailable = true;
          offerDate = true;
        } else {
          // offerItemAvailable = false;
          offerDate = false;
        }
      }

      // offer times
      if (currCat.offerTimes !== null) {
        offerMessage += `${t("mittagMessage.between")} ${currCat?.offerTimes?.[0]?.slice(
          0,
          5
        )} - ${currCat?.offerTimes?.[1]?.slice(0, 5)}`;
        let currTime = currTimeLocal < 1000 ? "0" + currTimeLocal : currTimeLocal;
        const offerStartTime =
          currCat?.offerTimes?.[0]?.slice(0, 2) + currCat?.offerTimes?.[0]?.slice(3, 5);
        const offerEndTime =
          currCat?.offerTimes?.[1]?.slice(0, 2) + currCat?.offerTimes?.[1]?.slice(3, 5);
          categoryOfferTimes = {
            offerStartTime,
            offerEndTime,
          }
    
        if (currTime < offerStartTime) {
          categoryTime = "before";
          offerTime = true
        } else if (currTime > offerEndTime) {
          categoryTime = "after";
          offerTime = true
        } else if (currTime >= offerStartTime && currTime <= offerEndTime) {
          // offerItemAvailable = true
          offerTime = true;
          categoryTime = "between";
        } else {
          // offerItemAvailable = false
          offerTime = false;
        }
        categoryOfferTimes.categoryTime = categoryTime
      }

      // today is offer day - true
      if (offerDay) {
        // offerDate not null in database
        if (currCat?.offerDates !== null) {
          // today is offer date - true
          if (offerDate) {
            // offerTime not null in database
            if (currCat?.offerTimes !== null) {
              // today is offer time - true
              if (offerTime) {
                offerItemAvailable = true;
              }
              // today is not offer time - false
              else {
                offerItemAvailable = false;
              }
            }
            // offerTime null in database but offer date valid
            else {
              offerItemAvailable = true;
            }
          }
          // today is not offer date - false
          else {
            offerItemAvailable = false;
          }
        } else {
          // offerTime not null in database
          if (currCat?.offerTimes !== null) {
            // today is offer time - true
            if (offerTime) {
              offerItemAvailable = true;
            }
            // today is not offer time - false
            else {
              offerItemAvailable = false;
            }
          }
          // offerTime null in database but offer day valid
          else {
            offerItemAvailable = true;
          }
        }
      } else {
        offerItemAvailable = false;
      }
    }
    return { offerMessage, offerItemAvailable , categoryTime ,categoryOfferTimes   };
  };

  // useEffect(() => {
  //   const itemDefault = items?.find((el) => el.isDefault === true);
  //   if (itemDefault?.id) {
  //     scroller.scrollTo(`category-main-${itemDefault.id}`, {
  //       // duration: 1500,
  //       // delay: 100,
  //       smooth: true,
  //       // containerId: 'ContainerElementID',
  //       offset: -100, // Scrolls to element - 100 pixels down the page
  //       // ... other options
  //     });
  //     dispatch(viewCategory(itemDefault.id));
  //   }
  // }, []);

  return (
    <div
      // onTouchMove={(e) => onTouchMove(e)}
      // onTouchEnd={(e) => onTouchEnd(e)}
      // onTouchStart={(e) => onTouchStart(e)}
      className="ItemTable pt-[114px]"
      style={{
        backgroundImage: theme.palette.mode === "light" ? `url(${pattSrc})` : `url(${pattSrc2})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "auto",
      }}
    >
      {currCat?.name === "search" ? (
        <React.Fragment>
          <div className="SearchFields mt-10 lg:mt-0">
            <TextField
              autoFocus
              style={{ margin: 5 }}
              size="small"
              label={t("categoryNav.searchByArticle")}
              variant="outlined"
              onChange={(e) => searchInputHandler(e, true)}
              value={searchingArticle}
            />
            <TextField
              style={{ margin: 5 }}
              autoFocus
              size="small"
              label={t("categoryNav.searchItem")}
              variant="outlined"
              onChange={(e) => searchInputHandler(e, false)}
              value={searchingName}
            />
          </div>
          {filteredProd.length === 0 ? <p>{t("categoryNav.noItemsFound")}</p> : null}
        </React.Fragment>
      ) : null}

      {currCat?.name === "search" && (
        <div className="min-h-[80svh]">
          <>
            {filteredProd.map((el2, index2) => {
              const objTemp = getOfferTimeBoolean(categories?.find((el) => el.id === el2.catId));
              return (
                <ItemNew
                  orderType={orderType}
                  key={el2.id}
                  item={el2}
                  category={categories.find((el) => el.id === el2.catId)}
                  offerItemAvailable={objTemp.offerItemAvailable}
                  offerMessage={objTemp.offerMessage}
                  categoryTime={objTemp.categoryTime}
                  categoryOfferTimes={objTemp.categoryOfferTimes}
                />
              );
            })}
          </>
        </div>
      )}
      {currCat?.name !== "orders" && currCat?.name !== "search" ? (
        <div className="ItemContainer">
          {/* <List
            useIsScrolling={true}
            itemCount={filteredItems.length}
            itemSize={20}
            height={700}
            style={{
              width: "100%",
            }}
            // width={400}
          >
            {({ index, style, isScrolling }) =>
              isScrolling ? (
                <Skeleton style={style} />
              ) : (
                <ExpensiveItem
                  index1={index}
                  bannerImage={bannerImage}
                  theme={theme}
                  orderType={orderType}
                  selectedCat={selectedCat}
                  item={filteredItems[index]}
                  getOfferTimeBoolean={getOfferTimeBoolean}
                  t={t}
                />
              )
            }
          </List> */}

          {filteredItems &&
            filteredItems.map((item, index1) => {
              const singleCat = categories?.find((el) => el.id === item.catId.id);
              const objTemp = getOfferTimeBoolean(singleCat);
              // console.log('objTemp', objTemp)
              return (
                <React.Fragment key={index1}>
                  {singleCat &&
                  item.catId.name !== "best" &&
                  item.catId.name !== "Favorites" &&
                  singleCat?.alwaysShow === false &&
                  objTemp.offerItemAvailable === false ? null : (
                    <div
                      className="my-10 relative "
                      name={"category-main-" + item.catId.id}
                      id={"category-main-" + item.catId.id}
                    >
                      <Element
                        name={"category-main-" + item.catId.id}
                        className={`element`}
                        style={{
                          paddingTop: index1 === 0 ? "0px" : "100px",
                        }}
                      >
                        {item.catId.name !== "best" && item.catId.name !== "Favorites" ? (
                          <div className=" relative mb-5 lg:mb-0   text-white w-full flex flex-col-reverse lg:grid  lg:grid-cols-[30%_70%]">
                            <Card
                              className="flex flex-col justify-center items-center min-h-[80px]"
                              sx={{
                                boxShadow: 0,
                                borderRadius: 0,
                              }}
                            >
                              <p
                                className="text-center font-bold text-[18px] lg:text-[25px]"
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {item.catId.name === "best" ? t("best") : item.catId.name}
                              </p>
                              <p className=" w-full text-[12px] lg:text-[14px] ">
                                {item.catId.description}
                              </p>
                              {item.catId.type === "offer" && item.catId.alwaysShow === true ? (
                                <p className=" w-full text-[12px] lg:text-[14px] italic text-gray-500">
                                  {t("mittagMessage.itemsOnlyAvailable")} <br></br>
                                  {objTemp.offerMessage}
                                </p>
                              ) : null}
                            </Card>

                            <div
                              className="h-[150px]"
                              style={{
                                backgroundImage:
                                  item.catId.image && item.catId.image !== "default.jpg"
                                    ? `url('${item.catId.image}')`
                                    : // : "url('/media/default.jpg')",
                                      `url('${bannerImage}')`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <Card
                            className="flex flex-col justify-center items-center min-h-[80px]"
                            sx={{
                              boxShadow: 0,
                              borderRadius: 0,
                            }}
                          >
                            <p
                              className="text-center font-bold text-[18px] lg:text-[25px]"
                              style={{
                                color: theme.palette.primary.main,
                              }}
                            >
                              {item.catId.name === "best" ? t("best") : item.catId.name}
                            </p>
                            <p className=" w-full text-[12px] lg:text-[14px] ">
                              {item.catId.description}
                            </p>
                            {item.catId.type === "offer" ? (
                              <p className=" w-full text-[12px] lg:text-[14px] italic text-gray-500">
                                {t("mittagMessage.itemsOnlyAvailable")} <br></br>
                                {objTemp.offerMessage}
                              </p>
                            ) : null}
                          </Card>
                        )}

                        <div className="flex flex-wrap justify-center ">
                          {item.items.map((el2, index2) => {
                            const singleCat1 = categories?.find((el) => el.id === el2.catId);
                            const objTemp1 = getOfferTimeBoolean(singleCat1);
                            return (
                              <ItemNew
                                orderType={orderType}
                                key={el2.id}
                                item={el2}
                                category={singleCat1}
                                // category={categories.find((el) => el.id === el2.catId)}
                                offerItemAvailable={objTemp1.offerItemAvailable}
                                offerMessage={objTemp1.offerMessage}
                                categoryTime={objTemp1.categoryTime}
                                categoryOfferTimes={objTemp1.categoryOfferTimes}
                              />
                            );
                          })}
                        </div>
                      </Element>
                    </div>
                  )}

                  <small className="text-gray-400 px-[20px] block md:hidden">
                    Alle Preise verstehen sich in Euro und sind inklusive Mehrwertsteuer. Abbildung
                    kann vom original abweichen.
                  </small>
                </React.Fragment>
              );
            })}
        </div>
      ) : null}
      {categoryProgress > 1
        ? Object.keys(currItem).length === 0 && (
            <Box className={"fixed  bottom-[12%] md:bottom-[5%] right-[5%] md:right-[32%] z-[199]"}>
              <Fab
                onClick={() => {
                  // window.scrollTo({ top: 0, behavior: "smooth" });
                  const id = filteredItems[0].catId.id;
                  if (id === 123456) {
                    dispatch(viewFavCategory());
                  } else if (id === 999) {
                    dispatch(viewBestCategory());
                  } else {
                    dispatch(viewCategory(filteredItems[0].catId.id));
                  }
                  // setTimeout(() => {
                  //   scroller.scrollTo(`category-main-${filteredItems[0].catId.id}`, {
                  //     smooth: true,
                  window.scrollTo({ top: 0, behavior: "smooth" });

                  //   });
                  // }, 1000);
                }}
                aria-label="add"
              >
                <ArrowUpward />
              </Fab>
            </Box>
          )
        : null}
    </div>
  );
};

export default ItemTable;
