import React from "react";

function FeedBackHtml(props) {
  const { userData, rating, comments, shop, order } = props;

  return (
    <div style={{ margin: "20px" }}>
      <h3>
        Rückmeldung der Bestellnummer # {order.serial}
      </h3>
      <p>
        <b>Wertung: </b> {rating}/5
      </p>
      <p>
        <b>Bemerkungen: </b> {comments}
      </p>
      <h3>Kundeninformation</h3>
      <p>Name: {userData.name}</p>
      <p>Email: {userData.email}</p>
      <p>Telefon: {userData.phone}</p>
    </div>
  );
}

export default FeedBackHtml;
