import React from "react";
import { useSelector } from "react-redux";

import "./TotalCalculator.css";

import { useTranslation } from "react-i18next";

const TotalCalculator = (props) => {
  const cart = useSelector((state) => state.cart);
 
  const { t } = useTranslation();

  const priceFormat = window.$priceFormater;

  return (
    <table className="Total">
      <tbody>
        <tr>
          <td style={{ color: "grey" }}> {t("cart.subtotal")}: </td>
          <td className="TotalAmount">
            <b>{priceFormat.format(cart.subTotal)}</b>
          </td>
        </tr>
        {/* <tr>
          <td>User Saved: </td>
          <td className="TotalAmount">
            <b>{priceFormat.format(cart.actualTotal-cart.subTotal)}</b>
          </td>
        </tr> */}
        {cart.voucherDisc ? (
          <React.Fragment>
            <tr style={{ color: "#00b700" }}>
              <td style={{ color: "grey" }}>{t("cart.voucherDiscount")}: </td>
              <td className="TotalAmount">
                <b>-{cart.voucherDisc}</b>
                <b>
                  {cart.voucherDiscType === "amount" ? "€" : "%"}
                </b>
              </td>
            </tr>
            <tr style={{ color: "#00b700" }}>
              <td style={{ color: "grey" }}>{t("cart.afterDiscount")}</td>
              <td className="TotalAmount">
                <b>{priceFormat.format(cart.afterVoucherDisc)}</b>
              </td>
            </tr>
          </React.Fragment>
        ) : null}
        {cart.orderType === "delivery" ? (
          <tr>
            <td style={{ color: "grey" }}>{t("cart.deliveryCharges")}: </td>
            <td className="TotalAmount">
              <b>
                {cart.deliveryCharges
                  ? "+" + priceFormat.format(cart.deliveryCharges)
                  : t("cart.free")}
              </b>
            </td>
          </tr>
        ) : null}
        <tr>
          <td style={{ color: "grey" }}>{t("cart.grandTotal")}: </td>
          <td className="TotalAmount">
            <b>{priceFormat.format(cart.grandTotal)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalCalculator;
