import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { green } from "@mui/material/colors";
import {
  Close,
  CheckCircleOutline,
  ErrorOutline,
  ExpandMore,
  NotificationAdd,
  NotificationsNone,
} from "@mui/icons-material";
import { Box, Button, ButtonGroup, IconButton, Typography, useTheme } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";

import "./OrderSummary.css";
import OrderPaper from "./OrderPaper";
import OrderTracker from "../OrderTracker/OrderTracker";
import StarRating from "../../UI/StarRating/StarRating";
import ReactDOMServer from "react-dom/server";
import axios from "axios";

import { useTranslation } from "react-i18next";

import { socket } from "../../../App";
import { setEmailSent, setOrderSent, toggleNewOrder } from "../../../ToolKit/Slices/OrderSlice";
import { setNotificationPermission } from "../../../ToolKit/Slices/UserSlice";
import { regSw, subscribe } from "../../../utils/swHelper";
import { superSocket } from "../../../context/socket";

const OrderSummary = (props) => {
  const { shopLogo, currOrderCustomized } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const currOrder = useSelector((state) => state.order.currOrder);
  const categories = useSelector((state) => state.menu.categories);
  const orderError = useSelector((state) => state.order.error);
  const shop = useSelector((state) => state.shop.shopData);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const shopCurrOpen = useSelector((state) => state.shop.shopCurrOpen);
  const fax = useSelector((state) => state.shop.settings.fax);
  const winOrder = useSelector((state) => state.shop.settings.winOrder);
  const settings = useSelector((state) => state.shop.settings);
  const feedbackSent = useSelector((state) => state.user.feedbackSent);
  const userData = useSelector((state) => state.order.currOrder?.userData);
  const defaultDeliveryTime = settings?.livePanelSettings?.defaultDeliveryTime;
  const defaultPickupTime = settings?.livePanelSettings?.defaultPickupTime;
  const trackers = useSelector((state) => state.order.currOrdersTrackers);
  const themeMode = useSelector((state) => state.user.theme);
  const notificationPermission = useSelector((state) => state.user.notificationPermission);

  const dispatch = useDispatch();
  const [notificationPermissionLocal, setNotificationPermissionLocal] = useState(
    notificationPermission === "granted" || false
  );
  let activeStepV = 0;
  let rejectedV = false;
  let delayedV = false;
  let reasonV = "";
  let durationV = null;
  let emailSentV = false;
  let orderSentV = false;

  if (currOrder !== null) {
    const currTracker = trackers.filter((t) => t.orderId === currOrder.id);
    if (currTracker[0]) {
      const { activeStep, rejected, delayed, reason, duration, emailSent, orderSent } =
        currTracker[0];
      activeStepV = activeStep;
      rejectedV = rejected;
      delayedV = delayed;
      reasonV = reason;
      durationV = duration;
      emailSentV = emailSent;
      orderSentV = orderSent;
    } else {
      if (currOrder.status !== null) {
        emailSentV = true;
        orderSentV = true;
      }
      if (currOrder.status === "preparing") {
        activeStepV = 1;
        durationV = currOrder.duration[1] || currOrder.duration;
      }
      if (currOrder.status === "enroute") {
        activeStepV = 2;
        durationV = currOrder.duration[1] || currOrder.duration;
      }
      if (currOrder.status === "completed") {
        activeStepV = 2;
        durationV = currOrder.duration[1] || currOrder.duration;
      }
      if (currOrder.status === "rejected") {
        rejectedV = true;
        reasonV = "Rejected";
      }
    }
  }

  const [open, setOpen] = useState(false);

  const [showRating, setShowRating] = useState(false);
  // const [status, setStatus] = useState();
  // const [loading, setLoading] = useState(true);

  const showActionSnack = () => {
    const action = (key) => (
      <React.Fragment>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t("tracker.HowToAllow")}
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            closeSnackbar(key);
          }}
          style={{ margin: "5px" }}
        >
          {t("tracker.Close")}
        </Button>
      </React.Fragment>
    );
    let message = t("tracker.You have blocked our request for notification");
    enqueueSnackbar(message, {
      variant: "warning",
      persist: true,
      action,
    });
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const checkNotificationPromise = () => {
    try {
      Notification?.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  async function registerAndSubscribe() {
    try {
      const serviceWorkerReg = await regSw();
      subscribe(serviceWorkerReg, currOrder.id);
      // const subscription = JSON.parse(localStorage.getItem("subscription"));
      // if (subscription) {
      //   axios.post(`${import.meta.env.VITE_API_URL}/push-noty/update-subscription-user`, {
      //     ...subscription,
      //     orderId: currOrder.id,
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  }
  // default granted
  const askNotificationPermission = () => {
    setOpen(false);
    // function to actually ask the permissions
    function handlePermission(permission) {
      // Whatever the user answers, we make sure Chrome stores the information
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }

      // set the button to shown or hidden, depending on what the user answers
      dispatch(setNotificationPermission({ notify: Notification?.permission }));
      if (Notification?.permission === "denied") {
        showActionSnack();
      } else {
        showSnack(t("You will get notification when order status changes"), "success", false);
        registerAndSubscribe();
      }
    }

    // Let's check if the browser supports notifications
    if (!window.Notification) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification?.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification?.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowRating(true);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!fax.active && !winOrder && orderError === null && !orderSentV) {
      socket.emit("newOrder", currOrder);
      superSocket.emit("refreshTodayOrders", currOrder);
      console.log("order sent via socket useeffect!");
      dispatch(setOrderSent(true));
      dispatch(toggleNewOrder(false));
    }

    return () => {};
  }, [currOrder]);

  // let currOrderCustomized = {};

  // if (currOrder !== null) {
  //   currOrder.items.forEach((el) => {
  //     let category = categories?.find((el2) => el2.id === el.catId);
  //     if (category) {
  //       if (category.id + "" in currOrderCustomized) {
  //         currOrderCustomized = {
  //           ...currOrderCustomized,
  //           [category.id]: {
  //             ...currOrderCustomized[category.id],
  //             items: [...currOrderCustomized[category.id].items, el],
  //           },
  //         };
  //       } else {
  //         currOrderCustomized = {
  //           ...currOrderCustomized,
  //           [category.id]: {
  //             value: category,
  //             items: [el],
  //           },
  //         };
  //       }
  //     }
  //   });
  // }

  useEffect(() => {
    if (!emailSentV && orderError === null) {
      const messageHtml = ReactDOMServer.renderToString(
        <OrderPaper
          isEmail={true}
          currOrder={currOrder}
          currOrderItems={currOrderCustomized}
          shop={shop}
          discounts={discounts}
          shopLogo={shopLogo}
        />
      );
      const userEmail = {
        orderHtml: messageHtml,
        orderSerial: currOrder.serial ? currOrder.serial : null,
        shopName: shop.name,
        userEmail: currOrder.userData.email ? currOrder.userData.email : null,
      };
      const shopEmail = {
        orderHtml: messageHtml,
        street: currOrder.userData.street ? currOrder.userData.street : null,
        grandTotal: currOrder.grandTotal ? currOrder.grandTotal : null,
        customer: userData?.name,
      };
      if (fax.active) {
        // const faxHtml = ReactDOMServer.renderToString(
        //   <OrderTable currOrder={currOrder} shop={shop} discounts={discounts} />
        // );
        const faxData = {
          // orderHtml: faxHtml,
          orderSerial: currOrder.serial,
          shopName: shop.name,
        };
        axios
          .post(`${import.meta.env.VITE_API_URL}/order/fax_email`, faxData)
          .then((res) => {
            console.log("Email sent to faxserver", res);
            dispatch(setEmailSent(true));
          })
          .catch((err) => {
            console.log("Email failed to faxserver", err);
          });
      }

      if (settings.receiveOrdersOn?.filter((el) => el.active === true)?.length > 0) {
        axios
          .post(`${import.meta.env.VITE_API_URL}/order/shop_email`, shopEmail)
          .then((res) => {
            console.log("Email sent to shop successfully", res);
            dispatch(setEmailSent(true));
          })
          .catch((err) => {
            console.log("Email to shop failed", err);
          });
      } else {
        dispatch(setEmailSent(true));
      }
      // if (settings.receiveOrdersOn[0]) {
      //   axios
      //     .post(`${import.meta.env.VITE_API_URL}/order/shop_email`, shopEmail)
      //     .then((res) => {
      //       console.log("Email sent to shop successfully", res);
      //       dispatch(setEmailSent(true));
      //     })
      //     .catch((err) => {
      //       console.log("Email to shop failed", err);
      //     });
      // }
      if (settings.sendEmailtoCustomer) {
        axios
          .post(`${import.meta.env.VITE_API_URL}/order/user_email`, userEmail)
          .then((res) => {
            // console.log("Email sent to user successfully", res);
            dispatch(setEmailSent(true));
          })
          .catch((err) => {
            console.log("Failed to send email to user", err);
          });
      }
      dispatch(setEmailSent(true));
    } else {
      if (currOrder !== null) {
        dispatch(setEmailSent(true));
      }
    }
    return () => {};
  }, [currOrder]);

  useEffect(() => {
    if (currOrder !== null) {
      socket.emit("join", currOrder.id);
      console.log(`ordersummary room join`, currOrder.id);
    }
    return () => {};
  }, []);

  // useEffect(() => {
  //   if (notificationPermission) {
  //     if (notificationPermission === "denied") {
  //       setNotificationPermissionLocal(false);
  //     } else if (notificationPermission === "default") {
  //       setNotificationPermissionLocal(true);
  //     }
  //   }
  // }, [notificationPermission]);

  return (
    <React.Fragment>
      <div
        className="flex flex-col gap-2 items-center"
        style={{
          minWidth: "350px",
          // backgroundColor: "#eeeeee",
          padding: "10px",
        }}
      >
        {/* || !notificationPermissionLocal */}
        {notificationPermission !== "granted" && !notificationPermissionLocal && (
          <Box
            className="w-full flex items-center gap-1 m-1 rounded-md p-[10px]"
            sx={{
              backgroundColor: theme.palette.warning.light,
              display:
                notificationPermission === "ask" && notificationPermissionLocal === false && "none",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Would you like to receive notifications about your order?")}
            </Typography>
            <Box>
              <ButtonGroup variant="outlined" size="small">
                <Button
                  color="error"
                  endIcon={<NotificationsNone />}
                  // setNotificationPermissionLocal(false)
                  onClick={() => {
                    setNotificationPermissionLocal(false);
                    dispatch(setNotificationPermission({ notify: "ask" }));
                  }}
                  // onClick={() => dispatch(setNotificationPermission({ notify: "default" }))}
                >
                  {t("notify.no")}
                </Button>
                <Button
                  color="success"
                  endIcon={<NotificationAdd />}
                  variant="contained"
                  onClick={() => {
                    setNotificationPermissionLocal(true);
                    askNotificationPermission();
                  }}
                >
                  {t("notify.yes")}
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        )}

        {notificationPermission !== "granted" && notificationPermissionLocal && (
          <Box className="w-full flex items-center gap-3 m-1">
            <Typography>Please allow notifications from the AddressBar..! </Typography>
          </Box>
        )}

        {currOrder !== null ? (
          <Box className="w-full flex flex-col gap-2">
            {winOrder || currOrder.delayedOrder ? (
              <p
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                {t("orderSummary.thankyouForOrder")}
                <br />
                {settings.sendEmailtoCustomer ? t("orderSummary.youWillReceiveEmail") : null}
              </p>
            ) : null}
            {/* {defaultDeliveryTime !== "manual" && (
                    <Typography variant="caption">{defaultDeliveryTime} mins</Typography>
                  )} */}
            {fax.active || winOrder ? (
              <React.Fragment>
                <div id="alert-dialog-slide-description" className="OrderDoneSign">
                  <CheckCircleOutline style={{ color: green[500], fontSize: 100 }} />
                  <br></br>
                  {t("orderSummary.orderSent")}
                </div>
                <p style={{ textAlign: "center", fontSize: 14 }}>
                  {currOrder.delayedOrder
                    ? currOrder.orderType === "delivery"
                      ? t("orderSummary.orderWillBeDelivereAt") + currOrder.delayedTime.slice(0, 5)
                      : t("orderSummary.orderWillBeReadytAt") + currOrder.delayedTime.slice(0, 5)
                    : currOrder.orderType === "pickup"
                    ? t("orderSummary.orderWillBeReadyInStart") +
                      ` ${defaultPickupTime !== "manual" ? defaultPickupTime : "15-30"} ` +
                      t("orderSummary.orderWillBeReadyInEnd")
                    : t("orderSummary.orderWillBeDeliveredStart") +
                      ` ${defaultDeliveryTime !== "manual" ? defaultDeliveryTime : "20-60"} ` +
                      t("orderSummary.orderWillBeDeliveredEnd")}
                </p>
              </React.Fragment>
            ) : shopCurrOpen ? (
              <OrderTracker
                settings={settings}
                order={currOrder}
                trackers={{
                  activeStep: activeStepV,
                  rejected: rejectedV,
                  delayed: delayedV,
                  reason: reasonV,
                  duration: durationV,
                }}
              />
            ) : (
              <p style={{ textAlign: "center", fontSize: 14 }}>
                {currOrder.delayedOrder
                  ? currOrder.orderType === "delivery"
                    ? t("orderSummary.orderWillBeDelivereAt") + currOrder.delayedTime.slice(0, 5)
                    : t("orderSummary.orderWillBeReadytAt") + currOrder.delayedTime.slice(0, 5)
                  : currOrder.orderType === "pickup"
                  ? t("orderSummary.orderWillBeReadyIn")
                  : t("orderSummary.orderWillBeDElivered")}
              </p>
            )}
            {!feedbackSent ? (
              showRating ? (
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                  className="relative"
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={() => setShowRating(false)}
                  >
                    <Close />
                  </IconButton>
                  {/* <Close onClick={() => setShowRating(false)} style={{ padding: 10 }} /> */}
                  <StarRating order={currOrder} />
                </div>
              ) : (
                <div style={{ margin: "10px", textAlign: "center" }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => setShowRating(true)}
                    endIcon={<ExpandMore />}
                  >
                    Feedback
                  </Button>
                </div>
              )
            ) : (
              <p style={{ margin: "20px", textAlign: "center" }}>
                {t("feedback.thankyouForYourFeedback")}
              </p>
            )}
            <div>
              <OrderPaper
                currOrderItems={currOrderCustomized}
                themeMode={themeMode}
                currOrder={currOrder}
                userDiscount={
                  currOrder?.userData?.discounts !== undefined
                    ? currOrder.userData?.discounts
                    : null
                }
                shop={shop}
                discounts={discounts}
                shopLogo={shopLogo}
              />
            </div>
          </Box>
        ) : (
          <div id="alert-dialog-slide-description" className="OrderDoneSign">
            <ErrorOutline style={{ color: "red", fontSize: 100 }} />
            <br></br>
            {t("orderSummary.orderFailed")}
            <br />
            <span style={{ textAlign: "center" }}>{t("orderSummary.somethingWentWrong")}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default OrderSummary;

{
  /* <OrderTracker
      settings={settings}
      order={currOrder}
      trackers={{
        activeStep: activeStepV,
        rejected: rejectedV,
        delayed: delayedV,
        reason: reasonV,
        duration: durationV,
      }}
    /> */
}
