import { LocalMallSharp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function StatusGifs(props) {
  let review = "../../../common/review.gif";
  let prep = "../../../common/preparing.gif";
  let delivery = "../../../common/delivery.gif";

  const themeMode = useSelector((state) => state.user.theme);
  return (
    <div
      style={{
        display: "inline-flex",
        width: "100%",
        textAlign: "center",
        alignItems: "baseline",
        minHeight: 100,
        // backgroundColor: "black",
      }}
    >
      <div
        className="flex justify-center items-center pe-2"
        style={{
          width: "40%",
          visibility: props.activeStep === 0 ? "unset" : "hidden",
        }}
      >
        {themeMode === "dark" ? (
          <img
            src={review}
            alt="reviewing..."
            style={{
              width: "50px",
              paddingRight: 10,
              "-webkit-filter": "invert(1)",
              filter: "invert(1)",
            }}
          />
        ) : (
          <img
            src={review}
            alt="reviewing..."
            style={{
              width: "50px",
              paddingRight: 10,
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "20%",
          visibility: props.activeStep === 1 ? "unset" : "hidden",
        }}
      >
        {themeMode === "dark" ? (
          <img
            src={prep}
            alt="preparing..."
            style={{
              width: "100px",
              "-webkit-filter": "invert(1)",
              filter: "invert(1)",
            }}
          />
        ) : (
          <img
            src={prep}
            alt="preparing..."
            style={{
              width: "100px",
            }}
          />
        )}
      </div>
      <div
        className="flex justify-center ps-3"
        style={{
          width: "40%",
          visibility: props.activeStep === 2 ? "unset" : "hidden",
        }}
      >
        {" "}
        {props.orderType === "delivery" ? (
          <>
            {themeMode === "dark" ? (
              <img
                src={delivery}
                alt="delivering..."
                style={{
                  width: "50px",
                  paddingLeft: 10,
                  "-webkit-filter": "invert(1)",
                  filter: "invert(1)",
                }}
              />
            ) : (
              <img
                src={delivery}
                alt="delivering..."
                style={{
                  width: "50px",
                  paddingLeft: 10,
                }}
              />
            )}
          </>
        ) : (
          <div style={{ paddingLeft: 15 }}>
            <LocalMallSharp fontSize="large" sx={{ mb: 3 }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default StatusGifs;
