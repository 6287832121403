import React from "react";

import { Button } from "@mui/material";

import { RefreshSharp } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

function ErrorFallbackSimple(props) {
  const { t } = useTranslation();

  return (
    <div
      role="alert"
      style={{
        position: "absolute",
        top: "35%",
        width: "90%",
        padding: 10,
        textAlign: "center",
      }}
    >
      <React.Fragment>
        <h3>{t("reporting.oopsSomethingWentWrong")}</h3>

        <Button
          variant="outlined"
          onClick={() => {
            window.location.reload();
          }}
          color="inherit"
          startIcon={<RefreshSharp />}
        >
          {t("button.goBack")}
        </Button>
      </React.Fragment>
    </div>
  );
}

export default ErrorFallbackSimple;
