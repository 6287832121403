import axios from "axios";
async function regSw() {
  if ("serviceWorker" in navigator) {
    let url = "/sw.js";
    const reg = await navigator.serviceWorker.register(url, { scope: "/" });
    console.log("service config is", { reg });
    return reg;
  }
  throw Error("serviceworker not supported");
}

async function subscribe(serviceWorkerReg, orderId) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  console.log({ subscription });
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BDQy2JhZzlZbCIiPiVuR4nZqsQb0eCimwC9nl25BNmITydWIcpxohlmCBm5g5-_9KKZy1F3BzZMCROncaHD26N4",
    });

    localStorage.setItem("subscription", JSON.stringify(subscription));
    axios.post(`${import.meta.env.VITE_API_URL}/push-noty/subscribeUser`, subscription).then(() => {
      const subscription1 = JSON.parse(localStorage.getItem("subscription"));
      if (subscription1) {
        axios.post(`${import.meta.env.VITE_API_URL}/push-noty/update-subscription-user`, {
          ...subscription1,
          orderId,
        });
      }
    });
  } else {
    localStorage.setItem("subscription", JSON.stringify(subscription));
    const subscription1 = JSON.parse(localStorage.getItem("subscription"));
    axios.post(`${import.meta.env.VITE_API_URL}/push-noty/update-subscription-user`, {
      ...subscription1,
      orderId,
    });
  }
}

// async function subscribe(serviceWorkerReg) {
//   let subscription = await serviceWorkerReg.pushManager.getSubscription();
//   if (subscription === null) {
//     subscription = serviceWorkerReg.pushManager
//       .subscribe({
//         userVisibleOnly: true,
//         applicationServerKey:
//           "BDQy2JhZzlZbCIiPiVuR4nZqsQb0eCimwC9nl25BNmITydWIcpxohlmCBm5g5-_9KKZy1F3BzZMCROncaHD26N4",
//       })
//       .then((sub) => {
//         console.log("sub", sub);
//         if (sub) {
//           axios.post(`${import.meta.env.VITE_API_URL}/subscribe`, {
//             ...sub,
//             userType: "user",
//             orderId: null,
//           });
//         }
//       });
//   }
// }

async function orderSubscribe(serviceWorkerReg, orderId) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BDQy2JhZzlZbCIiPiVuR4nZqsQb0eCimwC9nl25BNmITydWIcpxohlmCBm5g5-_9KKZy1F3BzZMCROncaHD26N4",
    });
    // console.log({ subscription });
    // axios.post(`${import.meta.env.VITE_API_URL}/subscribe`, subscription);
    axios.post(`${import.meta.env.VITE_API_URL}/subscribeUser`, subscription);
  } else {
    // axios.post(`${import.meta.env.VITE_API_URL}/update-subscription-user`, {
    //   ...subscription,
    //   orderId: orderId,
    // });
  }

  return subscription;
}

export { regSw, subscribe, orderSubscribe };
