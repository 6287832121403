import React from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "../ScreenSize/ScreenSize";

import "./Dialoges.css";
import { Button, Divider, IconButton } from "@mui/material";

import styled, { keyframes } from "styled-components";
import { slideInUp } from "react-animations";
import { Close } from "@mui/icons-material";
import { acceptCookies } from "../../../ToolKit/Slices/UserSlice";

const slideAnimation = keyframes`${slideInUp}`;

const SlideDiv = styled.div`
  animation: 0.3s ${slideAnimation};
`;

function CookieDialog() {
  const { screenWidth } = useWindowDimensions();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className="CookieMessage lg:mb-8 ">
      <SlideDiv>
        <div className="CookieContainer">
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton style={{ color: "white" }} onClick={() => dispatch(acceptCookies())}>
              <Close />
            </IconButton>
          </div>
          <h3 style={{ color: "white", margin: "10px" }}>{t("cookies.WelcomeToOurNewWebsite")}</h3>
          <p className="CookieDialog">{t("cookies.cookiesMessage")}</p>
          <span>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth={screenWidth < 660 ? true : false}
              onClick={() => dispatch(acceptCookies())}
            >
              OK
            </Button>
          </span>
        </div>
      </SlideDiv>
      <Divider />
    </div>
  );
}

export default CookieDialog;
