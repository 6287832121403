import React from "react";
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";

import "./CartToggle.css";

import {
  ReplayRounded,
  Restaurant,
  ShoppingCart,
  ShoppingCartRounded,
  Today,
} from "@mui/icons-material";

import { Box, Button, Fab, Typography, useTheme } from "@mui/material";

import CartCounter from "./CartCounter";
import PriceCalculator from "../../ItemTable/Item/PriceCalculator";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FaCartPlus, FaClock } from "react-icons/fa";
import { addToCart, showCartToggle } from "../../../ToolKit/Slices/CartSlice";
import { resetCustomize } from "../../../ToolKit/Slices/ItemSlice";
import { ordersContainerToggle, selectAreaToggle } from "../../../ToolKit/Slices/OrderSlice";
import { timeDialogToggle } from "../../../ToolKit/Slices/ShopSlice";

import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { setIsRequiredToppingsNotSelected } from "../../../ToolKit/Slices/ToppingSlice";

const CartToggle = (props) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const itemCount = useSelector((state) => state.cart.cartItemCount);
  const currItem = useSelector((state) => state.item.currItem);
  const currToppings = useSelector((state) => state.topping.currToppings);
  const orderType = useSelector((state) => state.cart.orderType);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const subTotal = useSelector((state) => state.cart.subTotal);
  const area = useSelector((state) => state.order.selectedArea);
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);
  const minLimits = useSelector((state) => state.topping.minLimits);
  const limitCounter = useSelector((state) => state.topping.limitCounter);
  const categories = useSelector((state) => state.menu.categories);
  console.log("currItem", currItem);
  const settings = useSelector((state) => state.shop.settings);
  const defaultDeliveryTime = settings?.livePanelSettings?.defaultDeliveryTime;
  const defaultPickupTime = settings?.livePanelSettings?.defaultPickupTime;
  const { screenWidth } = useWindowDimensions();

  const { t } = useTranslation();

  const priceFormat = window.$priceFormater;

  const calculatePrice = () => {
    let orignalPrice = currItem.price + currToppings.toppingAmount;
    let discPrice = null;

    if (!currItem.isDiscount) {
      if (discounts.deliveryDiscountActive && orderType === "delivery" && currItem.deliveryDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
      } else if (discounts.pickupDiscountActive && orderType === "pickup" && currItem.pickupDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
      } else {
        discPrice = null;
      }
    } else {
      if (orderType === "delivery") {
        if (currItem.discounts.deliveryType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.delivery;
        } else {
          discPrice = orignalPrice - currItem.discounts.delivery;
        }
      } else {
        if (currItem.discounts.pickupType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.pickup;
        } else {
          discPrice = orignalPrice - currItem.discounts.pickup;
        }
      }
    }
    return discPrice;
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const scrollDrinksToView = () => {
    let allClasses = [];
    let allRemainingToppingElements = [];

    if (minLimits.length > 0) {
      // console.log("if");
      let tCatIds = limitCounter.map((m) => {
        return m.tCatId;
      });
      // console.log("limitCounter", limitCounter);
      // console.log("tCatIds", tCatIds);

      // let remainingRequiredTopping = minLimits.filter((item) => tCatIds.includes(item.tCatId));
      let remainingRequiredTopping = minLimits.map((item) => item.tCatId);
      // console.log("remainingRequiredTopping", remainingRequiredTopping);
      for (const item of remainingRequiredTopping) {
        let className = "topping-selection-required-" + item;
        // let className = "topping-selection-required-" + item.tCatId;
        allClasses.push(className);
      }
      // console.log("allClasses", allClasses);
      // get all elements
      for (const singleClass of allClasses) {
        const element = document.getElementsByClassName(singleClass);
        allRemainingToppingElements.push(element[0]);
      }
    }

    // console.log("allRemainingToppingElements", allRemainingToppingElements);
    //
    allRemainingToppingElements[0].style.backgroundColor = "rgba(255, 0, 0 , 0.2)";
    setTimeout(() => {
      allRemainingToppingElements[0].style.backgroundColor = "";
    }, 1000);

    smoothScrollIntoView(allRemainingToppingElements[0], {
      behavior: "smooth",
      block: "top",
    });
  };

  return (
    <div className={`CartToggle ${props.cartToggle2 ? "CartToggleTwo" : null}`}>
      {currItem.name && props.toppingsDialog ? (
        <div style={{ display: "flex" }}>
          {/* cart button for normal view */}
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            size="small"
            className="CartButtonCustom"
            style={{ fontSize: screenWidth < 400 ? "16px" : "17px", zIndex: 99999999 }}
            onClick={
              todayTime.open && isOrderPossible
                ? orderType !== ""
                  ? minLimits.length === 0
                    ? // ? currItem.isTimed
                      categories?.find((el) => el.id === currItem.catId)?.type === "offer"
                      ? currItem.offerItemAvailable
                        ? () => {
                            dispatch(
                              addToCart({
                                item: currItem,
                                toppings: currToppings,
                                discPrice: calculatePrice(),
                              })
                            );
                            dispatch(resetCustomize());
                            showSnack(
                              `${currItem.name} ${t("notify.addedToCart")}`,
                              "success",
                              false
                            );
                            props?.setToppingsDialog(false);
                          }
                        : () => {
                            showSnack(
                              `${currItem.name} ${t("notify.isOnlyAvailableOn")} ${
                                currItem.offerMessage
                              }`,
                              "info",
                              true
                            );
                            props?.setToppingsDialog(false);
                          }
                      : () => {
                          dispatch(
                            addToCart({
                              item: currItem,
                              toppings: currToppings,
                              discPrice: calculatePrice(),
                            })
                          );
                          dispatch(resetCustomize());
                          showSnack(
                            `${currItem.name} ${t("notify.addedToCart")}`,
                            "success",
                            false
                          );
                          props?.setToppingsDialog(false);
                        }
                    : () => {
                        showSnack(t("notify.chooseRequiredToppings"), "warning", false);
                        scrollDrinksToView();
                        dispatch(setIsRequiredToppingsNotSelected(true));
                        // props?.setToppingsDialog(false);
                      }
                  : () => {
                      dispatch(selectAreaToggle(true));
                      props?.setToppingsDialog(false);
                    }
                : () => {
                    dispatch(timeDialogToggle(true));
                    props?.setToppingsDialog(false);
                  }
            }
            startIcon={<FaCartPlus style={{ fontSize: 24 }} />}
          >
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
                margin: 0,
                fontWeight: "400",
                textTransform: "capitalize",
                paddingRight: "10px",
              }}
            >
              {currItem.name}&nbsp;
            </p>

            <span style={{ margin: 0 }}>
              <PriceCalculator
                item={currItem}
                price={currItem.price}
                toppingAmount={currToppings.toppingAmount}
              />
            </span>
          </Button>
        </div>
      ) : (
        <React.Fragment>
          {/* cart button for mobile view */}
          <Button
            color="primary"
            fullWidth
            variant="contained"
            size="small"
            style={{ fontSize: "18px" }}
            className="CartButtonCustom relative"
            onClick={(e) => {
              e.stopPropagation();

              dispatch(showCartToggle());
            }}
            sx={{ display: "flex", flexDirection: "column", padding: "0" }}
          >
            <Box className="flex items-center gap-1">
              {/* fixed z-[10] left-[-15px] */}
              <Box className="flex justify-start  w-full absolute z-10 left-[-15px]  ">
                <Box
                  sx={{
                    
                    borderRadius: "50px",
                    px: "7px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: "54px",
                    boxShadow:
                      "0px 3px 5px -4px rgba(0,0,0,0.2),0px 6px 10px -4px rgba(0,0,0,0.14),0px 1px 18px -4px rgba(0,0,0,0.12)",
                    // backgroundColor: "secondary"
                    backgroundColor: theme.palette.secondary.main
                    }}
                  className=" transition-all z-50 shadow-sm "
                  // variant="extended"
                  // size="large"
                  // color="secondary"
                  // aria-label="add"
                  // onTouchStart={() => screenWidth < 768 && setShowFullFab(true)}
                  // onTouchEnd={() => screenWidth < 768 && setShowFullFab(false)}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(ordersContainerToggle(true));
                  }}
                >
                  <Box className="flex flex-col cursor-pointer hover:bg-grey-600 icon-pr">
                    <ReplayRounded style={{ color: "white", fontSize: 55, p: 0 }} />
                    {/* <History className="" style={{ color: "white", fontSize: 110, p: 0 }} /> */}

                    <ShoppingCartRounded
                      className="icon-abs"
                      style={{ color: "white", fontSize: 16, p: 0 }}
                    />
                  </Box>
                </Box>
              </Box>
              <CartCounter itemCount={itemCount} />
              <ShoppingCart fontSize="default" />
              &nbsp;
              {itemCount ? priceFormat.format(subTotal) : "0,00 €"}
            </Box>
            {orderType === "pickup" && defaultPickupTime !== "manual" ? (
              <Box className="flex items-center justify-center gap-2 ">
                <Box className="flex items-center  gap-2 ">
                  <FaClock fontSize={".7rem"} />

                  <Typography variant="caption" fontSize={".7rem"}>
                    {t("Pickup Time")}:
                  </Typography>
                </Box>

                <Typography variant="caption" fontSize={".7rem"}>
                  {defaultPickupTime} mins
                </Typography>
              </Box>
            ) : null}
            {(orderType === "delivery" || orderType === "") && defaultDeliveryTime !== "manual" ? (
              <Box className="flex items-center justify-center gap-2 ">
                <Box className="flex items-center  gap-2 ">
                  <FaClock fontSize={".7rem"} />

                  <Typography variant="caption" fontSize={".7rem"}>
                    {t("Delivery Time")}:
                  </Typography>
                </Box>

                <Typography variant="caption" fontSize={".7rem"}>
                  {defaultDeliveryTime} mins
                </Typography>
              </Box>
            ) : null}
          </Button>
          {area?.area && orderType === "delivery" ? (
            <p
              style={{
                margin: 0,
                fontSize: "13px",
                padding: 5,
                // marginBottom: "6px",
                backgroundColor: theme.palette.primary.light,
              }}
            >
              {t("cart.minOrderAmount")}: <b>{priceFormat.format(area?.minOrderAmount)}</b>
              &nbsp;&nbsp;
              {t("cart.deliveryCharges")}: <b>{priceFormat.format(area?.deliveryCharges)}</b>
            </p>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default CartToggle;
