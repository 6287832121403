import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ItemOfferDialog = (props) => {
  const {
    open,
    setClose,
    handleProceed,
    categoryOfferTimes,
    chosenInterval,
    setChosenInterval,
    itemName,
  } = props;
  const { t } = useTranslation();

  const delayedTimeIntervals = useSelector((state) => state.shop.delayedTimeIntervals);
  const pickupIntervals = useSelector((state) => state.shop.pickupTimeIntervals);
  const deliveryIntervals = useSelector((state) => state.shop.deliveryTimeIntervals);
  const separateTimings = useSelector((state) => state.shop?.settings?.separateTimings);
  const orderType = useSelector((state) => state.cart.orderType);

  const [intervals, setIntervals] = useState([]);

  const handleChange = (event) => {
    setChosenInterval(event.target.value);
  };

  const handleCancel = () => {
    setClose();
  };

  useEffect(() => {
    if (categoryOfferTimes && delayedTimeIntervals) {
      let timeFilters = [];

    // console.log('delayedTimeIntervals', delayedTimeIntervals)
      timeFilters = delayedTimeIntervals?.filter(
        (interval) =>
          interval >= categoryOfferTimes?.offerStartTime &&
          interval <= categoryOfferTimes?.offerEndTime
      );

      setIntervals([...timeFilters]);

      setChosenInterval(timeFilters?.[0]);
      
      // if (categoryTime !== "") {
      //   if (categoryTime === "before") {
      //     const timeFilters = delayedTimeIntervals.filter(
      //       (interval) =>
      //         interval >= categoryOfferTimes?.offerStartTime &&
      //         interval <= categoryOfferTimes?.offerEndTime
      //     );

      //     setIntervals([...timeFilters]);
      //   } else {

      //     const timeFilters = delayedTimeIntervals.filter(
      //       (interval) => interval >= serverTime && interval <= categoryOfferTimes?.offerEndTime
      //     );

      //     setIntervals([...timeFilters]);
      //   }
      // }
    }
  }, [categoryOfferTimes, delayedTimeIntervals]);

  const customDialogActions = (
    <Box className="w-full flex flex-col gap-2">
      <Box className="w-full flex justify-end gap-2">
        <Button onClick={handleCancel} color="primary" fullWidth variant="outlined">
          {t("Cancel")}
        </Button>
        <Button onClick={handleProceed} color="primary" fullWidth variant="contained">
          {t("Proceed")}
        </Button>
      </Box>
    </Box>
  );

  return (
    <CustomDialog
      customSize={"sm"}
      open={open}
      setClose={setClose}
      title={t("Attention")}
      isDialogActions={true}
      customDialogActions={customDialogActions}
    >
      <Box className="p-3 flex flex-col gap-4 ">
        <Typography>
          {itemName}&nbsp;
          {t("is only available between")}&nbsp;
          {categoryOfferTimes?.offerStartTime?.slice(0, 2)}:
          {categoryOfferTimes?.offerStartTime?.slice(2, 4)}
          &nbsp;-&nbsp;
          {categoryOfferTimes?.offerEndTime?.slice(0, 2)}:
          {categoryOfferTimes?.offerEndTime?.slice(2, 4)}
        </Typography>
        <Typography>Sie können aber gerne vorbestellen. Bitte wählen sie ein Uhrzeit.</Typography>

        <Box className="w-[50%]">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t("Time")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={chosenInterval}
              label="Time"
              onChange={handleChange}
            >
              {intervals.map((interval) => {
                return (
                  <MenuItem key={interval} value={interval}>
                    {interval?.toString()?.slice(0, 2)}:{interval?.toString()?.slice(2, 4)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default ItemOfferDialog;
