import React, { useEffect, useState } from "react";

import InputForm from "../../UI/Forms/InputForm/InputForm";
// import Paypal from "../../Paypal/Paypal";

import {
  FormControlLabel,
  ButtonGroup,
  Button,
  Divider,
  Typography,
  Checkbox,
  Box,
  useTheme,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  DialogActions,
} from "@mui/material";
import { Done, ErrorOutline } from "@mui/icons-material";

import "./PlaceOrder.css";
import {
  regOrderForm,
  guestForm,
  loginForm,
  guestPickupForm,
  optionsForm,
  kioskForm,
} from "../../UI/Forms/Forms";
import { checkValidity } from "../../UI/Forms/Validation";

import { v4 as uuidv4 } from "uuid";

import PulseLoader from "react-spinners/PulseLoader";
import { IoIosCash } from "react-icons/io";

import { socket } from "../../../App";

import { useTranslation } from "react-i18next";
import {
  placeOrder,
  setOrderTime,
  storePendingPaymentOrder,
  toggleStripeButtonLoading,
  updatePaymentStatus,
  updatePaymentStatusStripe,
} from "../../../ToolKit/Slices/OrderSlice";
import {
  checkIsShopCurrentlyOpen,
  policyDialogToggle,
  setFutureOrderIntervals,
  setIsPickupAvailable,
  termsDialogToggle,
  timeDialogToggle,
} from "../../../ToolKit/Slices/ShopSlice";
import { loginUser } from "../../../ToolKit/Slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import PaypalSDK from "../../Paypal/PaypalSDK";
import { checkTimeLies } from "../../../ToolKit/Slices/utils";
import { enqueueSnackbar } from "notistack";
import StripeForm from "./StripeForm";
import axios from "axios";
import StripeLogo from "../../UI/StripeLogo/StripeLogo";
import OnlinePayment from "../../UI/SVGIcons/OnlinePayment";
import { FaPaypal } from "react-icons/fa";
import PCMPayment from "../../UI/SVGIcons/PCMPayment";
import ShowTime from "../../Shop/ShopData/TimingsTable/ShowTime";

const showSnack = (message, type, persist) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: persist,
  });
};

const PlaceOrderF = (props) => {
  // console.log("guest", guestForm);
  const dispatch = useDispatch();
  const theme = useTheme();

  // redux states
  const [clientSecret, setClientSecret] = useState("");
  const cartItems = useSelector((state) => state.cart.cartItems);
  const paymentMethods = useSelector((state) => state.shop.paymentMethods);
  const area = useSelector((state) => state.order.selectedArea);
  const areas = useSelector((state) => state.shop.deliveryAreas);
  const deliveryAndPickupTimes = useSelector((state) => state.shop.deliveryAndPickupTimes);
  // const userActionLocal = useState((state) => state.order.userAction);
  const orderType = useSelector((state) => state.cart.orderType);
  const currUser = useSelector((state) => state.user.currUser);
  const userAddresses = useSelector((state) => state.user.addresses);
  const delayedTime = useSelector((state) => state.order.delayedTime);
  const pError = useSelector((state) => state.order.pError);
  const delayedOrder = useSelector((state) => state.order.delayedOrder);
  const voucher = useSelector((state) => state.shop.currVoucher);
  const afterDiscount = useSelector((state) => state.cart.afterVoucherDisc);
  const userDevice = useSelector((state) => state.user.userDevice);
  const currOrder = useSelector((state) => state.order.currOrder);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const deliveryON = useSelector((state) => state.shop.deliveryON);
  const delayedOrderIntervals = useSelector((state) => state.shop.delayedTimeIntervals);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const futureOrderIntervals = useSelector((state) => state.shop.futureOrderIntervals);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);

  const vacationsClosing = useSelector((state) => state.shop.settings?.vacationsClosing);
  const manualClosing = useSelector((state) => state.shop.settings?.manualClosing);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const serverDate = useSelector((state) => state.shop.serverDate);
  const shopOpeningDate = useSelector((state) => state.shop.shopOpeningDate);
  const separateTimings = useSelector((state) => state.shop?.settings?.separateTimings);
  const methodUrls = useSelector((state) => state.order.paymentMethodUrls);
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);
  const firstPayment = paymentMethods[0];
  // const shopOpen = useSelector((state) => state.shop.todayTiming.open);

  // console.log('paymentMethods', paymentMethods)
  // console.log("firstPayment", firstPayment);
  // local state
  const [state, setState] = useState({
    orderForm:
      currUser && orderType !== "kiosk"
        ? optionsForm
        : orderType === "pickup"
        ? loginForm
        : orderType === "kiosk"
        ? kioskForm
        : loginForm,
    userActionLocal: "login",
    formIsValid: currUser ? true : false,
    paymentMethod: firstPayment,
    timeIntervals: [],
    // shopOpen: false,
    userAddress: currUser ? userAddresses.filter((addr) => addr.selected === true)[0] : null,
    termsAccepted: false,
    proceedClicked: false,
    ppButtonLoading: true,
    stripeButtonLoading: false,
  });

  const [session, setSession] = useState({
    orderId: null,
    sessionId: null,
    clientSecret: null,
  });

  useEffect(() => {
    // const futureOrderStartTime = currTimeLocal + 100;

    const futureOrderStartTime = currTimeLocal + 100;
    const newIntervals = delayedOrderIntervals.filter((f) => f > Number(futureOrderStartTime));

    dispatch(setFutureOrderIntervals(newIntervals));
  }, []);

  // useEffect(() => {
  //   // const futureOrderStartTime = currTimeLocal + 100;
  //   const futureOrderStartTime = 2002 + 100;
  //   const newIntervals = delayedOrderIntervals.filter((f) => f > Number(futureOrderStartTime));

  //   dispatch(setFutureOrderIntervals(newIntervals));
  //   // const futureOrderStartTime = currTimeLocal + 100;
  //   let hour;
  //   let minutes;
  //   // if (futureOrderStartTime < 1000) {
  //   if (futureOrderStartTime.toString().length === 3) {
  //     hour = futureOrderStartTime.toString().slice(0, 1);
  //     minutes = futureOrderStartTime.toString().slice(1, 3);
  //   } else if (futureOrderStartTime.toString().length === 4) {
  //     hour = futureOrderStartTime.toString().slice(0, 2);
  //     minutes = futureOrderStartTime.toString().slice(2, 4);
  //   }

  //   const time = preOrderStartTime / 60;

  //   const isTimeInteger = time % 1 === 0;
  //   if (isTimeInteger) {
  //     const newTime = futureOrderStartTime + Number(time.toString() + "00");
  //     const newIntervals = delayedOrderIntervals.filter((f) => f > Number(newTime));

  //     dispatch(setFutureOrderIntervals(newIntervals));
  //   } else {
  //     const splittedTime = time.toString().split(".");

  //     const hourToAdd = Number(splittedTime[0]);

  //     let minutesToAdd = 0;
  //     if (preOrderStartTime === 30 || preOrderStartTime === 90) {
  //       minutes = Math.round(Number(splittedTime[1]) / 1.67) * 10;
  //     } else {
  //       minutes = Math.round(Number(splittedTime[1]) / 1.67);
  //     }

  //     if (Number(minutes) + minutesToAdd > 59) {
  //       hour = (Number(hour) + 1).toString();
  //       minutes = Math.abs(Number(minutes) + minutesToAdd - 60);
  //     } else {
  //       minutes = (Number(minutes) + minutesToAdd).toString();
  //     }

  //     const actualTimeForUser = hour + minutes;

  //     const newIntervals = delayedOrderIntervals.filter((f) => f > Number(actualTimeForUser));

  //     dispatch(setFutureOrderIntervals(newIntervals));
  //   }
  // }, []);
  const afterButtonReady = () => {
    setState({
      ...state,
      ppButtonLoading: false,
    });
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const [pLoading, setPLoading] = useState(true);
  const [numpadDialog, setNumpadDialog] = useState(false);
  const [pincode, setPincode] = useState("");

  const handleNumClick = (num) => {
    if (pincode.length < 4) {
      setPincode(pincode + num);
    }
  };
  const handleClear = () => {
    setPincode("");
  };

  const handleBackspace = () => {
    setPincode(pincode.slice(0, -1));
  };

  const handleSubmitPinCode = () => {
    // Handle pincode submission logic here
    // setNumpadDialog(false);
    // orderHandler();
    axios
      .post(`${import.meta.env.VITE_API_URL}/shop_data/check-kiosk-pin`, {
        pin: pincode,
      })
      .then((res) => {
        if (res.data === true) {
          setNumpadDialog(false);
          orderHandler();
        }
      })
      .catch((err) => {
        showSnack(`Pincode enter is not valid, try again`, "warning", false);
      });
  };

  const orderHandler = () => {
    const siteUrl = window.location.origin;

    if (separateTimings && orderType !== "kiosk") {
      if (
        ((!isPickupAvailable?.status && isPickupAvailable?.message === "less") ||
          (!isDeliveryAvailable?.status && isDeliveryAvailable?.message === "less")) &&
        delayedOrder
      ) {
        if (
          delayedTime === "" ||
          delayedTime === t("Choose Time") ||
          delayedTime === t("As soon as possible")
        ) {
          showSnack("Choose a valid interval please :(", "error");
          return;
        }
      }
    }
    // console.log('currTimeLocal', currTimeLocal)
    // const currentServerTime = setServerTimeLocal(currTimeLocal);
    // console.log('currentServerTime', currentServerTime)

    let todaysTime = { ...todayTiming };

    if (separateTimings) {
      todaysTime.timings = [deliveryAndPickupTimes];
    }
    console.log("todaysTime", todaysTime);
    const payloadObject = {
      todayTiming: todaysTime,
      serverTime: currTimeLocal,
      manualClosing,
      vacationsClosing,
      serverDate,
      shopOpeningDate,
      separateTimings,
    };

    dispatch(checkIsShopCurrentlyOpen(payloadObject));

    // const timeLies = checkTimeLies(todayTiming?.timings, currTimeLocal);
    const timeLies = checkTimeLies(todaysTime?.timings, currTimeLocal);

    let formData = {};
    for (let formElementIdentifier in state.orderForm) {
      if (formElementIdentifier !== "deliveryTime") {
        formData[formElementIdentifier] = state.orderForm[formElementIdentifier].value.trim();
      }
    }
    let itemsAndDiscount = [];
    const itemsWithDiscount = cartItems.filter((cartItem) => cartItem?.isDiscount === true);
    itemsWithDiscount.forEach((f) => {
      itemsAndDiscount.push({ id: f.id, discount: f.discounts });
    });
    // console.log("itemsWithDiscount", itemsWithDiscount);
    // console.log("itemsAndDiscount", itemsAndDiscount);
    formData = {
      ...formData,
      addrId: uuidv4(),
      postalCode: area.postalCode,
      city: area.area,
      selected: true,
      userAreaId: area.id,
      socketId: socket.id,
      device: userDevice,
      amountSaved: (props.actualTotal - props.subTotal).toFixed(2),
      discounts: discounts,
      itemDiscount: itemsWithDiscount.length > 0 ? itemsAndDiscount : [],
    };
    const order = {
      orderType: orderType,
      delayedOrder: orderType === "kiosk" ? false : delayedOrder,
      delayedTime:
        orderType === "kiosk"
          ? null
          : delayedOrder
          ? delayedTime
          : state.orderForm.deliveryTime.value,
      items: cartItems,
      subTotal: props.subTotal,
      voucher: voucher,
      afterDiscount: voucher ? afterDiscount : null,
      deliveryCharges: props.deliveryCharges,
      grandTotal: props.grandTotal,
      paymentMethod: state.paymentMethod.method,
      paymentStatus:
        state.paymentMethod.method === "Paypal" || state.paymentMethod.method === "Stripe"
          ? "pending"
          : null,
      userData:
        currUser && state.userActionLocal !== "change"
          ? {
              ...currUser,
              ...state.userAddress,
              message: formData.message,
              socketId: socket.id,
              amountSaved: (props.actualTotal - props.subTotal).toFixed(2),
              device: userDevice,
              discounts: discounts,
              itemDiscount: itemsWithDiscount.length > 0 ? itemsAndDiscount : [],
            }
          : formData,
      userType: currUser ? "Registered" : "Guest",
      paypalData: null,
      stripeData: null,
    };

    if (orderType === "kiosk") {
      console.log("place order kiosk");
      if (state.paymentMethod.method === "Paypal") {
        dispatch(
          storePendingPaymentOrder({
            orderData: order,
            userAction: state.userActionLocal,
            currUser,
          })
        ).then(() => {
          setPLoading(false);
        });
      } else if (state.paymentMethod.method === "Stripe") {
        dispatch(
          storePendingPaymentOrder({
            orderData: order,
            userAction: state.userActionLocal,
            currUser,
          })
        ).then((res1) => {
          console.log("Stripe response", res1);

          if (res1?.payload?.error !== undefined) {
            setState({
              ...state,
              proceedClicked: true,
            });
            dispatch(toggleStripeButtonLoading(false));
          } else {
            setPLoading(false);
            axios
              .post(`${import.meta.env.VITE_API_URL}/stripe/embedded-checkout`, {
                siteUrl: window.location.origin,
                grandTotal: newGrandTotal,
                orderId: res1.payload.id,
                userData: order.userData,
              })
              .then((response) => {
                if (state.userActionLocal === "register") {
                  const data = { ...order.userData, orders: res1.payload.id };

                  setSession({
                    ...session,
                    orderId: res1.payload.id,
                    clientSecret: response.data.client_secret,
                    sessionId: response.data.id,
                    userData: data,
                  });
                  setState({
                    ...state,
                    stripeButtonLoading: true,
                  });
                  dispatch(toggleStripeButtonLoading(false));
                } else {
                  setSession({
                    ...session,
                    orderId: res1.payload.id,
                    clientSecret: response.data.client_secret,
                    sessionId: response.data.id,
                    userData: null,
                  });
                  setState({
                    ...state,
                    stripeButtonLoading: true,
                  });
                  dispatch(toggleStripeButtonLoading(false));
                }
              });
          }
        });
      } else {
        dispatch(placeOrder({ orderData: order, userAction: state.userActionLocal, currUser }));
      }
    } else if (timeLies.status) {
      if (state.paymentMethod.method === "Paypal") {
        dispatch(
          storePendingPaymentOrder({
            orderData: order,
            userAction: state.userActionLocal,
            currUser,
          })
        ).then(() => {
          setPLoading(false);
        });
      } else if (state.paymentMethod.method === "Stripe") {
        dispatch(
          storePendingPaymentOrder({
            orderData: order,
            userAction: state.userActionLocal,
            currUser,
          })
        ).then((res1) => {
          console.log("Stripe response", res1);

          if (res1?.payload?.error !== undefined) {
            setState({
              ...state,
              proceedClicked: true,
            });
            dispatch(toggleStripeButtonLoading(false));
          } else {
            setPLoading(false);
            axios
              .post(`${import.meta.env.VITE_API_URL}/stripe/embedded-checkout`, {
                siteUrl: window.location.origin,
                grandTotal: newGrandTotal,
                orderId: res1.payload.id,
                userData: order.userData,
              })
              .then((response) => {
                if (state.userActionLocal === "register") {
                  const data = { ...order.userData, orders: res1.payload.id };

                  setSession({
                    ...session,
                    orderId: res1.payload.id,
                    clientSecret: response.data.client_secret,
                    sessionId: response.data.id,
                    userData: data,
                  });
                  setState({
                    ...state,
                    stripeButtonLoading: true,
                  });
                  dispatch(toggleStripeButtonLoading(false));
                } else {
                  setSession({
                    ...session,
                    orderId: res1.payload.id,
                    clientSecret: response.data.client_secret,
                    sessionId: response.data.id,
                    userData: null,
                  });
                  setState({
                    ...state,
                    stripeButtonLoading: true,
                  });
                  dispatch(toggleStripeButtonLoading(false));
                }
              });
          }
        });
      } else {
        dispatch(placeOrder({ orderData: order, userAction: state.userActionLocal, currUser }));
      }
    } else {
      if (timeLies?.message === "less") {
        if (state.paymentMethod.method === "Paypal") {
          dispatch(
            storePendingPaymentOrder({
              orderData: order,
              userAction: state.userActionLocal,
              currUser,
            })
          ).then(() => {
            setPLoading(false);
          });
        } else if (state.paymentMethod.method === "Stripe") {
          dispatch(
            storePendingPaymentOrder({
              orderData: order,
              userAction: state.userActionLocal,
              currUser,
            })
          ).then((res1) => {
            if (res1?.payload?.error !== undefined) {
              setState({
                ...state,
                proceedClicked: true,
              });
              dispatch(toggleStripeButtonLoading(false));
            } else {
              setPLoading(false);

              axios
                .post(`${import.meta.env.VITE_API_URL}/stripe/embedded-checkout`, {
                  siteUrl: window.location.origin,
                  grandTotal: newGrandTotal,
                  orderId: res1.payload.id,
                  userData: order.userData,
                })
                .then((response) => {
                  // console.log("response embedded-checkout =>", response);

                  if (state.userActionLocal === "register") {
                    const data = { ...order.userData, orders: res1.payload.id };

                    setSession({
                      ...session,
                      orderId: res1.payload.id,
                      clientSecret: response.data.client_secret,
                      sessionId: response.data.id,
                      userData: data,
                    });
                    setState({
                      ...state,
                      stripeButtonLoading: true,
                    });
                    dispatch(toggleStripeButtonLoading(false));
                  } else {
                    setSession({
                      ...session,
                      orderId: res1.payload.id,
                      clientSecret: response.data.client_secret,
                      sessionId: response.data.id,
                      userData: null,
                    });
                    setState({
                      ...state,
                      stripeButtonLoading: true,
                    });
                    dispatch(toggleStripeButtonLoading(false));
                  }
                });
            }
          });
        } else {
          dispatch(placeOrder({ orderData: order, userAction: state.userActionLocal, currUser }));
        }
      } else {
        dispatch(timeDialogToggle(true));
        showSnack("Shop is Closed ", "info", true);
      }
    }
  };

  const paypalPendingOrderHandler = () => {
    setState({
      ...state,
      proceedClicked: true,
    });
    orderHandler();
  };

  const stripePendingOrderHandler = () => {
    dispatch(toggleStripeButtonLoading(true));
    // setState({
    //   ...state,
    //   stripeButtonLoading: true,
    // });
    // dispatch(toggleStripeButtonLoading(true));
    orderHandler();
  };

  const loginHandler = (formDetails) => {
    dispatch(loginUser({ formDetails, areas, deliveryON, orderType }));
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
      ...state.orderForm,
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier],
    };
    if (inputIdentifier === "deliveryTime") {
      if (
        event.target.value === "As soon as possible" ||
        event.target.value === "So schnell wie möglich"
      ) {
        dispatch(setOrderTime({ time: "As soon as possible", delayed: false }));
      } else {
        dispatch(setOrderTime({ time: event.target.value, delayed: true }));
      }
    }

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      state.userActionLocal === "register" ? state.orderForm.password.value : null
    );

    updatedFormElement.touched = true;
    console.log("updatedFormElement", updatedFormElement);
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
    }

    setState({ ...state, orderForm: updatedOrderForm, formIsValid: formIsValid });
  };

  const termsHandler = () => {
    setState({ ...state, termsAccepted: !state.termsAccepted });
  };

  const paypalFailHandler = () => {
    alert("Transaktion fehlgeschlagen ! Bitte versuche es erneut");

    const status = "failed";
    const paymentData = null;

    dispatch(updatePaymentStatus({ status, paymentData, orderId: currOrder.id, currUser }));
  };

  const paypalCancelHandler = () => {
    alert("Transaktion abgebrochen! Bestellung ist nicht abgeschlossen");
    // console.log("paypal transaction canceled");
    const status = "canceled";
    const paymentData = null;

    dispatch(updatePaymentStatus({ status, paymentData, orderId: currOrder.id, currUser }));
  };

  const updatePaypalOrder = (paymentData) => {
    const status = "payed";

    dispatch(
      updatePaymentStatus({ status, payment: paymentData, orderId: currOrder?.id, currUser })
    );
  };

  const { grandTotal } = props;

  const { t, i18n } = useTranslation();
  const {
    orderForm,
    formIsValid,
    userActionLocal,
    paymentMethod,
    timeIntervals,
    // shopOpen,
    termsAccepted,
    userAddress,
    proceedClicked,
    ppButtonLoading,
  } = state;

  //   If user orders 10 euro then paypal charges = 10.71 (total + paypal charges)
  // 10 + charges + 2.99%of charges = Total amount user has to pay

  // 0.299 0.39 = 0.69

  // 10
  // paypal charges
  let extraCharge = paymentMethod.methodCharges + (grandTotal * paymentMethod.percentCharge) / 100;
  // 0.39 (methodCharge) + 0.299 (percentCharge of grandTotal)
  // 0.689 extraCharge
  // 0.0206011 surcharge
  let surCharge = (extraCharge * paymentMethod.percentCharge) / 100;

  let newGrandTotal = grandTotal;
  if (paymentMethod.extraFeeByUser) {
    newGrandTotal = grandTotal + extraCharge + surCharge;
    // newGrandTotal = grandTotal + extraCharge;
  }

  const priceFormat = window.$priceFormater;

  const formElementsArray = [];
  for (let key in orderForm) {
    formElementsArray.push({
      id: key,
      config: orderForm[key],
    });
  }

  const saveDetails = () => {
    setState({
      ...state,
      orderForm: state.orderForm.street
        ? {
            ...regOrderForm,
            ...state.orderForm,
          }
        : regOrderForm,

      // orderType === "delivery" ? regOrderForm : regPickupForm,
      userActionLocal: "register",
      formIsValid: false,
    });
  };

  const handleDisablePlaceOrderButtonKiosk = () => {
    if (formIsValid && termsAccepted) {
      return false;
    } else {
      return true;
    }
  };

  const handleDisablePlaceOrderButton = () => {
    if (!separateTimings) {
      if (isShopCurrentlyOpen.status || delayedOrder) {
        if (formIsValid && termsAccepted) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          state.orderForm.deliveryTime.value !== "As soon as possible" &&
          state.orderForm.deliveryTime.value !== ""
        ) {
          if (formIsValid && termsAccepted) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      if (isShopCurrentlyOpen.status || delayedOrder) {
        // if (formIsValid && termsAccepted) {
        //   return false;
        // } else {
        //   return true;
        // }

        if (
          ((orderType === "pickup" &&
            !isPickupAvailable?.status &&
            isPickupAvailable?.message === "less") ||
            (orderType === "delivery" &&
              !isDeliveryAvailable?.status &&
              isDeliveryAvailable?.message === "less")) &&
          delayedOrder
        ) {
          if (
            state.orderForm.deliveryTime.value !== "As soon as possible" &&
            state.orderForm.deliveryTime.value !== ""
          ) {
            if (formIsValid && termsAccepted) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          if (formIsValid && termsAccepted) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (
          state.orderForm.deliveryTime.value !== "As soon as possible" &&
          state.orderForm.deliveryTime.value !== ""
        ) {
          if (formIsValid && termsAccepted) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    }
  };


  let form = (
    <form className="ContactForm">
      {formElementsArray.map((formElement) => (
        <>
          {formElement?.id === "deliveryTime" &&
            separateTimings &&
            (orderType === "delivery" &&
            !isDeliveryAvailable?.status &&
            isDeliveryAvailable?.message === "less" ? (
              <div className="flex items-end justify-center gap-2">
                <Typography>{t("Delivery Time")}</Typography>
                {/* styleType={"col"} */}
                <Box className="font-bold">
                  <ShowTime timings={todayTiming?.deliveryTimings} fontWeight={"900"} />
                </Box>
              </div>
            ) : (
              orderType === "pickup" &&
              !isPickupAvailable?.status &&
              isPickupAvailable?.message === "less" && (
                <div className="flex items-end justify-center gap-2">
                  <Typography>{t("Pickup Time")}</Typography>
                  {/* styleType={"col"} */}
                  <ShowTime timings={todayTiming?.pickupTimings} fontWeight={"900"} />
                </div>
              )
            ))}

          <InputForm
            formElement={formElement}
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            timeIntervals={
              isShopCurrentlyOpen.status
                ? futureOrderIntervals
                : delayedOrderIntervals?.filter((f) => f > Number(isShopCurrentlyOpen?.time?.[0]))
            }
            touched={formElement.config.touched}
            shopOpen={isShopCurrentlyOpen.status}
            elementName={formElement.config.elementName}
            changed={(event) => inputChangedHandler(event, formElement.id)}
            userAction={userActionLocal}
            saveDetails={saveDetails}
            orderType={orderType}
          />
        </>
      ))}
    </form>
  );

  //   const storage = getStorage(app);

  // const storageRef = ref(storage, "images/" + imageName);

  // const url = await getDownloadURL(storageRef)

  // console.log("paymentMethods", paymentMethods);
  let paymentMethodDiv = (
    <Box className="mb-2">
      {paymentMethods.map((method, index) => {
        if (
          method.availableOn.includes(
            orderType.slice(0, 1).toUpperCase() + orderType.slice(1, orderType.length)
          ) ||
          orderType === "kiosk"
        )
          if (method.isActive)
            if (
              method.method === "Stripe" ||
              method.method === "Cash on Delivery" ||
              method.method === "Paypal" ||
              method.method === "EC"
            )
              return (
                <Box
                  onClick={() => setState({ ...state, paymentMethod: method })}
                  sx={{
                    border:
                      paymentMethod.method === method.method
                        ? "2px solid #9ccc65"
                        : "2px solid transparent",
                    mb: 2,
                    // minHeight: "95px"
                  }}
                  className={` relative w-full sm:w-[82%] cursor-pointer   p-1 ${
                    method.method === "Stripe" ? "py-5" : "py-3"
                  } mx-auto hover:shadow-md transition-shadow duration-300  shadow-md  rounded-lg`}
                >
                  <Box className="flex flex-col  gap-3 ">
                    {/* logo */}
                    <Box className="flex items-end gap-2">
                      {method.method === "Cash on Delivery" ? (
                        <IoIosCash
                          style={{
                            color: theme.palette.primary.main,
                            // fontSize: "2rem",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      ) : method.method === "Stripe" ? (
                        <OnlinePayment
                          color={theme.palette.primary.main}
                          height={"30px"}
                          width={"30px"}
                        />
                      ) : method.method === "Paypal" ? (
                        <FaPaypal
                          // height={"30px"}
                          // width={"30px"}
                          fontSize={"2rem"}
                          style={{
                            color: theme.palette.primary.main,
                            // color:
                            //   paymentMethod.method === method.method ? "#fff" : "rgb(26, 76, 197)",
                          }}
                        />
                      ) : (
                        <PCMPayment
                          color={theme.palette.primary.main}
                          height={"30px"}
                          width={"30px"}
                        />
                      )}
                      <Box className="flex items-start gap-2">
                        <Typography variant="caption" color={"text.primary"}>
                          {method.method === "Cash on Delivery"
                            ? t("checkout.cash")
                            : method.method === "Stripe"
                            ? t("Online Payment")
                            : method.method === "Paypal"
                            ? "Paypal"
                            : "EC"}
                        </Typography>

                        <Box className="flex items-center gap-2">
                          <Typography
                            variant="caption"
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            {method.extraFeeByUser ? (
                              <span>
                                +
                                {method.method === "Paypal"
                                  ? priceFormat.format(
                                      method.methodCharges +
                                        (grandTotal * method.percentCharge) / 100 +
                                        ((method.methodCharges +
                                          (grandTotal * method.percentCharge) / 100) *
                                          method.percentCharge) /
                                          100
                                    )
                                  : priceFormat.format(
                                      method.methodCharges +
                                        (grandTotal * method.percentCharge) / 100
                                    )}
                              </span>
                            ) : null}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {method.method === "Stripe" && (
                      <Box className="flex items-center flex-wrap gap-3">
                        {methodUrls?.slice(0, 5).map((el) => {
                          return (
                            <img
                              className=" w-[20px] h-[20px]"
                              src={el}
                              alt="payment methods stripe"
                            />
                          );
                        })}
                      </Box>
                    )}
                  </Box>

                  {paymentMethod.method === method.method && (
                    <Box className="absolute top-[10px] right-[10px]">
                      <IconButton
                        size="small"
                        sx={{
                          // border: "1px solid #9ccc65",
                          backgroundColor: "green",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                      >
                        <Done fontSize="small" htmlColor="white" />
                      </IconButton>
                      {/* <CheckCircleRounded color="success" fontSize="large" /> */}
                    </Box>
                  )}
                </Box>
              );
      })}

      {/* <div className="PaymentMethods">
        {paymentMethods.map((method, index) => {
          if (
            method.availableOn.includes(
              orderType.slice(0, 1).toUpperCase() + orderType.slice(1, orderType.length)
            )
          )
            if (method.isActive)
              return (
                <Paper
                  className="PaymentMethodsPaper"
                  sx={{
                    backgroundColor:
                      paymentMethod.method === method.method && theme.palette.primary.main,
                  }}
                  key={index}
                  elevation={2}
                  value={method}
                  onClick={() => setState({ ...state, paymentMethod: method })}
                >
                  <div>
                    {paymentMethod.method === method.method ? (
                      <Done sx={{ color: "inherit" }} />
                    ) : null}
                    &nbsp;
                  </div>
                  <div className="PaymentIcons">
                    <IconContext.Provider value={{ size: "3em" }}>
                      {method.method === "Cash on Delivery" ? (
                        <IoIosCash
                          style={{
                            color: paymentMethod.method === method.method ? "#fff" : "#61b585",
                          }}
                        />
                      ) : method.method === "Paypal" ? (
                        <FaPaypal
                          style={{
                            color:
                              paymentMethod.method === method.method ? "#fff" : "rgb(26, 76, 197)",
                          }}
                        />
                      ) : method.method === "Stripe" ? (
                        <img
                          className="w-[45px]"
                          src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2FStripe_square.svg?alt=media&token=b982bd72-0ac5-4eb0-896e-bec31502aa7e"
                          alt=""
                        />
                      ) : (
                        <GrCreditCard
                          style={{
                            color: paymentMethod.method === method.method ? "#fff" : "#61b585",
                          }}
                        />
                      )}
                    </IconContext.Provider>
                  </div>
                  <span style={{ color: "text.primary" }}>
                    {method.method === "Cash on Delivery" ? t("checkout.cash") : method.method}
                  </span>

                  <br></br>
                  <Box>
                    {method.extraFeeByUser ? (
                      <span>
                        +
                        {method.method === "Paypal"
                          ? priceFormat.format(
                              method.methodCharges +
                                (grandTotal * method.percentCharge) / 100 +
                                ((method.methodCharges +
                                  (grandTotal * method.percentCharge) / 100) *
                                  method.percentCharge) /
                                  100
                            )
                          : priceFormat.format(
                              method.methodCharges + (grandTotal * method.percentCharge) / 100
                            )}
                      </span>
                    ) : null}
                  </Box>
                </Paper>
              );
        })}
      </div> */}
    </Box>
  );

  // console.log("pLoading", pLoading);

  return (
    <>
      {/* {state.stripeButtonLoading && <Backdrop open={true} />} */}

      <div>
        <span className="CheckoutLanguage">
          <ButtonGroup size="small">
            <Button
              style={{
                color: i18n.language === "de" ? theme.palette.primary.main : "lightgrey",
              }}
              onClick={() => {
                i18n.changeLanguage("de");
              }}
            >
              DE
            </Button>
            <Button
              style={{
                color: i18n.language === "en" ? theme.palette.primary.main : "lightgrey",
              }}
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              EN
            </Button>
          </ButtonGroup>
        </span>
        {
          // pLoading ? (
          //   <PulseLoader color={theme.palette.primary.main} />
          // ) :

          proceedClicked ? (
            pError !== null ? (
              <div>
                <ErrorOutline style={{ color: "red", fontSize: 100 }} />
                <br></br>
                <p className="text-red-500">{t("orderSummary.somethingWentWrong")}</p>
              </div>
            ) : (
              <React.Fragment>
                {pLoading ? (
                  <PulseLoader color={theme.palette.primary.main} />
                ) : (
                  <>
                    <Typography variant="h6" className="CartTitles">
                      {t("checkout.total")} {priceFormat.format(newGrandTotal)}
                    </Typography>
                    <p className="WarningText">
                      <ErrorOutline />
                      &nbsp;
                      {t("checkout.toCompleteYourOrderPleaseWaitAfterPaypalPayment")}
                    </p>

                    <p style={{ opacity: ppButtonLoading ? 1 : 0 }}>
                      <PulseLoader color={theme.palette.primary.main} />
                      {t("checkout.pleaseWait")}
                    </p>
                    <PaypalSDK
                      userAction={state.userActionLocal}
                      ppButtonLoading={ppButtonLoading}
                      afterButtonReady={afterButtonReady}
                      grandTotal={newGrandTotal.toFixed(2)}
                      onSuccess={updatePaypalOrder}
                      onError={paypalFailHandler}
                      onCancel={paypalCancelHandler}
                    />
                  </>
                )}
              </React.Fragment>
            )
          ) : (
            <div>
              <br />
              <br />
              {currUser || orderType === "kiosk" ? null : (
                <ButtonGroup
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="primary"
                  aria-label="contained primary button group"
                >
                  <Button
                    onClick={() =>
                      setState({
                        ...state,
                        orderForm: orderType === "delivery" ? guestForm : guestPickupForm,
                        userActionLocal: "guest",
                        formIsValid: false,
                      })
                    }
                    variant={userActionLocal === "guest" ? "contained" : "outlined"}
                  >
                    {t("checkout.orderAsGuest")}
                    {userActionLocal === "guest" ? <Done fontSize="small" /> : null}
                  </Button>
                  <Button
                    style={{
                      display: orderType === "delivery" ? "block" : "none",
                    }}
                    onClick={() =>
                      setState({
                        ...state,
                        orderForm: state.orderForm.street
                          ? {
                              ...regOrderForm,
                              ...state.orderForm,
                            }
                          : regOrderForm,

                        // orderType === "delivery" ? regOrderForm : regPickupForm,
                        userActionLocal: "register",
                        formIsValid: false,
                      })
                    }
                    variant={userActionLocal === "register" ? "contained" : "outlined"}
                  >
                    {t("checkout.registerAndOrder")}
                    {userActionLocal === "register" ? <Done fontSize="small" /> : null}
                  </Button>
                  <Button
                    onClick={() =>
                      setState({ ...state, orderForm: loginForm, userActionLocal: "login" })
                    }
                    variant={userActionLocal === "login" ? "contained" : "outlined"}
                  >
                    {t("checkout.loginAndOrder")}
                    {userActionLocal === "login" ? <Done fontSize="small" /> : null}
                  </Button>
                </ButtonGroup>
              )}
              {currUser ? (
                <React.Fragment>
                  <Typography variant="h6" className="CartTitles">
                    {t("checkout.yourDetails")}
                  </Typography>
                  <>
                    <Card className="UserDetails">
                      <div style={{ display: "inline-flex", width: "100%" }}>
                        <ul
                          style={{
                            width: "50%",
                            fontSize: "16px",
                            color: "grey",
                            padding: "0",
                          }}
                        >
                          <li>{currUser.name}</li>
                          <li>{currUser.email}</li>
                          <li>{currUser.phone}</li>
                        </ul>
                        {orderType === "delivery" ? (
                          <ul
                            style={{
                              width: "50%",
                              fontSize: "16px",
                              color: "grey",
                              padding: "0",
                            }}
                          >
                            <li style={{ textAlign: "right" }}>
                              {userAddress.street}, {t("forms.Floor")}:{userAddress.floor}
                            </li>
                            <li style={{ textAlign: "right" }}>
                              {t("forms.Bell Name")}:{userAddress.bellName}
                            </li>
                            <li style={{ textAlign: "right" }}>
                              {userAddress.postalCode}, {userAddress.city}
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </Card>
                  </>
                </React.Fragment>
              ) : null}

              {form}
              <Divider />
              {userActionLocal !== "login" || currUser || orderType === "kiosk" ? (
                <div id="buttons">
                  <Typography variant="h6" className="CartTitles">
                    {t("checkout.paymentMethodHeading")}
                  </Typography>
                  {paymentMethodDiv}
                  <Divider />
                  <Typography variant="h6" className="CartTitles">
                    {t("checkout.total")} {priceFormat.format(newGrandTotal)}
                  </Typography>
                  <span>
                    <Button
                      onClick={() => dispatch(termsDialogToggle(true))}
                      variant="text"
                      color="primary"
                      size="small"
                      style={{
                        textDecoration: "underline",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("topBar.termsOfUsage")}
                    </Button>
                  </span>
                  <span>
                    <Button
                      onClick={() => dispatch(policyDialogToggle(true))}
                      variant="text"
                      color="primary"
                      size="small"
                      style={{
                        textDecoration: "underline",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("topBar.dataPolicy")}
                    </Button>
                  </span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termsAccepted}
                        onChange={termsHandler}
                        name="checkedB"
                        // color="primary"
                        size="small"
                      />
                    }
                    label={t("checkout.acceptTermsConditions")}
                    sx={{
                      color: "text.primary",
                    }}
                  />

                  {!formIsValid ? (
                    <p className="WarningText">{t("checkout.enterAllFields")}</p>
                  ) : null}
                  {paymentMethod.method === "Paypal" && formIsValid ? (
                    <React.Fragment>
                      <Button
                        onClick={paypalPendingOrderHandler}
                        disabled={handleDisablePlaceOrderButton()}
                        // disabled={
                        //   isShopCurrentlyOpen.status || delayedOrder
                        //     ? formIsValid && termsAccepted
                        //       ? false
                        //       : true
                        //     : state.orderForm.deliveryTime.value !== "As soon as possible" &&
                        //       state.orderForm.deliveryTime.value !== ""
                        //     ? formIsValid && termsAccepted
                        //       ? false
                        //       : true
                        //     : true
                        // }
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        style={{ marginBottom: "50px" }}
                      >
                        {t("checkout.proceedToPaypal")} {priceFormat.format(newGrandTotal)}
                      </Button>
                    </React.Fragment>
                  ) : paymentMethod.method === "Stripe" && formIsValid ? (
                    <React.Fragment>
                      <Button
                        onClick={stripePendingOrderHandler}
                        disabled={handleDisablePlaceOrderButton()}
                        // disabled={
                        //   isShopCurrentlyOpen.status || delayedOrder
                        //     ? formIsValid && termsAccepted
                        //       ? false
                        //       : true
                        //     : state.orderForm.deliveryTime.value !== "As soon as possible" &&
                        //       state.orderForm.deliveryTime.value !== ""
                        //     ? formIsValid && termsAccepted
                        //       ? false
                        //       : true
                        //     : true
                        // }
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        style={{ marginBottom: "50px" }}
                      >
                        proceed to {t("Online Payment")} {priceFormat.format(newGrandTotal)}
                      </Button>

                      {state.stripeButtonLoading && (
                        <StripeForm
                          closeDialog={(success) => {
                            if (session.clientSecret && session.sessionId && !success) {
                              showSnack(
                                "Transaktion abgebrochen! Bestellung ist nicht abgeschlossen",
                                "error"
                              );

                              // alert("Transaktion abgebrochen! Bestellung ist nicht abgeschlossen");
                              // console.log("paypal transaction canceled");
                              const status = "canceled";
                              const paymentData = null;

                              // setTimeout(() => {
                              dispatch(
                                updatePaymentStatusStripe({
                                  status,
                                  payment: paymentData,
                                  orderId: session.orderId,
                                  currUser,
                                })
                              ).then(() => {});
                              // }, 2000);
                            }
                            setState({
                              ...state,
                              stripeButtonLoading: false,
                            });
                          }}
                          session={session}
                          total={newGrandTotal}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <Button
                      onClick={() => {
                        if (orderType === "kiosk") {
                          setNumpadDialog(true);
                        } else {
                          orderHandler();
                        }
                      }}
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      disabled={
                        orderType === "kiosk"
                          ? handleDisablePlaceOrderButtonKiosk()
                          : handleDisablePlaceOrderButton()
                      }
                      // disabled={
                      //   !separateTimings
                      //     ? isShopCurrentlyOpen.status || delayedOrder
                      //       ? formIsValid && termsAccepted
                      //         ? false
                      //         : true
                      //       : state.orderForm.deliveryTime.value !== "As soon as possible" &&
                      //         state.orderForm.deliveryTime.value !== ""
                      //       ? formIsValid && termsAccepted
                      //         ? false
                      //         : true
                      //       : true
                      //     : ""
                      // }
                      style={{ marginBottom: "50px" }}
                    >
                      {t("button.placeOrder")} {priceFormat.format(newGrandTotal)}
                    </Button>
                  )}
                </div>
              ) : orderType !== "kiosk" ? (
                <div>
                  <Button
                    onClick={() => loginHandler(state.orderForm)}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!formIsValid}
                  >
                    {t("button.login")}
                  </Button>
                </div>
              ) : null}
              <div id="bottom"></div>
            </div>
          )
        }
      </div>

      <Dialog open={numpadDialog} onClose={() => setNumpadDialog(false)}>
        <DialogTitle>
          <Typography>Enter Pincode</Typography>
          <Typography>Please enter pincode shown on kiosk screen</Typography>
        </DialogTitle>

        <DialogContent>
          {/* <TextField
          value={pincode}
          inputProps={{ maxLength: 4, style: { textAlign: 'center' } }}
          variant="outlined"
          fullWidth
          disabled
        /> */}
          <p className="text-[25px] font-bold text-center h-[20px]">{pincode}</p>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num, index) => (
              <Grid item xs={4} key={index}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleNumClick(num.toString())}
                  disabled={pincode.length >= 4}
                >
                  <p className="text-[20px]">{num}</p>
                </Button>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Button
                sx={{ fontSize: "20px" }}
                variant="contained"
                fullWidth
                onClick={handleBackspace}
              >
                ⌫
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button sx={{ fontSize: "20px" }} variant="contained" fullWidth onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNumpadDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitPinCode} color="primary" disabled={pincode.length !== 4}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlaceOrderF;
