import { combineReducers } from "@reduxjs/toolkit";

import { CartSlice } from "./CartSlice";
import { ItemSlice } from "./ItemSlice";
import { MenuSlice } from "./MenuSlice";
import { NotifierSlice } from "./NotifierSlice";
import { OrderSlice } from "./OrderSlice";
import { ShopSlice } from "./ShopSlice";
import { ToppingSlice } from "./ToppingSlice";
import { UserSlice } from "./UserSlice";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  // whitelist: ['cart'],
  whitelist: [""],
};

const cartPersistConfig = {
  key: "cart",
  storage: storageSession,
};

// const cartPersistConfig2 = {
//   key: "cart",
//   storage: storage,
//   whitelist:["orderType"]
// };

const orderPersistConfig = {
  key: "order",
  storage: storage,
  // whitelist: ["selectedArea", "currOrder", "currOrderStatus", "emailSent"],
  whitelist: [
    "selectedArea",
    "currOrdersTrackers",
    "localOrders",
    "currOrder",
    "isOfferTimeItemSelected",
    "stripeSession",
  ],
};

const userPersistConfig = {
  key: "user",
  storage: storage,
  blacklist: [
    "isLoading",
    "userDialogVisible",
    "profileVisible",
    "userAction",
    "isLoading",
    "error",
    "currOrder",
    "offerClosed",
    "viewSmartBar",
    "addingNewAddress",
    "isDeleteAccount",
    // "theme",
  ],
};

const shopPersistConfig = {
  key: "shop",
  storage: storageSession,
  whitelist: ["currVoucher", "runtimeError", "isError", "errorSent"],
};

// ROOT REDUCER

const rootReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, CartSlice.reducer),
  item: ItemSlice.reducer,
  menu: MenuSlice.reducer,
  notifier: NotifierSlice.reducer,
  order: persistReducer(orderPersistConfig, OrderSlice.reducer),
  shop: persistReducer(shopPersistConfig, ShopSlice.reducer),
  topping: ToppingSlice.reducer,
  user: persistReducer(userPersistConfig, UserSlice.reducer),
});

export default persistReducer(rootPersistConfig, rootReducer);
