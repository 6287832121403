const PCMPayment = (props) => {
  const { color, height, width } = props;
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      stroke="#000000"
      stroke-width="5.12"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M264.551,94.063h-34.206c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.206 c4.427,0,8.017-3.589,8.017-8.017S268.979,94.063,264.551,94.063z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M196.142,94.063h-51.307c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S200.569,94.063,196.142,94.063z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M264.551,59.858h-17.104c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.104 c4.427,0,8.017-3.589,8.017-8.017S268.979,59.858,264.551,59.858z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M213.243,59.858h-17.097c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.097 c4.427,0,8.017-3.589,8.017-8.017S217.671,59.858,213.243,59.858z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M161.939,59.858h-17.103c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.103 c4.427,0,8.017-3.589,8.017-8.017S166.366,59.858,161.939,59.858z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M435.574,222.33h-86.046V110.631c0-23.281-18.941-42.221-42.221-42.221h-0.534V8.017c0-2.615-1.275-5.065-3.417-6.566 c-2.142-1.501-4.882-1.862-7.339-0.967L275.24,8.038l-20.776-7.555c-1.769-0.643-3.71-0.643-5.479,0l-20.776,7.555l-20.776-7.555 c-1.769-0.643-3.71-0.643-5.479,0l-20.776,7.555l-20.776-7.555c-1.769-0.643-3.71-0.643-5.479,0l-20.776,7.555L113.37,0.483 c-2.456-0.894-5.196-0.533-7.339,0.967c-2.142,1.501-3.417,3.952-3.417,6.566v60.392h-0.534 c-23.281,0-42.221,18.941-42.221,42.221v359.148c0,23.281,18.941,42.221,42.221,42.221h205.228 c20.54,0,37.691-14.745,41.448-34.205h86.819c9.136,0,16.568-7.432,16.568-16.568v-222.33 C452.142,229.762,444.71,222.33,435.574,222.33z M118.647,19.463l12.759,4.639c1.769,0.643,3.71,0.643,5.479,0l20.776-7.555 l20.776,7.555c1.769,0.643,3.71,0.643,5.479,0l20.776-7.555l20.776,7.555c1.769,0.643,3.71,0.643,5.479,0l20.776-7.555 l20.776,7.555c1.769,0.643,3.71,0.643,5.479,0l12.759-4.639v108.805H118.647V19.463z M333.495,469.779 c0,14.44-11.748,26.188-26.188,26.188H102.079c-14.44,0-26.188-11.748-26.188-26.188V110.631c0-14.44,11.748-26.188,26.188-26.188 h0.534v43.825h-0.534c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h205.228c4.427,0,8.017-3.589,8.017-8.017 s-3.589-8.017-8.017-8.017h-0.534V84.443h0.534c14.44,0,26.188,11.748,26.188,26.188V469.779z M367.699,461.762h-18.171V238.364 h18.171V461.762z M436.109,461.228c0,0.294-0.239,0.534-0.534,0.534h-17.637V298.755c0-4.428-3.589-8.017-8.017-8.017 c-4.427,0-8.017,3.588-8.017,8.017v163.007h-18.171V238.364h18.171v26.185c0,4.427,3.589,8.017,8.017,8.017 c4.427,0,8.017-3.589,8.017-8.017v-26.185h17.637c0.295,0,0.534,0.24,0.534,0.534V461.228z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M208.969,290.739h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C234.088,302.008,222.82,290.739,208.969,290.739z M208.969,324.944h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C218.054,320.868,213.979,324.944,208.969,324.944z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M208.969,359.148h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C234.088,370.417,222.82,359.148,208.969,359.148z M208.969,393.353h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C218.054,389.277,213.979,393.353,208.969,393.353z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M208.969,427.558h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C234.088,438.826,222.82,427.558,208.969,427.558z M208.969,461.762h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C218.054,457.687,213.979,461.762,208.969,461.762z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M285.929,290.739h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C311.048,302.008,299.78,290.739,285.929,290.739z M285.929,324.944h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C295.015,320.868,290.939,324.944,285.929,324.944z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M285.929,359.148h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C311.048,370.417,299.78,359.148,285.929,359.148z M285.929,393.353h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C295.015,389.277,290.939,393.353,285.929,393.353z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M285.929,427.558h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C311.048,438.826,299.78,427.558,285.929,427.558z M285.929,461.762h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C295.015,457.687,290.939,461.762,285.929,461.762z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M132.008,290.739h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C157.127,302.008,145.859,290.739,132.008,290.739z M132.008,324.944h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C141.094,320.868,137.018,324.944,132.008,324.944z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M132.008,359.148h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C157.127,370.417,145.859,359.148,132.008,359.148z M132.008,393.353h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C141.094,389.277,137.018,393.353,132.008,393.353z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M132.008,427.558h-8.551c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119h8.551 c13.851,0,25.119-11.268,25.119-25.119C157.127,438.826,145.859,427.558,132.008,427.558z M132.008,461.762h-8.551 c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086h8.551c5.01,0,9.086,4.076,9.086,9.086 C141.094,457.687,137.018,461.762,132.008,461.762z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M298.756,162.472H110.631c-9.136,0-16.568,7.432-16.568,16.568V256c0,9.136,7.432,16.568,16.568,16.568h188.125 c9.136,0,16.568-7.432,16.568-16.568v-76.96C315.324,169.904,307.892,162.472,298.756,162.472z M299.29,256 c0,0.294-0.239,0.534-0.534,0.534H110.631c-0.295,0-0.534-0.24-0.534-0.534v-76.96c0-0.294,0.239-0.534,0.534-0.534h188.125 c0.295,0,0.534,0.24,0.534,0.534V256z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PCMPayment;
