import React from "react";

import "../CategoryNav.css";
import { useSelector } from "react-redux";

const CategoryImage = (props) => {
  const image = useSelector((state) => state.shop?.homepage?.bannerImage);
  let imgSrc = image !== "" ? image : "../../../media/CategoryImages/default.jpg";
  // let imgSrc =
  //   props.image !== null && props.image !== "default.jpg"
  //     ? props.image
  //     : "../../../media/CategoryImages/default.jpg";
  return <div style={{ backgroundImage: `url(${imgSrc})` }} className="CategoryImage"></div>;
};

export default CategoryImage;
