import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const IntervalSelector = (props) => {
  const { intervals, setChosenInterval, chosenInterval } = props;

  const { t } = useTranslation();
  const [interval, setInterval] = useState("");
  const [focused, setFocused] = useState(false);
  const currServerTime = useSelector((state) => state.shop.serverTime);

  const handleChange = (event) => {
    setInterval(event.target.value);
    setChosenInterval(event.target.value);
  };
  const handleFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };
  return (
    <FormControl
      sx={{
        border: `1px solid ${focused ? "transparent" : chosenInterval === null && "red"} `,
        borderRadius: "6px",
      }}
      fullWidth
      size="small"
    >
      <InputLabel id="demo-simple-select-label">{t("Pre Order")}</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={interval}
        label={t("Pre Order")}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        onChange={handleChange}
        color="secondary"
      >
        {intervals
          ?.filter((el) => el > currServerTime)
          ?.map((sInterval, index) => {
            return <MenuItem value={sInterval}>{sInterval}</MenuItem>;
          })}
      </Select>
    </FormControl>
  );
};

export default IntervalSelector;
