import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import { useTranslation } from "react-i18next";

import "./DataPolicy-Terms.css";

import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Slide,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { termsDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Terms = () => {
  const { screenWidth } = useWindowDimensions();

  const termsVisible = useSelector((state) => state.shop.termsVisible);
  const shopData = useSelector((state) => state.shop.shopData);
  
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const [open, setOpen] = useState(termsVisible);
  
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    if (termsVisible) {
      axios.get(`${import.meta.env.VITE_API_URL}/common/terms`).then((res) => {
        setText(res.data.termsCondition);
        setLoading(false);
      });
    }
  }, [termsVisible]);
  const handleClose = () => {
    setOpen(false);
    dispatch(termsDialogToggle(false));
  };

  return (
    <div>
      <Dialog
        fullScreen={screenWidth < 660 ? true : false}
        //
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className="DialogTitle">
          <Typography variant="h6">{t("topBar.termsOfUsage")}</Typography>
          <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="DialogContent">
          {loading && <LinearProgress />}
          <div
            className="shopDataContainer"
            // style={{ backgroundColor: "#eee" }}
          >
            <div>
              <h3>1. Geltungsbereich</h3>
              <p>
                Diese Allgemeinen Geschäftsbedingungen (AGB) regeln das Vertragsverhältnis zwischen
              </p>
              <p>
                {shopData.name}
                <br />
                {shopData.street},
                <br />
                {shopData.postalCode} {shopData.city}
                <br />
                E-Mail: {shopData.email}
              </p>
              <br />

              <div className="additional-info" dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Terms;
