import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Menu.css";
import AreaSelection from "../UI/Dialogues/AreaSelection";
import CategoryNav from "../CategoryNav/CategoryNav";
import ItemTable from "../ItemTable/ItemTable";
import { Box, Fab } from "@mui/material";
import { FoodBank, Restaurant } from "@mui/icons-material";
import { setIsCategorySideBar } from "../../ToolKit/Slices/MenuSlice";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../UI/ScreenSize/ScreenSize";

const Menu = () => {
  const dispatch = useDispatch();
  const { screenWidth } = useWindowDimensions();
  const selectedArea = useSelector((state) => state.order.selectedArea);
  const orderType = useSelector((state) => state.cart.orderType);
  const shopCurrOpen = useSelector((state) => state.shop.shopCurrOpen);
  const offerMessage = useSelector((state) => state.shop.homepage?.shopMessage?.message);
  const offerClosed = useSelector((state) => state.user.offerClosed);
  const isCategorySideBar = useSelector((state) => state.menu.isCategorySideBar);

  const [showFullFab, setShowFullFab] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      setShowFullFab(true);
    }, 4000);
    setTimeout(() => {
      setShowFullFab(false);
    }, 7000);
  }, []);

  return (
    <div
      className="Menu"
      style={{
        marginTop:
          offerMessage === "" || offerMessage === undefined
            ? "66px"
            : offerClosed
            ? "66px"
            : "100px",
        // offerMessage === "" || offerMessage === undefined
        // ? "41px"
        // : offerClosed
        // ? "41px"
        // : "61px",
      }}
    >
      {/* {orderType === '' ? <AreaSelection /> : null} */}

      {shopCurrOpen ? (
        orderType === "pickup" ? null : !selectedArea?.id || !orderType ? (
          <AreaSelection />
        ) : null
      ) : null}

      <CategoryNav />
      {!isCategorySideBar && (
        <Box className="flex justify-start p-2 fixed z-[10] left-[-15px] ">
          <Fab
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            className="flex gap-3 transition-all"
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            // onTouchStart={() => screenWidth < 768 && setShowFullFab(true)}
            // onTouchEnd={() => screenWidth < 768 && setShowFullFab(false)}
            onMouseOver={() => screenWidth > 768 && setShowFullFab(true)}
            onMouseLeave={() => screenWidth > 768 && setShowFullFab(false)}
            onClick={() => {
              setShowFullFab(false);
              dispatch(setIsCategorySideBar(true));
            }}
          >
            {showFullFab && <p>{t("Speisekarte")}</p>}
            <Restaurant size="large" />
          </Fab>
        </Box>
      )}

      <ItemTable />
    </div>
  );
};

export default Menu;
