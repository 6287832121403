import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Toppings from "../Toppings/Toppings";


const ItemCustomizationNew = (props) => {

  // const dispatch = useDispatch();
  const minLimits = useSelector((state) => state.topping.minLimits);
  const hasSize = useSelector((state) => state.item.hasSize);

  
  return (
    // style={{marginBottom: "80px"}}
    <div >
      <Toppings parentRef={props.parentRef} hasSize={hasSize} required={minLimits} />
    </div>
  );
};

export default ItemCustomizationNew;
