import React from "react";
import io from "socket.io-client";

// export const socket = io(import.meta.env.VITE_SUPER_API_URL);
export const superSocket = io(import.meta.env.VITE_SUPER_API_URL);

window.addEventListener("beforeunload", (event) => {
  // Emit a socket event to notify the server
  console.log("before unload fired");
  superSocket.emit("clientLogout", superSocket.id);

  // Optional: Add a confirmation dialog
  event.returnValue = ""; // This will trigger a confirmation dialog in some browsers
});
window.addEventListener("unload", (event) => {
  // Emit a socket event to notify the server
  superSocket.emit("clientLogout", superSocket.id);
});
export const SocketContext = React.createContext();
