import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { InputAdornment, TextField, Typography } from "@mui/material";
import { Done } from "@mui/icons-material";
import "./Voucher.css";

import { useTranslation } from "react-i18next";
import { checkVoucher } from "../../../ToolKit/Slices/ShopSlice";

const Voucher = (props) => {
  const dispatch = useDispatch();
  const currVoucher = useSelector((state) => state.shop.currVoucher);
  const voucherDisc = useSelector((state) => state.cart.voucherDisc);

  const [voucherCode, setVoucherCode] = useState("");
  const { t } = useTranslation();

  return (
    <div className="VoucherContainer" style={{ textAlign: "right" }}>
      {currVoucher ? (
        <span>
          {currVoucher.code} -{voucherDisc}
          {currVoucher.discountType === "amount" ? "€" : "%"} {t("cart.discount")}
        </span>
      ) : (
        <TextField
          id="field-not-ok"
          variant="outlined"
          label={t("cart.voucherCode")}
          rows={1}
          size="small"
          onChange={(e) => setVoucherCode(e.target.value)}
          InputProps={{
            // autocomplete:'off',
            // form: {
            //   autocomplete: 'off',
            // },
            endAdornment: voucherCode.length > 0 && (
              <InputAdornment position="end">
                <Typography
                  color={voucherCode ? "primary" : "disabled"}
                  className="NoteIcons"
                  onClick={() => {
                    dispatch(checkVoucher({ voucher: voucherCode }));
                  }}
                >
                  Apply
                </Typography>
                {/* <Done
                  disabled={true}
                  color={voucherCode ? "primary" : "disabled"}
                  className="NoteIcons"
                  onClick={() => dispatch(checkVoucher({ voucher: voucherCode }))}
                /> */}
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

export default Voucher;
