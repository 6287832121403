import { createSlice } from "@reduxjs/toolkit";

// Intilizing State
const initialState = {
  customizeBtnClicked: false,
  hasSize: false,
  currItem: {},
  currItemToppingCats: [],
  selectedSizeId: null,
};

// Creating ItemSlice
export const ItemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setSelectedSizeId(state, action) {
      state.selectedSizeId = action.payload;
    },
    resetCustomize(state) {
      return (state = initialState);
    },
    setCurrItemToppingCats(state, action) {
      return {
        ...state,
        currItemToppingCats: action.payload,
      };
    },
    customizeItem(state, action) {
      if (state.currItem.id) {
        if (
          action.payload.id === state.currItem.id &&
          action.payload.size === state.currItem.size
        ) {
          return {
            ...state,
            customizeBtnClicked: false,
          };
        } else {
          return {
            ...state,
            customizeBtnClicked: true,
            currItem: action.payload,
            hasSize: action.payload.itemHasSize,
          };
        }
      } else {
        return {
          ...state,
          customizeBtnClicked: !state.customizeBtnClicked,
          currItem: action.payload,
          hasSize: action.payload.itemHasSize,
        };
      }
    },
    updateSizePrice(state, action) {
      return {
        ...state,
        currItem: {
          ...state.currItem,
          size: action.payload.itemSize,
          price: action.payload.itemPrice,
        },
      };
    },
  },
});

// Exporting Slice(reducer) Actions
export const {
  updateSizePrice,
  customizeItem,
  resetCustomize,
  setCurrItemToppingCats,
  setSelectedSizeId,
} = ItemSlice.actions;
