import React from "react";

const OnlinePayment = (props) => {
  const { height, width, color } = props;
  return (
    <div>
      <svg
        fill={color}
        height={height}
        width={width}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 511 511"
        xmlSpace="preserve"
        stroke="#000000"
        stroke-width="5.11"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <g>
            <path d="M471.5,52h-432C17.72,52,0,69.72,0,91.5v256C0,369.28,17.72,387,39.5,387h176.003c4.142,0,7.5-3.358,7.5-7.5 s-3.358-7.5-7.5-7.5H39.5C25.991,372,15,361.009,15,347.5v-256C15,77.991,25.991,67,39.5,67h432c13.509,0,24.5,10.991,24.5,24.5 v256c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-256C511,69.72,493.28,52,471.5,52z" />{" "}
            <path d="M63.5,331h152c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-152c-4.142,0-7.5,3.358-7.5,7.5S59.358,331,63.5,331z" />{" "}
            <path d="M151,179.5v-32c0-12.958-10.542-23.5-23.5-23.5h-48C66.542,124,56,134.542,56,147.5v32c0,12.958,10.542,23.5,23.5,23.5h48 C140.458,203,151,192.458,151,179.5z M71,179.5V171h8.5c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H71v-8.5 c0-4.687,3.813-8.5,8.5-8.5H96v49H79.5C74.813,188,71,184.187,71,179.5z M127.5,188H111v-49h16.5c4.687,0,8.5,3.813,8.5,8.5v8.5 h-8.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8.5v8.5C136,184.187,132.187,188,127.5,188z" />{" "}
            <path d="M56,275.5c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V275.5z" />{" "}
            <path d="M80,259.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16c0-4.142-3.358-7.5-7.5-7.5S80,255.358,80,259.5z" />{" "}
            <path d="M104,259.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16c0-4.142-3.358-7.5-7.5-7.5S104,255.358,104,259.5z" />{" "}
            <path d="M128,259.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-16c0-4.142-3.358-7.5-7.5-7.5S128,255.358,128,259.5z" />{" "}
            <path d="M175,275.5v-16c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16c0,4.142,3.358,7.5,7.5,7.5S175,279.642,175,275.5z" />{" "}
            <path d="M199,275.5v-16c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16c0,4.142,3.358,7.5,7.5,7.5S199,279.642,199,275.5z" />{" "}
            <path d="M223,259.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V259.5z" />{" "}
            <path d="M455,271.172V243.5c0-52.659-42.841-95.5-95.5-95.5S264,190.841,264,243.5v27.672c-14.097,6.038-24,20.047-24,36.328v112 c0,21.78,17.72,39.5,39.5,39.5h160c21.78,0,39.5-17.72,39.5-39.5v-112C479,291.219,469.097,277.21,455,271.172z M279,243.5 c0-44.388,36.112-80.5,80.5-80.5s80.5,36.112,80.5,80.5v24.513c-0.167-0.002-0.332-0.013-0.5-0.013H423v-24.5 c0-35.014-28.486-63.5-63.5-63.5S296,208.486,296,243.5V268h-16.5c-0.168,0-0.333,0.011-0.5,0.013V243.5z M311,268v-24.5 c0-26.743,21.757-48.5,48.5-48.5s48.5,21.757,48.5,48.5V268H311z M464,419.5c0,13.509-10.991,24.5-24.5,24.5h-160 c-13.509,0-24.5-10.991-24.5-24.5v-112c0-13.509,10.991-24.5,24.5-24.5h160c13.509,0,24.5,10.991,24.5,24.5V419.5z" />{" "}
            <path d="M379.768,323.386c-7.135-6.001-16.562-8.517-25.861-6.9c-12.737,2.214-23.19,12.684-25.422,25.461 c-1.677,9.601,1.116,19.234,7.515,26.406V387.5c0,12.958,10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5v-19.151 c5.119-5.751,8-13.177,8-20.849C391,338.179,386.906,329.39,379.768,323.386z M370.48,359.699c-1.579,1.422-2.48,3.447-2.48,5.572 V387.5c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5v-22.229c0-2.125-0.901-4.15-2.48-5.573 c-4.342-3.912-6.259-9.441-5.258-15.171c1.145-6.553,6.702-12.131,13.215-13.263c1.023-0.178,2.04-0.266,3.042-0.266 c3.896,0,7.577,1.328,10.594,3.866c3.742,3.147,5.888,7.753,5.888,12.635C376,352.16,374.04,356.492,370.48,359.699z" />{" "}
          </g>{" "}
        </g>
      </svg>
    </div>
  );
};

export default OnlinePayment;
