import React, { useState, useEffect, useMemo, memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import "./Topping.css";
import { CameraAltOutlined, Cancel, Restaurant, RestaurantMenu } from "@mui/icons-material";
import { addToppings, removeTopping } from "../../../../ToolKit/Slices/ToppingSlice";
import AllergyInfo from "../../../ItemTable/Item/AllergyInfo";
import { Avatar, Badge, Card, Icon, Paper, useTheme } from "@mui/material";

const Topping = (props) => {
  // states
  const dispatch = useDispatch();
  const theme = useTheme();

  const item = useSelector((state) => state.item.currItem);
  const limit = useSelector((state) => state.topping.limitCounter);
  const allergies = useSelector((state) => state.menu.allergies);
  const selectedToppings = useSelector((state) => state.topping.currToppings.toppings);
  const [counter, setCounter] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);
  const priceFormat = window.$priceFormater;
  const { tId, name, tCatId } = props.topping;

  const { tIndex, tCat, topping, price, isMultiple } = props;

  const catLimits = limit.filter((lim) => lim.tCatId === tCat.tCatId);
  const catLimit = catLimits[0];

  useEffect(() => {
    setCounter(0);
  }, [item.size]);

  const tPrice = price;

  let firstElementClass = "";

  if (tIndex === 0) {
    firstElementClass = "first-topping-element";
  }

  let itemAllergies = [];

  if (topping.allergies) {
    topping.allergies.forEach((a) => {
      itemAllergies.push(allergies.find((al) => al.id === a));
    });
  }

  const handleToppingDelete = () => {
    dispatch(
      removeTopping({
        id: tId,
        tCatId: tCatId,
        count: props.toppingCount,
      })
    );
  };

  const toppingImage = useMemo(
    () =>
      topping.image ? (
        <Avatar
          variant="square"
          sx={{
            width: "50px",
            height: "50px",
          }}
          src={
            // toppingImage
            topping.image
            // : `https://orionid-images.s3.eu-central-1.amazonaws.com/common/toppings/${name}.jpg`
          }
        />
      ) : (
        <Avatar
          variant="square"
          sx={{
            width: "50px",
            height: "50px",
            // backgroundColor: theme.palette.background.default,
          }}
        >
          <Restaurant className="text-[50px]" />
        </Avatar>
      ),
    [props.key]
  );

  const onClick = (e) => {
    if (catLimit?.max > 0 && isMultiple) {
      e.stopPropagation();
      const toppingData = {
        tId: tId,
        name: name,
        price: tPrice,
        tCatId: tCat.tCatId,
        max: tCat.max,
        free: tCat.free,
      };
      dispatch(addToppings(toppingData));
      setCounter(counter + 1);
      // props.handleAddTopping(toppingData)
      props.setToggle(!props.toggle);
    } else if (!isMultiple) {
      props.handleSingleClick(topping, tIndex);
    }
  };

  const ToppingCardDisplay = (
    <Card
      elevation={4}
      style={{
        width: "100%",
        p: 0,
        backgroundColor: selectedToppings.some((el) => el.tId === tId)
          ? theme.palette.primary.light
          : theme.palette.mode === "light"
          ? "rgb(242, 243, 244, 0.5)"
          : theme.palette.background.default,
        color: selectedToppings.some((el) => el.tId === tId) && "black",
      }}
      className={
        tCat.free > 0 && isMultiple
          ? tCat.free === tCat.free - catLimit.free
            ? `topping-container ${firstElementClass} disabledTopping`
            : `${
                theme.palette.mode === "light" ? "topping-container" : "topping-container-dark"
              } ${firstElementClass}`
          : `${
              theme.palette.mode === "light" ? "topping-container" : "topping-container-dark"
            } ${firstElementClass}`
      }
      onClick={onClick}
      // onClick={
      // catLimit.max > 0
      //   ? (e) => {
      //       e.stopPropagation();
      //       const toppingData = {
      //         tId: tId,
      //         name: name,
      //         price: tPrice,
      //         tCatId: tCat.tCatId,
      //         max: tCat.max,
      //         free: tCat.free,
      //       };
      //       dispatch(addToppings(toppingData));
      //       setCounter(counter + 1);
      //       // props.handleAddTopping(toppingData)
      //       props.setToggle(!props.toggle);
      //     }
      //   : null
      // }
    >
      <div className="flex gap-2 items-center w-full pl">
        {toppingImage}
        <div className="flex justify-between w-full">
          <div className="topping-name flex justify-center items-center gap-2 ">
            <b>{name}</b>
            {/* topping count */}
            {props.toppingCount > 0 ? (
              <b
                className=""
                style={{
                  color: theme.palette.primary.contrastText,
                  fontSize: "16px",
                }}
              >
                x{props.toppingCount}
              </b>
            ) : null}
            {/* topping allergies */}
            {itemAllergies[0] ? (
              <AllergyInfo
                itemAllergies={itemAllergies}
                infoOpen={infoOpen}
                setInfoOpen={setInfoOpen}
                additionalInfo={topping?.additionalInfo}
              />
            ) : null}
          </div>
          {/* topping price */}
          <div className="topping-price pr-2">
            <b>+{priceFormat.format(tPrice)}</b>
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <React.Fragment>
      <Badge
        className="big-class"
        sx={{
          "& .MuiBadge-badge": {
            top: "6px",
            right: "6px",
          },
        }}
        badgeContent={
          <>
            {props.isSelected && isMultiple ? (
              <Icon
                className="cursor-pointer "
                sx={{
                  height: "1.2em",
                }}
                color="error"
                onClick={handleToppingDelete}
              >
                <Cancel
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
              </Icon>
            ) : (
              <>
                {!isMultiple && props.isSelected === topping.tId && (
                  <Icon
                    className="cursor-pointer "
                    sx={{
                      height: "1.2em",
                    }}
                    color="error"
                    onClick={onClick}
                  >
                    <Cancel
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </Icon>
                )}
              </>
            )}
          </>
        }
      >
        {ToppingCardDisplay}
      </Badge>
    </React.Fragment>
  );
};

export default Topping;
