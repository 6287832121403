import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import axios from "axios";

import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  deviceType,
} from "react-device-detect";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import { useState } from "react";
import { setUserDevice } from "../../../ToolKit/Slices/UserSlice";

function Device() {
  const { screenWidth, screenHeight } = useWindowDimensions();
  const dispatch = useDispatch();

  const [deviceInfo, setDeviceInfo] = useState({
    OS: osName + " " + osVersion,
    Browser: browserName + " " + fullBrowserVersion,
    mobile: mobileVendor + " " + mobileModel,
    deviceType: deviceType,
    viewport: screenWidth + "x" + screenHeight,
    client: browserName,
    info: "",
  });

  useEffect(() => {
    // axios
    // .get("https://www.cloudflare.com/cdn-cgi/trace")

    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => {
        setDeviceInfo({ ...deviceInfo, info: response.data });
        dispatch(setUserDevice({ ...deviceInfo, info: response.data }));
      })
      .catch((err) => {
        dispatch(setUserDevice(deviceInfo));
      });
    return () => {};
  }, []);

  return <div></div>;
}

export default Device;
