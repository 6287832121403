import React, { useEffect, useState } from "react";

import InputForm from "../../UI/Forms/InputForm/InputForm";
import "./TimingsDialog.css";
import { useDispatch, useSelector } from "react-redux";
import { setOrderTime } from "../../../ToolKit/Slices/OrderSlice";

const DelayedOrderF = (props) => {
  const dispatch = useDispatch();
  // redux state
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const delayedOrderIntervals = useSelector((state) => state.shop.delayedTimeIntervals);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);

  // local states
  const [delayForm, setDelayForm] = useState({
    deliveryTime: {
      elementType: "select",
      elementName: "deliveryTime",
      elementConfig: {
        placeholder: "Order Time?",
        default: "Choose Time",
      },
      value: "Choose Time",
      validation: {},
      valid: true,
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [timeIntervals, setTimeIntervels] = useState([]);

  const inputChangedHandler = (event) => {
    dispatch(setOrderTime({ time: event.target.value, delayed: true }));
  };

  const formElementsArray = [];
  for (let key in delayForm) {
    formElementsArray.push({
      id: key,
      config: delayForm[key],
    });
  }

  let form = (
    <form className="DelayedForm">
      {formElementsArray.map((formElement) => (
        <InputForm
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          timeIntervals={delayedOrderIntervals?.filter(
            (f) => f > Number(isShopCurrentlyOpen?.time[0])
          )}
          
          touched={formElement.config.touched}
          elName={formElement.config.elementName}
          changed={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
    </form>
  );

  return <div style={{ maxWidth: 325 }}>{form}</div>;
};

export default DelayedOrderF;
