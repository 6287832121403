import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, enqueueSnackbar } from "notistack";

import {
  Done,
  InfoSharp,
  ClearAll,
  LocalMall,
  RedoRounded,
  ShoppingCartRounded,
  ThreeSixtyRounded,
  ThreeSixty,
  History,
  ReplayRounded,
} from "@mui/icons-material";
import {
  Paper,
  Button,
  ButtonGroup,
  Box,
  Typography,
  useTheme,
  IconButton,
  Icon,
} from "@mui/material";

import useWindowDimensions from "../UI/ScreenSize/ScreenSize";
import CartItem from "./CartItem/CartItem";
import PlaceOrder from "../Orders/PlaceOrder/PlaceOrder";
import TotalCalculator from "./TotalCalculator/TotalCalculator";
import Voucher from "./Voucher/Voucher";
import PulseLoader from "react-spinners/PulseLoader";
import UserData from "../Users/UserProfile/UserData";
import "./Cart.css";
import { useTranslation } from "react-i18next";

import { FaClock, FaShippingFast } from "react-icons/fa";
import { FiShoppingBag } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import styled, { keyframes } from "styled-components";
import { slideInUp } from "react-animations";
import {
  addToCart,
  clearCart,
  increaseQuantity,
  resetCart,
  setOrderType,
} from "../../ToolKit/Slices/CartSlice";
import {
  openEighteenPlusItemDialogOpen,
  ordersContainerToggle,
  removePError,
  selectAreaToggle,
  setCartCheckout,
  setEighteenPlusItem,
  setIsOfferTimeItemSelected,
  setOrderTime,
} from "../../ToolKit/Slices/OrderSlice";
import { resetVoucher, updatePickupDeliveryTime } from "../../ToolKit/Slices/ShopSlice";
import ShowTime from "../Shop/ShopData/TimingsTable/ShowTime";
import { checkTimeLies, setServerTimeLocal } from "../../ToolKit/Slices/utils";
// import { socket } from "../../App";
const slideAnimation = keyframes`${slideInUp}`;

const SlideDiv = styled.div`
  animation: 0.3s ${slideAnimation};
`;

const Cart = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const categories = useSelector((state) => state.menu.categories);
  const isOfferTimeItemSelected = useSelector((state) => state.order.isOfferTimeItemSelected);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartCount = useSelector((state) => state.cart.cartItemCount);
  const areas = useSelector((state) => state.shop.deliveryAreas);
  const subTotal = useSelector((state) => state.cart.subTotal);
  const actualTotal = useSelector((state) => state.cart.actualTotal);
  const grandTotal = useSelector((state) => state.cart.grandTotal);
  const selectedArea = useSelector((state) => state.order.selectedArea);
  const orderType = useSelector((state) => state.cart.orderType);
  const voucherLoading = useSelector((state) => state.shop.voucherLoading);
  const discounts = useSelector((state) => state.shop.settings?.discounts);
  const voucherDisc = useSelector((state) => state.cart.voucherDisc);
  const afterVoucherDisc = useSelector((state) => state.cart.afterVoucherDisc);
  const currUser = useSelector((state) => state.user.currUser);
  const deliveryON = useSelector((state) => state.shop.deliveryON);
  const pickupON = useSelector((state) => state.shop.pickupON);
  const settings = useSelector((state) => state.shop.settings);
  const defaultDeliveryTime = settings?.livePanelSettings?.defaultDeliveryTime;
  const defaultPickupTime = settings?.livePanelSettings?.defaultPickupTime;
  const offerMessage = useSelector((state) => state.shop.homepage.offerMessage);
  const offerClosed = useSelector((state) => state.user.offerClosed);
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const separateTimings = useSelector((state) => state.shop.settings?.separateTimings);
  const cartCheckout = useSelector((state) => state.order.cartCheckout);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const addresses = useSelector((state) => state.user.addresses);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);

  // console.log("cartCheckout cart.js", cartCheckout);
  // cartCheckout

  const { screenWidth } = useWindowDimensions();
  // const [checkOut, setCheckOut] = useState(false);

  const [isTop, setIsTop] = useState(true);
  const [top, setTop] = useState(0);

  const [isItemWithOfferCategoryPresent, setIsItemWithOfferCategoryPresent] = useState("");

  const priceFormat = window.$priceFormater;

  useEffect(() => {
    // console.log("categories", categories);
    const catWithOfferTime = categories?.find((category) => {
      return cartItems.find((f) => f.catId === category.id && category.type === "offer");
    });

    // console.log("catWithOfferTime", catWithOfferTime);
    // console.log("isOfferTimeItemSelected", isOfferTimeItemSelected);
    setIsItemWithOfferCategoryPresent(catWithOfferTime);

    if (isOfferTimeItemSelected && !catWithOfferTime) {
      dispatch(setIsOfferTimeItemSelected(false));
      if (isShopCurrentlyOpen?.status === true) {
        dispatch(setOrderTime({ time: "", delayed: false }));
      }
    }
  }, [cartItems]);

  // useEffect(() => {
  //   socket.on("pickup_delivery_time_change", (times) => {
  //     console.log("times", times);
  //     if (defaultPickupTime !== times.pickupTime) {
  //       showSnack(` ${t("Pickup time changed to")} ${times.pickupTime}`, "info", true);
  //     }

  //     if (defaultDeliveryTime !== times.deliveryTime) {
  //       showSnack(` ${t("Delivery time changed to")} ${times.deliveryTime}`, "info", true);
  //     }

  //     dispatch(updatePickupDeliveryTime(times));
  //   });
  // }, [socket]);

  const { t } = useTranslation();

  // if (isMobile) {
  //   window.onbeforeunload = function () {
  //     return t("warning");
  //   };
  // }

  useEffect(() => {
    if (screenWidth > 1000) {
      let tempHeight = document.getElementById("mainHeader")?.offsetHeight;
      // tempHeight += 66;
      setTop(tempHeight);
    } else {
      setTop(0);
    }
  }, [screenWidth, offerClosed]);

  // useEffect(() => {
  //   let isCancelled = false;
  //   if (screenWidth > 1000 && !isCancelled) {
  //     document.addEventListener("scroll", () => {
  //       const scrollCheck = window.scrollY < 50;
  //       if (scrollCheck !== isTop) {
  //         setIsTop(scrollCheck);
  //       }
  //     });
  //   }
  //   return () => {
  //     isCancelled = true;
  //   };
  // }, [window.scrollY]);

  let remainingAmount = selectedArea?.minOrderAmount;

  if (grandTotal !== 0) {
    remainingAmount = voucherDisc
      ? selectedArea?.minOrderAmount - afterVoucherDisc
      : selectedArea?.minOrderAmount - subTotal;
  }

  const showActionSnack = () => {
    const action = (key) => (
      <React.Fragment>
        <Button
          variant="outlined"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t("notify.no")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(clearCart());
            closeSnackbar(key);
            enqueueSnackbar(t("notify.allItemsRemoved"), {
              variant: "success",
              persist: false,
              action: null,
            });
          }}
          style={{ margin: "5px" }}
        >
          {t("notify.yes")}
        </Button>
      </React.Fragment>
    );
    let message = t("notify.sureToDeleteAll");
    enqueueSnackbar(message, {
      variant: "warning",
      persist: true,
      action,
    });
  };

  const showSnack = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };
  // const currToppings = useSelector((state) => state.topping.currToppings);
  const calculatePrice = (currItem, currToppings, orderType) => {
    let toppingAmount = 0;
    currToppings.forEach((el) => {
      toppingAmount += el.price;
    });
    let orignalPrice = currItem.price + toppingAmount;
    let discPrice = null;

    if (!currItem.isDiscount) {
      if (discounts?.deliveryDiscountActive && orderType === "delivery" && currItem.deliveryDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts?.deliveryDiscount / 100);
      } else if (discounts?.pickupDiscountActive && orderType === "pickup" && currItem.pickupDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts?.pickupDiscount / 100);
      } else {
        discPrice = null;
      }
    } else {
      if (orderType === "delivery") {
        if (currItem.discounts.deliveryType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.delivery;
        } else {
          discPrice = orignalPrice - currItem.discounts.delivery;
        }
      } else {
        if (currItem.discounts.pickupType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.pickup;
        } else {
          discPrice = orignalPrice - currItem.discounts.pickup;
        }
      }
    }

    return { discPrice, toppingAmount };
  };

  const changeOrderType = (type, deliveryCharges) => {
    dispatch(setOrderType({ orderTypeLocal: type, deliveryCharges: deliveryCharges }));
    if (cartCount > 0) {
      let cart = cartItems;
      dispatch(resetCart(true));
      dispatch(resetVoucher());
      cart.forEach((el) => {
        if (el.toppings?.length > 0) {
          let obj = calculatePrice(el, el.toppings, type);
          dispatch(
            addToCart({
              item: { ...el, cartId: el.cartId },
              toppings: {
                toppings: el.toppings,
                toppingAmount: obj.toppingAmount,
              },
              discPrice: obj.discPrice,
            })
          );

          for (let i = 1; i < el.quantity; i++) {
            dispatch(
              increaseQuantity({
                id: el.cartId,
                discount: obj.discPrice ? obj.discPrice : el.price + obj.toppingAmount,
                price: el.price + obj.toppingAmount,
              })
            );
          }
        } else {
          let obj = calculatePrice(el, [], type);
          dispatch(
            addToCart({
              item: { ...el, cartId: el.cartId, quantity: 1 },
              toppings: null,
              discPrice: obj.discPrice,
            })
          );
          for (let i = 1; i < el.quantity; i++) {
            dispatch(
              increaseQuantity({
                id: el.cartId,
                discount: obj.discPrice ? obj.discPrice : el.price + obj.toppingAmount,
                price: el.price + obj.toppingAmount,
              })
            );
          }
        }
      });
    }
  };

  const handleProceedToCheckout = () => {
    let isDeliveryAvlblOnPlacingOrder = null;
    let isPickupAvlblOnPlacingOrder = null;

    const currentServerTime = setServerTimeLocal(currTimeLocal);

    // separateTimings check
    if (separateTimings && orderType === "delivery" && !todayTiming?.holiday) {
      isDeliveryAvlblOnPlacingOrder = checkTimeLies(
        todayTiming?.deliveryTimings,
        currentServerTime
      );
    } else if (separateTimings && orderType === "pickup" && !todayTiming?.holiday) {
      isPickupAvlblOnPlacingOrder = checkTimeLies(todayTiming?.pickupTimings, currentServerTime);
    }
    if (
      separateTimings &&
      orderType === "delivery" &&
      isDeliveryAvlblOnPlacingOrder?.status === false &&
      isDeliveryAvlblOnPlacingOrder?.message !== "less"
    ) {
      showSnack(t("Delivery is not available at the moment"), "warning", false);
    } else if (
      separateTimings &&
      orderType === "pickup" &&
      isPickupAvlblOnPlacingOrder?.status === false &&
      isPickupAvlblOnPlacingOrder?.message !== "less"
    ) {
      showSnack(t("Pickup is not available at the moment"), "warning", false);
    } else {
      const itemWithEighteenPlusDrink = cartItems.find(
        (cartItem) => cartItem?.drinkInfo && cartItem?.drinkInfo?.minAge > 0
      );

      if (itemWithEighteenPlusDrink && !cartCheckout) {
        dispatch(setEighteenPlusItem(itemWithEighteenPlusDrink));
        dispatch(openEighteenPlusItemDialogOpen(true));
      } else {
        if (cartCheckout === true) {
          dispatch(removePError());
        }
        console.log("cartCheckout", cartCheckout);
        dispatch(setCartCheckout(!cartCheckout));
      }
    }

    // setCheckOut(!checkOut);
    //           // setCheckOut(!checkOut)
  };

  // console.log("areas", areas);
  // console.log("selectedArea", selectedArea);
  // console.log('areas?.filter((el) => el.id === selectedArea.id)', areas?.filter((el) => el.id === selectedArea.id))

  return (
    <Box
      className="Cart "
      sx={{
        top: `${top}px`,
        // offerMessage === "" || offerMessage === undefined
        //   ? "66px"
        //   : offerClosed
        //   ? "66px"
        //   : "103px",
        bgcolor: "background.default",
      }}

      // sx={{ top: isTop ? "unset" : "0", bgcolor: "background.default" }}
    >
      {cartCheckout ? null : (
        <SlideDiv>
          <Paper className="CartContainer" elevation={2}>
            <p style={{ margin: "8px", color: "grey" }}> {t("cart.yourOrder")}</p>
            <div className="CartItems">
              {cartCount > 0 ? (
                cartItems.map((item, index) => (
                  <CartItem
                    key={item.cartId}
                    item={item}
                    // editToggle={editToggle}
                    orderType={orderType}
                  />
                ))
              ) : (
                <div
                  style={{ textAlign: "center" }}
                  className="flex flex-col justify-center items-center gap-1"
                >
                  <p style={{ color: "grey", fontSize: "12px" }}>{t("cart.noItems")}</p>

                  <Box
                    onClick={() => dispatch(ordersContainerToggle(true))}
                    className="flex flex-col cursor-pointer hover:bg-grey-600 icon-pr"
                  >
                    <ReplayRounded style={{ color: "grey", fontSize: 75, p: 0 }} />
                    {/* <History className="" style={{ color: "grey", fontSize: 110, p: 0 }} /> */}

                    <ShoppingCartRounded
                      className="icon-abs"
                      style={{ color: "grey", fontSize: 25, p: 0 }}
                    />
                  </Box>

                  <p style={{ color: "grey", fontSize: "12px" }}>{t("Re Order")}</p>
                </div>
              )}
            </div>
            {cartCount ? (
              <div className=" w-full flex items-center justify-between">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    if (isItemWithOfferCategoryPresent !== "" && isItemWithOfferCategoryPresent) {
                      dispatch(setIsOfferTimeItemSelected(false));
                    }
                    showActionSnack();
                  }}
                >
                  {t("button.clear")}
                  <ClearAll fontSize="small" style={{ color: "ff2300" }} />
                </Button>

                {/* SAVING AMOUNT (in green) */}

                {orderType === "delivery" &&
                  discounts?.deliveryDiscountActive &&
                  actualTotal - subTotal > 0 && (
                    <span className="SavingAmount ">
                      {t("cart.savingAmount")} <b>{priceFormat.format(actualTotal - subTotal)}</b>
                    </span>
                  )}
                {orderType === "pickup" &&
                  discounts?.pickupDiscountActive &&
                  actualTotal - subTotal > 0 && (
                    <span className="SavingAmount ">
                      {t("cart.savingAmount")} <b>{priceFormat.format(actualTotal - subTotal)}</b>
                    </span>
                  )}
                {/* {discounts.active && actualTotal - subTotal > 0 ? (
                  <span className="SavingAmount">
                    {t("cart.savingAmount")} <b>{priceFormat.format(actualTotal - subTotal)}</b>
                  </span>
                ) : null} */}
              </div>
            ) : null}
            <br></br>
            <TotalCalculator />

            {cartCount > 0 ? (
              voucherLoading ? (
                <PulseLoader color={theme.palette.primary.main} />
              ) : (
                <Voucher />
              )
            ) : null}

            <br></br>
            {orderType !== "kiosk" && (
              <Box className="">
                <ButtonGroup
                  sx={{
                    width: "320px",
                    height: "60px",
                  }}
                  // disabled={cartCount > 0 ? true : false}
                  className="OrderTypeBtns"
                  size="small"
                  variant="contained"
                  color="primary"
                  aria-label="contained primary button group"
                  // onClick={
                  //   cartCount > 0
                  //     ? () => showSnack(t("notify.clearItemsToChangeOrder"), "warning")
                  //     : null
                  // }
                >
                  <Button
                    disabled={
                      separateTimings && !todayTiming?.holiday
                        ? !isDeliveryAvailable?.status && isDeliveryAvailable?.message !== "less"
                        : false
                    }
                    fullWidth
                    onClick={
                      deliveryON
                        ? selectedArea?.id
                          ? () => {
                              if (!currUser && orderType === "delivery") {
                                dispatch(selectAreaToggle(true));
                              }
                              changeOrderType("delivery", selectedArea?.deliveryCharges);
                            }
                          : () => {
                              if (!currUser) {
                                dispatch(selectAreaToggle(true));
                              }

                              // changeOrderType("delivery", 0);
                            }
                        : () =>
                            showSnack(
                              t("orderQueryDialog.Delivery not available at this time"),
                              "warning"
                            )
                    }
                    style={deliveryON ? null : { color: "grey" }}
                    variant={orderType === "delivery" ? "contained" : null}
                    startIcon={<FaShippingFast />}
                    sx={{
                      position: "relative",
                      backgroundColor: orderType !== "delivery" && "lightgray",
                      color: orderType !== "delivery" && "grey",
                    }}
                  >
                    {separateTimings &&
                      !isDeliveryAvailable?.status &&
                      isDeliveryAvailable?.message === "less" && (
                        <div
                          style={{
                            background: "yellow",
                          }}
                          className="px-[4px] absolute top-0  right-0 rounded-bl-[5px]  bg-red text-black"
                        >
                          <Typography fontSize={".5rem"} variant="caption">
                            {t("Pre Order")}
                          </Typography>
                        </div>
                      )}
                    {discounts?.deliveryDiscountActive ? (
                      <div className="ribbon ribbon-top-left">
                        <span
                          style={{
                            backgroundColor: orderType !== "delivery" && "lightgray",
                            color: orderType !== "delivery" && "black",
                          }}
                        >
                          {"-" + discounts?.deliveryDiscount + "%"}
                        </span>
                      </div>
                    ) : null}
                    <Box className="flex flex-col">
                      <Box className="flex">
                        <p>{t("button.delivery")}</p>
                        {/* {orderType === "delivery" ? <Done fontSize="small" /> : null} */}
                      </Box>

                      {defaultDeliveryTime !== "manual" && (
                        <Typography variant="caption">{defaultDeliveryTime} mins</Typography>
                      )}
                    </Box>
                  </Button>
                  {/* currVoucher */}
                  {/* pickup button cart */}
                  <Button
                    disabled={
                      separateTimings && !todayTiming?.holiday
                        ? !isPickupAvailable?.status && isPickupAvailable?.message !== "less"
                        : false
                    }
                    fullWidth
                    onClick={
                      pickupON
                        ? () => {
                            changeOrderType("pickup", 0);
                          }
                        : () =>
                            showSnack(
                              t("orderQueryDialog.Pickup not available at this time"),
                              "warning"
                            )
                    }
                    style={pickupON ? null : { color: "grey" }}
                    variant={orderType === "pickup" ? "contained" : null}
                    startIcon={<LocalMall />}
                    sx={{
                      position: "relative",
                      backgroundColor: orderType !== "pickup" && "lightgray",
                      color: orderType !== "pickup" && "grey",
                    }}
                  >
                    {separateTimings &&
                      !isPickupAvailable?.status &&
                      isPickupAvailable?.message === "less" && (
                        <div
                          style={{
                            background: "yellow",
                            paddingY: "0px",
                          }}
                          className="px-[4px] absolute top-0  left-0 rounded-br-[5px]  bg-red text-black"
                        >
                          <Typography fontSize={".5rem"} variant="caption">
                            {t("Pre Order")}
                          </Typography>
                        </div>
                      )}

                    {discounts?.pickupDiscountActive ? (
                      <div className="ribbon-2 ribbon-top-right">
                        <span
                          style={{
                            backgroundColor: orderType !== "pickup" && "lightgray",
                            color: orderType !== "pickup" && "black",
                          }}
                        >
                          {"-" + discounts?.pickupDiscount + "%"}
                        </span>
                      </div>
                    ) : null}
                    <Box className="flex flex-col">
                      <Box className="flex">
                        <p>{t("button.pickup")}</p>
                        {/* <ShowTime timings={isPickupAvailable?.time} /> */}

                        {/* {orderType === "pickup" ? <Done fontSize="small" /> : null} */}
                      </Box>
                      {defaultPickupTime !== "manual" && (
                        <Typography variant="caption">{defaultPickupTime} mins</Typography>
                      )}
                    </Box>
                  </Button>
                </ButtonGroup>

                {settings?.separateTimings === true && !todayTiming?.holiday && (
                  <Box
                    sx={{
                      mb: 0,
                      pt: 0.5,
                    }}
                    className="CartContainer flex items-center justify-evenly font-medium text-gray-600 pt-0 mt-0"
                  >
                    <Box
                      sx={{
                        opacity: isDeliveryAvailable?.status === false ? 1 : 0,
                        pointerEvents: isDeliveryAvailable?.status === false && "none",
                      }}
                    >
                      <Typography variant="caption">{t("Available only")}</Typography>
                      <ShowTime
                        timings={todayTiming?.deliveryTimings}
                        styleType={"col"}
                        fontWeight={"400"}
                      />
                    </Box>
                    <Box
                      sx={{
                        opacity: isPickupAvailable?.status === false ? 1 : 0,
                        pointerEvents: isPickupAvailable?.status === false && "none",
                      }}
                    >
                      <Typography variant="caption" fontSize={"italics"}>
                        {t("Available only")}
                      </Typography>
                      <ShowTime
                        timings={todayTiming?.pickupTimings}
                        styleType={"col"}
                        fontWeight={"400"}
                      />
                    </Box>
                  </Box>
                )}
                {orderType === "pickup" && cartCount > 0 ? null : (
                  <div>
                    {selectedArea?.id || orderType === "pickup" ? null : (
                      <div className="AreaDescription">
                        <span>{t("cart.selectAreaToProceed")}</span>
                        <br></br>
                        {currUser ? null : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => dispatch(selectAreaToggle(true))}
                          >
                            {t("button.selectArea")}
                          </Button>
                        )}
                      </div>
                    )}

                    {selectedArea?.minOrderAmount > subTotal ? (
                      selectedArea?.id && orderType === "delivery" ? (
                        <div className="minAmountDesc flex flex-col gap-3">
                          {areas?.filter((el) => el.id === selectedArea?.id).length !== 0 && (
                            <p style={{ fontSize: "14px" }}>
                              <InfoSharp fontSize="small" style={{ color: "red" }} />{" "}
                              {t("cart.minAmount")} {selectedArea?.area}, {selectedArea?.postalCode}{" "}
                              : <b>{priceFormat.format(selectedArea?.minOrderAmount)}</b> <br></br>
                              {subTotal !== 0
                                ? priceFormat.format(remainingAmount) +
                                  " " +
                                  t("cart.moreRequiredAmount")
                                : null}
                            </p>
                          )}
                          {currUser &&
                            areas?.filter((el) => el.id === selectedArea?.id)?.[0]?.active ===
                              false && (
                              <p style={{ fontSize: "14px", color: "red" }}>
                                <InfoSharp fontSize="small" style={{ color: "red" }} />{" "}
                                {`${selectedArea?.area}, ${selectedArea?.postalCode}. ${t(
                                  "Delivery not available at this time"
                                )}`}
                              </p>
                            )}
                          {currUser ? null : (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => dispatch(selectAreaToggle(true))}
                            >
                              {t("button.changeArea")}
                            </Button>
                          )}
                        </div>
                      ) : null
                    ) : (
                      <>
                        {currUser &&
                          areas?.filter((el) => el.id === selectedArea?.id)?.[0]?.active ===
                            false && (
                            <p style={{ fontSize: "14px", color: "red", marginTop: 10 }}>
                              <InfoSharp fontSize="small" style={{ color: "red" }} />{" "}
                              {`${selectedArea?.area}, ${selectedArea?.postalCode}. ${t(
                                "Delivery not available at this time"
                              )}`}
                            </p>
                          )}
                      </>
                    )}
                    {currUser &&
                      orderType === "delivery" &&
                      areas?.filter(
                        (el) =>
                          el.id === addresses.filter((f) => f.selected === true)?.[0]?.userAreaId
                      ).length === 0 && (
                        <p style={{ fontSize: "14px", color: "red" }}>
                          <InfoSharp fontSize="small" style={{ color: "red" }} />{" "}
                          {`${addresses?.filter((f) => f.selected === true)?.[0]?.city}, ${
                            addresses?.filter((f) => f.selected === true)?.[0]?.postalCode
                          }. ${t("Delivery not available at this time")}`}
                        </p>
                      )}
                  </div>
                )}
              </Box>
            )}
          </Paper>
        </SlideDiv>
      )}

      <div
        style={{
          backgroundColor: theme.palette.mode === "dark" && theme.palette.background.default,
        }}
      >
        <div
          className={"CartChkButton"}
          style={{
            width: screenWidth < 1000 ? "100%" : "30%",
            bottom: cartCheckout && screenWidth < 660 ? "unset" : "0",
            top:
              cartCheckout && screenWidth < 660
                ? offerMessage === "" || offerMessage === undefined
                  ? "0px"
                  : offerClosed
                  ? "0px"
                  : "37px"
                : "unset",
            backgroundColor: theme.palette.mode === "dark" && theme.palette.background.default,
          }}
        >
          {orderType === "delivery" ? (
            <div
              className="checkOutBtn"
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? theme.palette.background.default : "white",
              }}
            >
              <SlideDiv>
                <Button
                  onClick={() => {
                    handleProceedToCheckout();
                  }}
                  className="CartButtonCustom"
                  disabled={
                    selectedArea?.minOrderAmount <= subTotal &&
                    areas?.filter((el) => el.id === selectedArea?.id)?.[0]?.active === true
                      ? false
                      : true
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  {cartCheckout
                    ? t("button.backToCart")
                    : t("button.proceedToCheckout") + " " + priceFormat.format(grandTotal)}
                </Button>
              </SlideDiv>
            </div>
          ) : null}
          {orderType === "pickup" || orderType === "kiosk" ? (
            <div
              className="checkOutBtn"
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? theme.palette.background.default : "white",
              }}
            >
              <SlideDiv>
                <Button
                  onClick={() => {
                    handleProceedToCheckout();
                    // setCheckOut(!checkOut)
                  }}
                  className="CartButtonCustom"
                  disabled={cartCount > 0 ? false : true}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  {cartCheckout
                    ? t("button.backToCart")
                    : t("button.proceedToCheckout") + " " + priceFormat.format(grandTotal)}
                </Button>
              </SlideDiv>
            </div>
          ) : null}
        </div>
      </div>
      {currUser && !cartCheckout ? (
        <Paper className="CartUserContainer" elevation={2}>
          <p style={{ color: "grey", textAlign: "center" }}>{t("cart.checkYourDetails")}</p>
          <UserData isCart={true} />
        </Paper>
      ) : null}
      {cartCheckout ? (
        <div className="CartContainer">
          {orderType === "pickup" || orderType === "kiosk" ? (
            <SlideDiv>
              <PlaceOrder grandTotal={grandTotal} actualTotal={actualTotal} subTotal={subTotal} />
            </SlideDiv>
          ) : selectedArea?.minOrderAmount <= subTotal ? (
            <div>
              <SlideDiv>
                <PlaceOrder
                  grandTotal={grandTotal}
                  subTotal={subTotal}
                  actualTotal={actualTotal}
                  deliveryCharges={selectedArea?.deliveryCharges}
                />
              </SlideDiv>
            </div>
          ) : (
            // setCheckOut(false)
            dispatch(setCartCheckout(false))
          )}
        </div>
      ) : null}
    </Box>
  );
};

export default Cart;
