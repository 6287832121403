import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { calculateVoucherDiscount } from "./utils";

const initialState = {
  cartItems: [],
  subTotal: 0,
  deliveryCharges: 0,
  grandTotal: 0,
  cartItemCount: 0,
  cartVisible: false,
  orderType: "",
  voucherDisc: null,
  voucherDiscType: "",
  afterVoucherDisc: null,
  actualTotal: 0,
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { item, toppings, discPrice } = action.payload;
      if (toppings) {
        const withToppingSubtotal =
          state.subTotal +
          (discPrice ? 0 : toppings.toppingAmount) +
          (discPrice ? discPrice : item.price);
        let afterVoucherDiscount = calculateVoucherDiscount(
          withToppingSubtotal,
          state.voucherDisc,
          state.voucherDiscType
        );
        // const afterVoucherDisc = state.voucherDisc
        //   ? withToppingSubtotal - (withToppingSubtotal * state.voucherDisc) / 100
        //   : withToppingSubtotal;
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            {
              ...item,
              discPrice: discPrice,
              totalDiscPrice: discPrice,
              totalPrice: item.price + toppings.toppingAmount,
              cartId: item.cartId === undefined ? uuidv4() : item.cartId,
              ...toppings,
              quantity: 1,
            },
          ],
          subTotal: withToppingSubtotal,
          // afterVoucherDisc: afterVoucherDisc,
          afterVoucherDisc: afterVoucherDiscount,
          cartItemCount: state.cartItemCount + 1,
          // grandTotal: afterVoucherDisc + state.deliveryCharges,
          grandTotal: afterVoucherDiscount + state.deliveryCharges,
          actualTotal: state.actualTotal + (item.price + toppings.toppingAmount),
        };
      } else {
        const withoutToppingSubtotal =
          state.subTotal + (discPrice !== null ? discPrice : item.price);

        let afterVoucherDiscount = calculateVoucherDiscount(
          withoutToppingSubtotal,
          state.voucherDisc,
          state.voucherDiscType
        );
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            {
              ...item,
              discPrice: discPrice,
              totalDiscPrice: discPrice,
              totalPrice: item.price,
              cartId: item.cartId === undefined ? uuidv4() : item.cartId,
              toppings: [],
              toppingAmount: 0,
              quantity: 1,
            },
          ],
          subTotal: withoutToppingSubtotal,
          // subTotal: state.subTotal + item.price,
          // new
          afterVoucherDisc: afterVoucherDiscount,
          // old
          // afterVoucherDisc: afterVoucherDisc,
          cartItemCount: state.cartItemCount + 1,
          // new
          grandTotal: afterVoucherDiscount + state.deliveryCharges,
          // old
          // grandTotal: afterVoucherDisc + state.deliveryCharges,
          actualTotal: state.actualTotal + item.price,
        };
      }
    },
    removeCartItem(state, action) {
      if (state.cartItemCount === 1) {
        return {
          ...state,
          cartItems: [],
          subTotal: 0,
          afterVoucherDisc: 0,
          cartItemCount: 0,
          grandTotal: 0,
          actualTotal: 0,
        };
      } else {
        const afterRemoveSubtotal =
          state.subTotal -
          (action.payload.discPrice ? action.payload.totalDiscPrice : action.payload.totalPrice);

        let afterVoucherDiscount = calculateVoucherDiscount(
          afterRemoveSubtotal,
          state.voucherDisc,
          state.voucherDiscType
        );

        // const afterVoucherDisc = state.voucherDisc
        //   ? afterRemoveSubtotal - (afterRemoveSubtotal * state.voucherDisc) / 100
        //   : afterRemoveSubtotal;
        return {
          ...state,
          cartItems: state.cartItems.filter((cItem) => cItem.cartId !== action.payload.cartId),
          subTotal: afterRemoveSubtotal,
          // afterVoucherDisc: afterVoucherDisc,
          afterVoucherDisc: afterVoucherDiscount,
          cartItemCount: state.cartItemCount - action.payload.quantity,
          // grandTotal: afterVoucherDisc + state.deliveryCharges,
          grandTotal: afterVoucherDiscount + state.deliveryCharges,
          actualTotal: state.actualTotal - action.payload.totalPrice,
        };
      }
    },
    showCartToggle(state, action) {
      return {
        ...state,
        cartVisible: !state.cartVisible,
      };
    },
    clearCart(state, action) {
      return {
        ...state,
        cartItems: [],
        subTotal: 0,
        afterVoucherDisc: 0,
        grandTotal: 0,
        cartItemCount: 0,
        actualTotal: 0,
        cartVisible: true,
      };
    },
    setDeliveryCharge(state, action) {
      return {
        ...state,
        deliveryCharges: action.payload,
        grandTotal: state.subTotal + action.payload,
      };
    },
    setOrderType(state, action) {
      return {
        ...state,
        orderType: action.payload.orderTypeLocal,
        deliveryCharges:
          action.payload.orderTypeLocal === "delivery" ? action.payload.deliveryCharges : 0,
        grandTotal: state.subTotal + action.payload.deliveryCharges,
      };
    },

    increaseQuantity(state, action) {
      const incSubtotal = state.subTotal + action.payload.discount;
      let afterVoucherDiscount = calculateVoucherDiscount(
        incSubtotal,
        state.voucherDisc,
        state.voucherDiscType
      );

      // const incAfterDiscount = state.voucherDisc
      //   ? incSubtotal - (incSubtotal * state.voucherDisc) / 100
      //   : incSubtotal;
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.cartId === action.payload.id) {
            return {
              ...item,
              quantity: item.quantity + 1,
              totalDiscPrice: item.discPrice * (item.quantity + 1),
              totalPrice: (item.price + item.toppingAmount) * (item.quantity + 1),
            };
          } else return item;
        }),
        subTotal: state.subTotal + action.payload.discount,
        // afterVoucherDisc: incAfterDiscount,
        afterVoucherDisc: afterVoucherDiscount,
        cartItemCount: state.cartItemCount + 1,
        // grandTotal: incAfterDiscount + state.deliveryCharges,
        grandTotal: afterVoucherDiscount + state.deliveryCharges,
        actualTotal: state.actualTotal + action.payload.price,
      };
    },

    decreaseQuantity(state, action) {
      if (state.cartItemCount === 1) {
        return {
          ...state,
          cartItems: [],
          subTotal: 0,
          afterVoucherDisc: 0,
          cartItemCount: 0,
          grandTotal: 0,
        };
      } else {
        const decSubtotal = state.subTotal - action.payload.discount;
        let afterVoucherDiscount = calculateVoucherDiscount(
          decSubtotal,
          state.voucherDisc,
          state.voucherDiscType
        );

        // const decAfterDiscount = state.voucherDisc
        //   ? decSubtotal - (decSubtotal * state.voucherDisc) / 100
        //   : decSubtotal;
        return {
          ...state,
          cartItems: state.cartItems.map((item) => {
            if (item.cartId === action.payload.id) {
              return {
                ...item,
                quantity: item.quantity - 1,
                totalDiscPrice: item.discPrice * (item.quantity - 1),
                totalPrice: (item.price + item.toppingAmount) * (item.quantity - 1),
              };
            } else return item;
          }),
          subTotal: decSubtotal,
          afterVoucherDisc: afterVoucherDiscount,
          // afterVoucherDisc: decAfterDiscount,
          cartItemCount: state.cartItemCount - 1,
          // grandTotal: decAfterDiscount + state.deliveryCharges,
          grandTotal: afterVoucherDiscount + state.deliveryCharges,
          actualTotal: state.actualTotal - action.payload.price,
        };
      }
    },

    addChefNote(state, action) {
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.cartId === action.payload.id) {
            return {
              ...item,
              chefNote: action.payload.note,
            };
          } else return item;
        }),
      };
    },

    setVoucherDiscount(state, action) {
      let voucherSubtotal, voucherDisc, discString;
      if (action.payload.voucher.discountType === "amount") {
        voucherDisc = action.payload.voucher.discountAmount;
        voucherSubtotal = state.subTotal - voucherDisc;
        discString = voucherDisc + " €";
      } else {
        voucherDisc = action.payload.voucher.discountPercent;
        voucherSubtotal = state.subTotal - (state.subTotal * voucherDisc) / 100;
        discString = voucherDisc + "%";
      }
      return {
        ...state,
        voucherDiscType: action.payload.voucher.discountType,
        voucherDisc: voucherDisc,
        afterVoucherDisc: voucherSubtotal,
        grandTotal: voucherSubtotal + state.deliveryCharges,
      };
    },
    resetCart(state, action) {
      return {
        ...state,
        cartItems: [],
        subTotal: 0,
        afterVoucherDisc: null,
        voucherDisc: null,
        grandTotal: 0,
        actualTotal: 0,
        cartItemCount: 0,
        cartVisible: action.payload,
      };
    },
  },
});

export const {
  addToCart,
  removeCartItem,
  showCartToggle,
  clearCart,
  setDeliveryCharge,
  setOrderType,
  increaseQuantity,
  decreaseQuantity,
  addChefNote,
  setVoucherDiscount,
  resetCart,
} = CartSlice.actions;
