import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../../../ToolKit/Slices/NotifierSlice";
import { enqueueSnackbar } from "notistack";

const NotifierF = (props) => {
  const [displayed, setDisplayed] = useState([]);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifier.notifications);

  notifications.map((notification) => {
    setTimeout(() => {
    // If notification already displayed, abort
    if (displayed.filter((key) => key === notification.key).length > 0) {
      return;
    }
    if (notification.withName) {
      enqueueSnackbar(t("notify.Welcome") + " " + notification.message, {
        variant: notification.type,
        persist: notification.persist,
      });
    } else {
      enqueueSnackbar(t(`notify.${notification.message}`), {
        variant: notification.type,
        persist: notification.persist,
      });
    }
    // Add notification's key to the local state
    setDisplayed([...displayed, notification.key]);
    // Dispatch action to remove the notification from the redux store
    //   this.props.removeNotification(notification.key);
    dispatch(removeNotification(notification.key));
    }, 300);
    return null;
  });
  return null;
};

export default NotifierF;
