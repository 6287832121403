const StripeLogo = () => {
  // BG 675DFF
  // h-[30px] w-[30px]
  return (
    <div className="rounded-lg bg-[#675DFF] text-lg h-[27px] w-[27px] text-center font-bold text-white">
      S
    </div>
  );
};

export default StripeLogo;
