import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

import {
  checkExisting,
  checkExistingPrice,
  decreaseLimit,
  increaseLimit,
  resetLimit,
} from "./utils";

const initialState = {
  toppingCategories: null,
  toppings: null,
  toppingSizePrices: null,
  toppingAndItem: null,

  currToppingPrice: 0,
  currToppings: {
    toppings: [],
    toppingAmount: 0,
  },
  minLimitFulfiled: false,
  currToppingCat: null,
  limitCounter: null,
  minLimits: [],
  id: 1,

  loading: true,
  error: null,
  requiredToppingSelectionRemaining: false,
};

//  ASYNC THUNKS

// 1. FETCH TOPPINGS

export const fetchToppings = createAsyncThunk("/topping_data", async (_, thunkApi) => {
  const { rejectWithValue, dispatch } = thunkApi;

  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/topping_data`);

    return response.data;
  } catch (error) {
    console.log("error", error);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    return rejectWithValue(error.response.data);
  }
});

//  THE SLICE

export const ToppingSlice = createSlice({
  name: "topping",
  initialState,
  reducers: {
    resetToppings(state, action) {
      return {
        ...state,
        currToppings: {
          toppings: [],
          toppingAmount: 0,
        },
        currToppingCat: null,
      };
    },
    addToppings(state, action) {
      const limitCounter = decreaseLimit(
        state.limitCounter,
        action.payload.tCatId,
        action.payload.max,
        action.payload.free
      );
      const minLimCats = limitCounter.filter((lim) => lim.min > 0);
      if (!state.currToppings.toppings) {
        state.currToppings.toppings.push({
          id: state.id,
          tId: action.payload.tId,
          name: action.payload.name,
          price: action.payload.price,
          tCatId: action.payload.tCatId,
        });
        state.currToppings.toppingAmount = action.payload.price;
        state.id = state.id + 1;
        state.limitCounter = limitCounter;
        state.minLimits = minLimCats;
        // return {
        //   ...state,
        //   currToppings: {
        //     ...state.currToppings,
        //     toppings: [
        //       {
        //         id: state.id,
        //         tId: action.payload.tId,
        //         name: action.payload.name,
        //         price: action.payload.price,
        //         tCatId: action.payload.tCatId,
        //       },
        //     ],
        //     toppingAmount: action.payload.price,
        //   },
        //   id: state.id + 1,
        //   limitCounter: limitCounter,
        //   minLimits: minLimCats,
        // };
      } else {
        state.currToppings.toppings.push({
          id: state.id,
          tId: action.payload.tId,
          name: action.payload.name,
          price: action.payload.price,
          tCatId: action.payload.tCatId,
        });
        state.currToppings.toppingAmount = state.currToppings.toppingAmount + action.payload.price;
        state.id = state.id + 1;
        state.limitCounter = limitCounter;
        state.minLimits = minLimCats;
        // return {
        //   ...state,
        //   currToppings: {
        //     ...state.currToppings,
        //     toppings: [
        //       ...state.currToppings.toppings,
        //       {
        //         id: state.id,
        //         tId: action.payload.tId,
        //         name: action.payload.name,
        //         price: action.payload.price,
        //         tCatId: action.payload.tCatId,
        //       },
        //     ],
        //     toppingAmount: state.currToppings.toppingAmount + action.payload.price,
        //   },
        //   id: state.id + 1,
        //   limitCounter: limitCounter,
        //   minLimits: minLimCats,
        // };
      }
    },
    removeSingleTopping(state, action) {
      // the issue was here
      const limitFilter = state.limitCounter.filter((lim) => lim.tCatId !== action.payload.tCatId);
      const minLimits = limitFilter.filter((lim) => lim.min > 0);

      const removedTopping = state.currToppings.toppings.find(
        (topping) => topping.tId === action.payload.tId
      );

      if (removedTopping) {
        state.currToppings.toppings = state.currToppings.toppings.filter(
          (topping) => topping.tId !== action.payload.tId
        );

        state.currToppings.toppingAmount -= removedTopping.price;
        state.id = state.id - 1;
        state.limitCounter = limitFilter;
        state.minLimits = minLimits;
      }
    },
    addSingleTopping(state, action) {
      const limitFilter = state.limitCounter.filter((lim) => lim.tCatId !== action.payload.tCatId);
      const minLimitsT = limitFilter.filter((lim) => lim.min > 0);
      if (!checkExisting(state.currToppings.toppings, action.payload.tCatId)) {
        return {
          ...state,
          currToppings: {
            ...state.currToppings,
            toppings: [
              ...state.currToppings.toppings,
              {
                id: state.id,
                tId: action.payload.tId,
                name: action.payload.name,
                price: action.payload.price,
                tCatId: action.payload.tCatId,
              },
            ],
            toppingAmount: state.currToppings.toppingAmount + action.payload.price,
          },
          id: state.id + 1,
          limitCounter: limitFilter,
          minLimits: minLimitsT,
        };
      } else {
        return {
          ...state,
          currToppings: {
            ...state.currToppings,
            toppingAmount:
              state.currToppings.toppingAmount +
              (action.payload.price -
                checkExistingPrice(state.currToppings.toppings, action.payload.tCatId)),
            toppings: [
              ...state.currToppings.toppings.filter(
                (topping) => topping.tCatId !== action.payload.tCatId
              ),
              {
                id: state.id,
                tId: action.payload.tId,
                name: action.payload.name,
                price: action.payload.price,
                tCatId: action.payload.tCatId,
              },
            ],
          },
          id: state.id + 1,
          limitCounter: limitFilter,
          minLimits: minLimitsT,
        };
      }
    },
    removeTopping(state, action) {
      let toRemove = null;
      let totalPrice = 0;
      let freeCount = 0;

      if (action.payload.count > 1) {
        toRemove = state.currToppings.toppings.filter(
          (topping) => topping.tId === action.payload.id
        );
        toRemove.map((t) => (totalPrice = totalPrice + t.price));
        toRemove.map((t) => (t.price === 0 ? (freeCount = freeCount + 1) : null));
      } else {
        toRemove = state.currToppings.toppings.filter(
          (topping) => topping.tId === action.payload.id
        );
        totalPrice = toRemove[0].price;
        freeCount = totalPrice === 0 ? (freeCount = 1) : (freeCount = 0);
      }
      const minActual = state.toppingCategories.filter(
        (tCat) => tCat.tCatId === action.payload.tCatId
      )[0].min;
      const increaseLim = increaseLimit(
        state.limitCounter,
        action.payload.tCatId,
        freeCount,
        action.payload.count,
        minActual
      );
      const increaseMin = increaseLim.filter((lim) => lim.min > 0);
      let temp = state.currToppings;
      temp.toppingAmount = state.currToppings.toppingAmount - totalPrice;
      temp.toppings = state.currToppings.toppings.filter(
        (topping) => topping.tId !== action.payload.id
      );

      state.currToppings = temp;

      state.limitCounter = increaseLim;
      state.minLimits = increaseMin;
    },
    initToppingLimit(state, action) {
      return {
        ...state,
        limitCounter: action.payload,
      };
    },
    iniMinLimits(state, action) {
      return {
        ...state,
        minLimits: action.payload,
      };
    },
    resetToppingLimit(state, action) {
      return {
        ...state,
        limitCounter: resetLimit(state.limitCounter, action.payload),
      };
    },
    setToppingCat(state, action) {
      return {
        ...state,
        currToppingCat: action.payload.id,
      };
    },
    setIsRequiredToppingsNotSelected(state, action) {
      state.requiredToppingSelectionRemaining = action.payload;
    },
  },
  extraReducers: {
    // FETCH TOPPINGS
    [fetchToppings.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [fetchToppings.fulfilled]: (state, action) => {
      return {
        ...state,
        toppingCategories: action.payload[0],
        toppings: action.payload[1],
        toppingSizePrices: action.payload[2],
        toppingAndItem: action.payload[3],
        loading: false,
        error: null,
      };
    },
    [fetchToppings.rejected]: (state, action) => {
      return {
        ...state,
        error: action?.error?.message || "Error",
        loading: false,
        toppingCategories: null,
        toppings: null,
        toppingCatSizes: null,
        toppingSizes: null,
        toppingSizePrices: null,
        toppingAndItem: null,
      };
    },
  },
});

// Some Aync Remaining ...

export const {
  addToppings,
  resetToppings,
  removeSingleTopping,
  addSingleTopping,
  removeTopping,
  initToppingLimit,
  iniMinLimits,
  resetToppingLimit,
  setToppingCat,
  setIsRequiredToppingsNotSelected,
} = ToppingSlice.actions;
