import React, { memo } from "react";
import { useSelector } from "react-redux";

export const PriceCalculator = (props) => {
  const { item, price, toppingAmount } = props;

  const discounts = useSelector((state) => state.shop.settings.discounts);
  const orderType = useSelector((state) => state.cart.orderType);
  const priceFormat = window.$priceFormater;

  const orignalPrice = price + toppingAmount;
  let discPrice = orignalPrice;

  if (!item.isDiscount) {
    if (discounts.deliveryDiscountActive && item.deliveryDisc && orderType === "delivery") {
      discPrice = orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
    } else if (discounts.pickupDiscountActive && item.pickupDisc && orderType === "pickup") {
      discPrice = orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
    } else {
      discPrice = orignalPrice;
    }
  } else {
    // if item has its own discount discount

    if (orderType === "delivery") {
      if (item.discounts.deliveryType === "percentage") {
        discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.delivery;
      } else {
        discPrice = orignalPrice - item.discounts.delivery;
      }
    } else {
      if (item.discounts.pickupType === "percentage") {
        discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.pickup;
      } else {
        discPrice = orignalPrice - item.discounts.pickup;
      }
    }
  }

  // if (discounts.active) {
  //   if (orderType === "delivery" && item.deliveryDisc) {
  //     discPrice =
  //       orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
  //   } else if (orderType === "pickup" && item.pickupDisc) {
  //     discPrice =
  //       orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
  //   } else discPrice = orignalPrice;
  // }

  return (
    <div>
      {discPrice !== orignalPrice ? (
        <div>
          <span
            style={{
              textDecoration: "line-through",
              fontSize: "11px",
              opacity: 0.7,
            }}
          >
            {orignalPrice.toFixed(2)}
          </span>
          <span>&nbsp;{priceFormat.format(discPrice)}</span>
        </div>
      ) : (
        <span>{priceFormat.format(orignalPrice)}</span>
      )}
    </div>
  );
};

export default memo(PriceCalculator);
