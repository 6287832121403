import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppBar, Button, Toolbar, MenuItem, Menu, IconButton, Badge } from "@mui/material";
import {
  AccountCircle,
  ExitToApp,
  Info,
  VpnKeySharp,
  PersonAdd,
  LocalMallSharp,
  Search,
  Language,
  Assignment,
} from "@mui/icons-material";

import NavDrawer from "../NavDrawer/NavDrawer";
import "./Header.css";
import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import { useTranslation } from "react-i18next";
import { viewFirstCategory, viewSearchCategory } from "../../../ToolKit/Slices/MenuSlice";
import { resetCustomize } from "../../../ToolKit/Slices/ItemSlice";
import {
  ordersContainerToggle,
  setIncompleteOrdersCount,
} from "../../../ToolKit/Slices/OrderSlice";
import { logoutUser, profileToggle, userDialogToggle } from "../../../ToolKit/Slices/UserSlice";
import {
  policyDialogToggle,
  shopDataToggle,
  termsDialogToggle,
} from "../../../ToolKit/Slices/ShopSlice";

import { useEffect } from "react";
import OfferDialog from "../Dialogues/OfferDialog";
import axios from "axios";

const Header = (props) => {
  const currUser = useSelector((state) => state.user.currUser);
  const incompleteOrdersCount = useSelector((state) => state.order.incompleteOrdersCount);
  const isManifestPresent = useSelector((state) => state.shop.isManifestPresent);
  const ShopData = useSelector((state) => state.shop.shopData);

  const manifest = ShopData?.manifest;
  const dispatch = useDispatch();

  const [svgLogo, setSvgLogo] = useState(false);
  const [isLogoSvg, setIsLogoSvg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageSource, setImageSource] = useState("");
  const open = Boolean(anchorEl);
  const offerMessage = useSelector((state) => state.shop.homepage?.shopMessage?.message);
  const offerClosed = useSelector((state) => state.user.offerClosed);

  useEffect(() => {
    checkImageSource();
  }, [ShopData]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //  check if image is in svg format
  const isSVGFormatImage = (url) => {
    return url.endsWith(".svg");
  };

  const checkImageSource = () => {
    if (ShopData) {
      if (isManifestPresent) {
        if (manifest["120xauto"] !== "") {
          setImageSource(manifest["120xauto"]);
          setIsLogoSvg(isSVGFormatImage(manifest["120xauto"]));
        } else {
          setImageSource("/logos/logo.svg");
          setIsLogoSvg(isSVGFormatImage("/logos/logo.svg"));
        }
      } else {
        setImageSource("/logos/logo.svg");
        setIsLogoSvg(isSVGFormatImage("/logos/logo.svg"));
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEli, setAnchorEli] = React.useState(null);
  const openi = Boolean(anchorEli);

  const handleMenui = (event) => {
    setAnchorEli(event.currentTarget);
  };

  const handleClosei = () => {
    setAnchorEli(null);
  };

  const { screenWidth } = useWindowDimensions();
  const { t, i18n } = useTranslation();

  const logoSrc = "/logos/logo.png";

  const handleBrokenImg = (e) => {
    setSvgLogo(true);
    e.target.src = "/logos/logo.svg";
  };

  const [imgWidth, setImgWidth] = useState("120");
  const [isImgBigger, setIsImgBigger] = useState(false);

  // let imgWidth = "120";
  const currCat = useSelector((state) => state.menu.selectedCategory);

  const setImageBiggerOrNot = () => {
    const img = new Image();
    img.onload = function () {
      if (this.height > 50 && currCat?.name !== "search") {
        setIsImgBigger(true);
      } else {
        if (currCat?.name === "search") {
          setImgWidth("60");
        }
        setIsImgBigger(false);
      }
    };
    img.src = manifest?.["120xauto"];
  };
  useEffect(() => {
    setImageBiggerOrNot();
  }, [manifest]);

  useEffect(() => {
    if (currCat?.name === "search") {
      setImgWidth("60");
      setIsImgBigger(false);
    }
  }, [currCat?.name]);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      document.addEventListener("scroll", () => {
        setTimeout(() => {
          let tempHeight = document.getElementById("logoSqueeze")?.offsetHeight;
          // console.log(document.getElementById("logoSqueeze").naturalHeight);
          if (tempHeight > 66) {
            setIsImgBigger(true);
          } else {
            setIsImgBigger(false);
          }
          if (window.scrollY > 225 && tempHeight > 66) {
            setImgWidth("60");
          } else if (window.scrollY < 225 && tempHeight < 66) {
            if (currCat?.name === "search") {
              setImgWidth("60");
            } else {
              setImgWidth("120");
            }
          }
        }, 500);
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [window.scrollY]);

  // useEffect(() => {
  //   throw new Error("This is an error");
  // }, []);
  // console.log("socket.id", hello);
  return (
    <AppBar
      id="mainHeader"
      position="fixed"
      style={{
        minHeight: "66px",
        // top:
        //   offerMessage === "" || offerMessage === undefined ? "0px" : offerClosed ? "0px" : "37px",
      }}
    >
      {offerClosed ? null : <OfferDialog />}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "66px",
        }}
        className={screenWidth < 1000 ? "toolBar" : null}
      >
        <div
          className="logo"
          style={{
            height: "66px",
            // overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            // onClick={() => {
            //   axios.get("https://orionid.herokuapp.com/api/").then((res) => {
            //     console.log(res.data);
            //   });
            // }}
            id="logoSqueeze"
            className="z-[100000]"
            style={{
              transition: "width .5s ease",
              position: isImgBigger ? "absolute" : "unset",
              marginTop: isImgBigger ? "55px" : "0px",
              //   document.getElementById("logoSqueeze")?.offsetHeight > 66 ? "-31px" : "0px",
              opacity: imageSource !== "" ? "1" : "0",
            }}
            width={imgWidth}
            src={imageSource}
            alt="logo"
          />
        </div>
        <nav
          className="Nav justify-center items-center"
          // style={{ alignSelf: screenWidth < 600 ? "flex-end" : "unset" }}
        >
          {screenWidth < 1000 ? (
            <ul className="NavButtons">
              <li className="NavButton">
                <Button
                  color="inherit"
                  size="small"
                  // style={{ color: "white" }}
                  onClick={() => {
                    dispatch(viewSearchCategory());
                    dispatch(resetCustomize());
                  }}
                >
                  <Search />
                </Button>
              </li>
              <li className="NavButton">
                <Button
                  color="inherit"
                  size="small"
                  // style={{ color: "white" }}
                  onClick={() => dispatch(ordersContainerToggle(true))}
                  startIcon={
                    <Badge
                      badgeContent={incompleteOrdersCount}
                      // sx={{ bgcolor: "black !important" }}
                      color="secondary"
                    >
                      <LocalMallSharp fontSize="small" />
                    </Badge>
                  }
                >
                  {t("userProfile.orders")}
                </Button>
              </li>

              <NavDrawer />
            </ul>
          ) : (
            <ul className="NavButtons">
              <li className="NavButton">
                <Button
                  color="inherit"
                  size="small"
                  // style={{ color: "white" }}
                  onClick={() => {
                    dispatch(viewSearchCategory());
                    dispatch(resetCustomize());
                  }}
                >
                  <Search />
                </Button>
              </li>
              {/* {orderSent ? ( */}
              <li className="NavButton">
                <Button
                  color="inherit"
                  size="small"
                  // style={{ color: "white" }}
                  onClick={() => dispatch(ordersContainerToggle(true))}
                  startIcon={
                    <Badge badgeContent={incompleteOrdersCount} color="secondary">
                      <LocalMallSharp fontSize="small" />
                    </Badge>
                  }
                >
                  {t("userProfile.orders")}
                </Button>
              </li>
              {/* ) : null} */}

              {currUser ? (
                <React.Fragment>
                  <li className="NavButton">
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() => dispatch(profileToggle(true))}
                      startIcon={<AccountCircle fontSize="small" />}
                    >
                      {currUser.name}
                    </Button>
                  </li>
                  <li className="NavButton">
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() => {
                        dispatch(logoutUser());
                        dispatch(setIncompleteOrdersCount({ incOrders: 0 }));
                        dispatch(viewFirstCategory());
                      }}
                      startIcon={<ExitToApp fontSize="small" />}
                    >
                      {t("topBar.logout")}
                    </Button>
                  </li>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <li className="NavButton">
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() =>
                        dispatch(userDialogToggle({ toggle: true, userAction: "login" }))
                      }
                      startIcon={<VpnKeySharp fontSize="small" />}
                    >
                      {t("topBar.login")}
                    </Button>
                  </li>
                  <li className="NavButton">
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() =>
                        dispatch(userDialogToggle({ toggle: true, userAction: "register" }))
                      }
                      startIcon={<PersonAdd fontSize="small" />}
                    >
                      {t("topBar.register")}
                    </Button>
                  </li>
                </React.Fragment>
              )}
              <li className="NavButton">
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => dispatch(shopDataToggle(true))}
                  startIcon={<Info fontSize="small" />}
                >
                  {t("topBar.contact")}
                </Button>
              </li>
              <li style={{ paddingLeft: 10 }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  edge="end"
                  onClick={handleMenu}
                  color="inherit"
                  size="small"
                >
                  <Language />
                  <span style={{ fontSize: "13px", paddingLeft: "2px" }}>
                    {i18n.language === "en"
                      ? "EN"
                      : i18n.language === "de"
                      ? "DE"
                      : i18n.language === "tr"
                      ? "TR"
                      : "AUTO"}
                  </span>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      i18n.changeLanguage("tr");
                      handleClose();
                      window.location.reload();
                    }}
                  >
                    Türkçe
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      i18n.changeLanguage("de");
                      handleClose();
                      window.location.reload();
                    }}
                  >
                    Deutsch
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      i18n.changeLanguage("en");
                      handleClose();
                      window.location.reload();
                    }}
                  >
                    English
                  </MenuItem>
                </Menu>
              </li>
              <NavDrawer />
              <li style={{ paddingLeft: 10 }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  edge="end"
                  onClick={handleMenui}
                  color="inherit"
                  size="small"
                >
                  <Assignment />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEli}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openi}
                  onClose={handleClosei}
                >
                  <MenuItem
                    onClick={() => {
                      dispatch(termsDialogToggle(true));
                      handleClosei();
                    }}
                  >
                    {t("topBar.termsOfUsage")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(policyDialogToggle(true));
                      handleClosei();
                    }}
                  >
                    {t("topBar.dataPolicy")}
                  </MenuItem>
                </Menu>
              </li>
            </ul>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
