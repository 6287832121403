import React from "react";
import {useSelector} from "react-redux"

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";

export default function ShopMap() {
  const { screenWidth } = useWindowDimensions();
  const mapSrc = useSelector(state => state.shop.shopData.map)

  return (
    <div>
      <iframe
        title="Shopmap"
        src={mapSrc}
        width="100%"
        // width={screenWidth < 550 ? "100%" : "100%"}
        height="auto"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
}
