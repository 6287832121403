import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Dialoges.css";
import { HighlightOff } from "@mui/icons-material";
import { setOfferClose } from "../../../ToolKit/Slices/UserSlice";
import { IconButton } from "@mui/material";

const messageType = [
  { type: "Announcement", color: "red" },
  { type: "Offer", color: "#16dd84" },
];

function OfferDialog() {
  // const offerMessage = useSelector((state) => state.shop.homepage.offerMessage);
  const shopMessage = useSelector((state) => state.shop.homepage?.shopMessage);

  const [color, setColor] = useState();

  useEffect(() => {
    if (shopMessage) {
      const foundType = messageType.filter((el) => el.type === shopMessage?.type);
      setColor(foundType[0].color);
    }
  }, [shopMessage?.type]);

  const dispatch = useDispatch();

  // console.log("shopMessage", shopMessage);
  return (
    <div
      id="OfferMessage"
      className={`OfferMessage min-h-[40px] bg-[black] w-[90%] lg:w-[100%]`}
      style={{
        display:
          shopMessage?.message === "" || shopMessage?.message === undefined ? "none" : "block",
        // position: "fixed",
        zIndex: "1130",
        // marginTop: "10px",
      }}
    >
      <div className="OfferContainer grid grid-cols-[90%_10%] lg:grid-cols-[97%_2%] items-center">
        <p
          className="text-[12px] md:text-[16px]"
          style={{ margin: "8px", color: color, lineHeight: "14px" }}
        >
          {shopMessage?.message}
        </p>
        <IconButton className={`OfferClose`} onClick={() => dispatch(setOfferClose())}>
          <HighlightOff sx={{ color: color }} />
        </IconButton>
      </div>
    </div>
  );
}

export default OfferDialog;
