import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nextNotification: -1, // used for notifications keys
  notifications: [], // contains the list of notifications
};

export const NotifierSlice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    addNotification(state, action) {
      let key = state.nextNotification + 1;

      return {
        ...state,
        nextNotification: key, // save new notification key in state
        notifications: [{ ...action.payload, key: key }, ...state.notifications], // add notification with incremented key at the start of the list
      };
    },
    removeNotification(state, action) {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.payload
        ), // remove notification from the list for given key
      };
    },
  },
});

export const { addNotification, removeNotification } = NotifierSlice.actions;
