import React, { useState } from "react";

import "./ItemSizeCard.css";

import pizzaImg from "/media/pizza-size.png";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const ItemSizeCard = ({ children, name, size, handleClick, index, filteredCatSizes, height }) => {
  const selectedSizeId = useSelector((state) => state.item.selectedSizeId);
  const theme = useTheme();

  return (
    <div style={{ height: height }}>
      <div
        style={{
          backgroundColor:
            size.id === selectedSizeId ? theme.palette.primary.main : "rgba(0,0,0,0.3)",
          backdropFilter: "blur(10px)",
        }}
        className="item"
        onClick={(e) => handleClick(size)}
      >
        <div
          style={{
            color: selectedSizeId === size.id ? theme.palette.primary.contrastText : "white",
          }}
          className="item-size"
        >
          {size.size}
        </div>

        <div className="item-image">
          <img
            width={25}
            height={25}
            src={size?.image ? size.image : pizzaImg}
            alt="size "
            style={{ transform: index === 1 ? `scale(${0.9})` : `scale(${0.75 + index / 5})` }}
          />
        </div>

        <div className="blue-world=city item-price ">
          <span
            style={{
              color: selectedSizeId === size.id ? theme.palette.primary.contrastText : "white",
            }}
          >
            {children}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ItemSizeCard;
