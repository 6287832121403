import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCountDownWithDate } from "../Timer/useCountDownWithDate";
import { Box, Typography } from "@mui/material";

const ShopClosingTime = () => {
  const { t } = useTranslation();
  const isShopAboutToClose = useSelector((state) => state.shop.isShopAboutToClose);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const manuallyClosed = useSelector((state) => state.shop.manuallyClosed);
  const vacationActiveToday = useSelector((state) => state.shop.vacationActiveToday);

  const shopClosingDate = useSelector((state) => state.shop.shopClosingDate);
  const serverDate = useSelector((state) => state.shop.serverDate);


  const [days, hours, minutes, seconds] = useCountDownWithDate(shopClosingDate, serverDate);

  const isShopOpenToday = () => {
       // !todayTiming?.open this works in both the scenarios holiday and normal time
    if (manuallyClosed || vacationActiveToday || !todayTiming?.open ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="">
      {isShopOpenToday() &&
        isShopCurrentlyOpen?.status === true &&
        isShopAboutToClose?.shopStatus === true &&
        minutes >= 0 &&
        seconds >= 0 && (
          <Box
            className={`flex gap-2 items-center justify-center bg-[#ffed00] rounded-lg shadow-md  text-black p-1 font-bold`}
          >
            <Box>
              <Typography>
                {t("Shop Closing In")}
                &nbsp;
                <span className="font-bold">
                  {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
                </span>
                &nbsp;{t("Minutes")}
              </Typography>
            </Box>
          </Box>
        )}
    </div>
  );
};

export default ShopClosingTime;
