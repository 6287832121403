import React, { memo, useEffect, useRef, useState } from "react";
import ToppingCategory from "./ToppingCategory/ToppingCategory";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import useWindowDimensions from "../../Components/UI/ScreenSize/ScreenSize";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import styled from "@emotion/styled";
import { Chip, useTheme } from "@mui/material";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import useCountToppings from "./useCountToppings";
import { setIsRequiredToppingsNotSelected } from "../../ToolKit/Slices/ToppingSlice";
import { useTranslation } from "react-i18next";

const ToppingMessage = (props) => {
  const { t } = useTranslation();
  const { toppingCat, limit } = props;
  if (toppingCat.max === 1 && (toppingCat.min === 1 || toppingCat.min === 0)) {
    return (
      <div className={`ExpSummary  `}>
        <span style={{ fontWeight: "bold" }}>{toppingCat.min > 0 ? <span> *</span> : null}</span>
      </div>
    );
  } else {
    return (
      <span style={{ marginLeft: "0.5rem", fontWeight: "bold" }}>
        {toppingCat?.free > 0
          ? `(${t("toppings.free")}: ${
              toppingCat?.free - limit?.filter((lim) => lim.tCatId === toppingCat.tCatId)[0]?.free
            }/${toppingCat?.free})`
          : // here
            null}
      </span>
    );
  }
};

const Toppings = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const priceFormat = window.$priceFormater;
  const dispatch = useDispatch();
  // local states

  // redux states
  const toppingCategories = useSelector((state) => state.item.currItemToppingCats);
  const currItem = useSelector((state) => state.item.currItem);
  const itemHasSize = currItem?.itemHasSize;
  const selectedToppings = useSelector((state) => state.topping.currToppings.toppings);
  const minLimits = useSelector((state) => state.topping.minLimits);
  const limit = useSelector((state) => state.topping.limitCounter);
  const { screenWidth } = useWindowDimensions();
  const { selectedToppingCount } = useCountToppings();

  const requiredToppingSelectionRemaining = useSelector(
    (state) => state.topping.requiredToppingSelectionRemaining
  );

  const [activeStep, setActiveStep] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  // const [isMounted, setIsMounted] = React.useState(false);

  const handleCategory = (index) => {
    setButtonClicked(true);
    setActiveStep(index);
  };

  useEffect(() => {
    if (requiredToppingSelectionRemaining) {
      if (minLimits.length < 1) {
        dispatch(setIsRequiredToppingsNotSelected(false));
      }
    }
  }, [requiredToppingSelectionRemaining, minLimits]);

  const priceFormatter = window.$priceFormater;

  const ColorlibStepIconRoot = styled("div")(() => ({
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  }));

  const StepIconFree = (props) => {
    return (
      <ColorlibStepIconRoot>
        {props.image ? (
          <Avatar src={props.image} />
        ) : (
          <Avatar>{props?.description?.slice(0, 1)?.toUpperCase()}</Avatar>
        )}
      </ColorlibStepIconRoot>
    );
  };

  // const handleDelete = (singleTop) => {
  //   // single topping occurences for removeTopping
  //   const count = singleTop.count;

  //   // fire removeTopping()

  //   dispatch(
  //     removeTopping({
  //       id: singleTop.item.tId,
  //       tCatId: singleTop.item.tCatId,
  //       count: count,
  //     })
  //   );
  // };

  const checkTCatId = (toppingCategory, index) => {
    let dataObj = { item: null, index: null };

    minLimits.filter((f, index) => {
      if (f.tCatId === toppingCategory.tCatId) {
        dataObj.item = f;
        dataObj.index = index;
      }
    });
    if (dataObj.item) {
      // setActiveStep(dataObj.index);
      return true;
    } else {
      return false;
    }
  };

  // React.useEffect(() => {
  //   setIsMounted(true);
  // }, []);

  // React.useEffect(() => {
  //   if (isMounted) {
  //     const itemDialiogScroll = document.querySelector(".item-dialog-scroll");
  //     if(itemDialiogScroll){
  //       const computedStyle = window.getComputedStyle(itemDialiogScroll);
  //       return computedStyle.position === 'sticky';
  //     }
  //     console.log("itemDialiogScroll", itemDialiogScroll);
  //     itemDialiogScroll.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (isMounted) {
  //       const itemDialiogScroll = document.querySelector(".item-dialog-scroll");
  //       itemDialiogScroll?.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [isMounted]);

  // const handleScroll = () => {
  //   console.log("User is scrolling!", window.scrollY);
  //   // Add your function logic here
  //   // if (descRef.current) {
  //   //   console.log("red");
  //   //   const rect = descRef.current.getBoundingClientRect();
  //   //   const isVisible =
  //   //     rect.top >= 0 &&
  //   //     rect.left >= 0 &&
  //   //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //   //     rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  //   //   setIsVisible(isVisible);
  //   // }
  // };

  return screenWidth < 550 ? (
    <Box sx={{ maxWidth: 400, marginTop: itemHasSize && "50px" }}>
      <Stepper orientation="vertical" className="item-dialog-scroll">
        {toppingCategories.map((toppingCat, index) => {
          // if (requiredToppingSelectionRemaining) {
          //   checkTCatId(toppingCat, index);
          // }
          return (
            <Step
              className={`${
                toppingCat.min > 0 && "topping-selection-required-" + toppingCat.tCatId
              }`}
              active={
                requiredToppingSelectionRemaining
                  ? checkTCatId(toppingCat, index)
                  : index === activeStep
              }
              // active={
              //   requiredToppingSelectionRemaining
              //     ? checkTCatId(toppingCat, index)
              //     : index === activeStep
              // }
              key={index}
            >
              <StepLabel
                onClick={() => handleCategory(index)}
                StepIconComponent={() => StepIconFree(toppingCat)}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* toppingCat and arrow div */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 1,
                        mr: 1,
                        fontWeight: "bold",
                        // visibility: index !== activeStep ? "visible" : "hidden",
                      }}
                    >
                      {toppingCat.description}
                      <ToppingMessage toppingCat={toppingCat} limit={limit} />
                    </Typography>

                    {index === activeStep ? <ArrowDropUp /> : <ArrowDropDown />}
                  </div>

                  {/*show selectedToppings */}
                  <div
                    style={{
                      display: index !== activeStep ? "flex" : "none",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedToppingCount.map((sTC, index) => {
                      if (sTC.item.tCatId === toppingCat.tCatId) {
                        return (
                          <Chip
                            sx={{ margin: "4px" }}
                            label={
                              sTC.item.name +
                              " " +
                              priceFormatter.format(sTC.item.price * sTC.count)
                            }
                            color="primary"
                            avatar={<Avatar>{sTC.count}</Avatar>}
                            onDelete={""}
                            size="small"
                          />
                        );
                      }
                    })}
                  </div>

                  {/* {index !== activeStep && (
                  <Typography sx={{ mt: 1, mr: 1, fontWeight: "bold" , visibility: index !== activeStep ? "hidden" : "visible" }}>
                    {toppingCat.description}
                  </Typography>
                )} */}
                </div>
              </StepLabel>
              <StepContent sx={{ mt: "-1rem" }}>
                {/* show respective toppings for each step */}
                <div>
                  <ToppingCategory
                    parentRef={props.parentRef}
                    key={toppingCat.tCatId}
                    tCategory={toppingCat}
                    required={props.required}
                    inStepper={true}
                  />
                </div>

                {/* cart preview below Stepper */}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      {/* cart preview */}

      {/* cart that is showing below for testing purposes */}
      {selectedToppings.length !== 0 ? (
        <Box className="flex items-center justify-center ">
          <Box sx={{ minWidth: 300, mt: 4, mx: 2 }} className="p-2 shadow-xl">
            <Box>
              <Box className="flex items-center gap-4">
                <Typography> {currItem.name}</Typography>
                <Typography fontSize={".8rem"}> {priceFormat.format(currItem.price)}</Typography>
              </Box>
              <Typography> {currItem.size}</Typography>
            </Box>
            <Box className="w-full">
              {selectedToppingCount.map((selected, index) => (
                <p key={index} className="flex justify-between text-xs">
                  <span className="item-count">
                    x{selected.count}
                    &nbsp;
                    {selected.item.name}
                  </span>

                  <span>{priceFormat.format(selected.item.price * selected.count)}</span>
                </p>
              ))}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  ) : (
    <div className="p-2 ">
      {/* <div>hello</div> */}
      {toppingCategories.length > 0
        ? toppingCategories?.map((tCat, key) => (
            <React.Fragment key={key}>
              <ToppingCategory key={tCat.tCatId} tCategory={tCat} required={props.required} />
            </React.Fragment>
          ))
        : null}
    </div>
  );
};

export default Toppings;
