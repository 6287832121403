import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch, useSelector } from "react-redux";
import { Close, Restaurant } from "@mui/icons-material";

import "./ItemDialog.css";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import CartToggle from "../../Cart/CartToggle/CartToggle";
import ItemSizePrices from "./ItemSizePrices";
import ItemCustomizationNew from "../../ItemCustomization/ItemCustomizationNew";
import { resetCustomize } from "../../../ToolKit/Slices/ItemSlice";
import { resetToppings } from "../../../ToolKit/Slices/ToppingSlice";
import { Avatar, IconButton, useTheme } from "@mui/material";
import ItemSizeCard from "./ItemSizeCard";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function ItemDialog({
  open,
  setOpen,
  item,
  hasSize,
  offerItemAvailable,
  offerMessage,
  handleClick,
}) {
  const { screenWidth, screenHeight } = useWindowDimensions();
  const dispatch = useDispatch();
  const theme = useTheme();
  const currItem = useSelector((state) => state.item.currItem);
  const sizedPrices = useSelector((state) => state.menu.prices);
  const sizes = useSelector((state) => state.menu.sizes);

  const [filteredCatSizes, setFilteredCatSizes] = React.useState([]);

  const [height, setHeight] = React.useState(80);
  const [itemPrices, setItemPrices] = React.useState(null);
  const parentRef = React.useRef();

  React.useEffect(() => {
    if (filteredCatSizes.length > 3) {
      setHeight(90);
    }
  }, [filteredCatSizes]);

  const handleClose = () => {
    dispatch(resetCustomize());
    dispatch(resetToppings());
    setOpen(false);
  };

  React.useEffect(() => {
    if (hasSize && item.price === null) {
      const itemP = sizedPrices.filter((price) => price.itemId === item.id);
      setFilteredCatSizes(
        sizes.filter((size) =>
          itemP.some((itemPrice) => size.id === itemPrice.sizeId && itemPrice.price !== 0)
        )
      );

      setItemPrices(itemP);
    }
  }, [hasSize, item.price]);

  const priceFormat = window.$priceFormater;
  // console.log("hasSize", hasSize);

  return (
    <div>
      <Dialog
       
        className="item-dialog-scroll-king"
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={screenWidth <= 450}
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* ,paddingTop: screenWidth < 550 ? "0" : "8px" */}
        <DialogContent     sx={{ padding: "0px 8px 8px 8px", overflowX: "clip" }}>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <div
           
            className="dialogTitle"
            style={{
              position: "relative",
              color: "#fff",
              height: "80px",
              background: theme.palette.primary.main,
              borderRadius: "4px",
              marginTop: "6px",
              display: "grid",
              gridTemplateColumns:
                screenWidth < 768
                  ? item.image && item.image !== ""
                    ? screenWidth > 555
                      ? "1fr 2fr 3fr"
                      : "1fr 2fr"
                    : "3fr 2fr"
                  : item.image && item.image !== ""
                  ? "1fr 4fr 3fr"
                  : "3fr 2fr",
              // screenWidth > 768
              // ? item.image && item.image !== ""
              //   ? "1fr 4fr 3fr"
              //   : "3fr 2fr"
              // : item.image && item.image !== ""
              // ? "1fr 2fr"
              // : "3fr 2fr",
            }}
          >
            {/* && screenWidth > 768 */}
            {item.image && item.image !== "" && (
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className="item-image-main"
              ></div>
            )}

            <div className="dialog-article">
              {/* {toppingImage} */}
              {/* {currItem.articleNo == null ? null : (
                  <span
                    className="flex justify-center items-center"
                    style={{
                      fontSize: ".7rem",
                      textAlign: "center",
                      background: "grey",
                      width: "50px",
                      height: "35px",

                      borderRadius: "50%",
                      marginRight: 20,
                    }}
                  >
                    {currItem.articleNo}
                  </span>

                )} */}

              <span className="dialog-name" style={{ width: "100%" }}>
                <span style={{ fontWeight: "bold" }}>
                  {currItem.articleNo !== "" && currItem.articleNo && <span>{currItem.articleNo + "."}&nbsp; </span>}
                  {currItem.name}
                </span>

                <br />
                {screenWidth < 768 ? (
                  ""
                ) : (
                  <p className="text-[0.7rem]">
                    {currItem?.description?.slice(0, 150)}
                    {currItem?.description?.length > 150 && "..."}
                  </p>
                )}
              </span>
            </div>
            {/* max-[550px]:-mt-${item.image && item.image !== "" ? 7 : 7 } */}
            {/* max-[550px]:mt-[${item.image !== null && item.image !== "" ? "-40px" : "-20px" }] */}
            <div
              style={{
                marginTop:
                  screenWidth < 550
                    ? item.image !== null && item.image !== ""
                      ? "-28px"
                      : "-15px"
                    : "0px",
              }}
              className={`sizes  `}
            >
              <div className="container-div">
                {filteredCatSizes.map((size, index) => (
                  <ItemSizeCard
                    filteredCatSizes={filteredCatSizes}
                    index={index + 1}
                    handleClick={handleClick}
                    key={size.id}
                    price={size.price}
                    name={size.name}
                    size={size}
                    height={height}
                  >
                    {itemPrices.map((price) => {
                      if (price.sizeId === size.id) {
                        return priceFormat.format(price.price);
                      } else {
                        return null;
                      }
                    })}
                  </ItemSizeCard>
                ))}
              </div>
            </div>

            <IconButton
              sx={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
              }}
              onClick={() => handleClose()}
            >
              <Close
                sx={{
                  fontSize: 26,
                  color: "white",
                }}
              />
            </IconButton>
          </div>

          {/* toppings */}
          <ItemCustomizationNew parentRef={parentRef} />
          {/* {selectedSizeId !== null ? <ItemCustomizationNew /> : null} */}
        </DialogContent>
        {/* <DialogActions >{cartVisible ? null : <CartToggle  />}</DialogActions> */}
        <DialogActions>
          <CartToggle toppingsDialog={open} setToppingsDialog={setOpen} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
