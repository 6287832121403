import { Box, Button, ButtonGroup } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customizeItem,
  resetCustomize,
  setCurrItemToppingCats,
  setSelectedSizeId,
  updateSizePrice,
} from "../../../ToolKit/Slices/ItemSlice";
import {
  iniMinLimits,
  initToppingLimit,
  resetToppings,
} from "../../../ToolKit/Slices/ToppingSlice";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

import "./ItemSizePrices.css";

// import pizzaImg from "../../../../media/pizza-size.png";

import {
  selectAreaToggle,
  setIsOfferTimeItemSelected,
  setOrderTime,
  setSelectedCategoryOfferTimes,
} from "../../../ToolKit/Slices/OrderSlice";
import { timeDialogToggle, vacationsDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import { FaCartPlus } from "react-icons/fa";
import { addToCart } from "../../../ToolKit/Slices/CartSlice";
import PriceCalculator from "./PriceCalculator";
import ItemOfferDialog from "./ItemOfferDialog";

const ItemSizePrices = (props) => {
  const {
    hasSize,
    offerItemAvailable,
    offerMessage,
    item,
    isDialog,
    setItemDialogVisible,
    itemDialogVisible,
    categoryTime,
    categoryOfferTimes,
  } = props;

  const [itemPrices, setItemPrices] = useState(null);
  const [filteredCatSizes, setFilteredCatSizes] = useState([]);
  // const [currItem, setCurrItem] = useState(useSelector((state) => state.item.currItem))
  const currItem = useSelector((state) => state.item.currItem);
  const categories = useSelector((state) => state.menu.categories);

  const sizes = useSelector((state) => state.menu.sizes);
  const sizedPrices = useSelector((state) => state.menu.prices);
  const orderType = useSelector((state) => state.cart.orderType);
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const showAreaSelection = useSelector((state) => state.order.showAreaSelection);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const isOfferTimeItemSelected = useSelector((state) => state.order.isOfferTimeItemSelected);

  const priceFormat = window.$priceFormater;
  const dispatch = useDispatch();

  const toppingAndItem = useSelector((state) => state.topping.toppingAndItem);
  const allToppingCategories = useSelector((state) => state.topping.toppingCategories);

  const vacationActiveToday = useSelector((state) => state.shop.vacationActiveToday);

  const [height, setHeight] = useState(80);
  const [itemHasTopping, setItemHasTopping] = useState(null);
  const [openItemOfferDialog, setOpenItemOfferDialog] = useState(false);
  const [chosenInterval, setChosenInterval] = useState("");
  const [currentSelectedItemInfo, setCurrentSelectedItemInfo] = useState(null);
  // const [state, setState] = useState({
  //   selectedSizeId: null,
  //   sizeName: null,
  //   sizedPrices: null,
  //   selectedSizeClass: "primary",
  //   selectedItemClass: "Item",
  //   updatedPrice: null,
  // });

  useEffect(() => {
    // check if item has toppings
    if (toppingAndItem !== null) {
      const hasTopping = toppingAndItem?.find((x) => x.itemId === item.id);
      setItemHasTopping(hasTopping);
    }
  }, [toppingAndItem]);

  useEffect(() => {
    if (filteredCatSizes.length > 3) {
      setHeight(90);
    }
  }, [filteredCatSizes]);

  useEffect(() => {
    // console.log("currItem", currItem);
    if (currItem?.id !== undefined && currItem?.id === item.id) {
      changeToppings();
    }
  }, [currItem]);

  useEffect(() => {
    if (hasSize && item.price === null) {
      const itemP = sizedPrices.filter((price) => price.itemId === item.id);
      setFilteredCatSizes(
        sizes.filter((size) => itemP.some((itemPrice) => size.id === itemPrice.sizeId))
      );

      setItemPrices(itemP);
    }
  }, [hasSize, item.price]);

  const calculatePrice = (currPrice) => {
    const { item } = props;

    // let orignalPrice = item.price;
    let orignalPrice = currPrice.price;
    let discPrice = null;

    if (!item.isDiscount) {
      if (discounts.deliveryDiscountActive && orderType === "delivery" && item.deliveryDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
      } else if (discounts.pickupDiscountActive && orderType === "pickup" && item.pickupDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
      } else {
        discPrice = null;
      }
    } else {
      if (orderType === "delivery") {
        if (item.discounts.deliveryType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.delivery;
        } else {
          discPrice = orignalPrice - item.discounts.delivery;
        }
      } else {
        if (item.discounts.pickupType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.pickup;
        } else {
          discPrice = orignalPrice - item.discounts.pickup;
        }
      }
    }
    return discPrice;
  };

  const changeToppings = () => {
    const tCatItems = toppingAndItem.filter((tItem) => tItem.itemId === currItem.id);

    let tCategories = allToppingCategories.filter((tCat) =>
      tCatItems.some((tCatItem) => tCat.tCatId === tCatItem.tCatId)
    );

    dispatch(setCurrItemToppingCats(tCategories));

    const catLimits = tCategories.map((tCat) => {
      return (({ tCatId, max, free, min }) => ({ tCatId, max, free, min }))(tCat);
    });
    const minLimCats = catLimits.filter((lim) => lim.min > 0);

    dispatch(initToppingLimit(catLimits));
    dispatch(iniMinLimits(minLimCats));
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const itemSizeClickHandler = (itemId, sizeId, sizeName) => {
    const currPrice = sizedPrices.find(
      (price) => price.sizeId === sizeId && price.itemId === itemId
    );

    dispatch(setSelectedSizeId(sizeId));

    dispatch(updateSizePrice({ itemSize: sizeName, itemPrice: currPrice.price }));
  };

  const openItemDetails = (customCurrItem, currPrice) => {
    if (itemHasTopping) {
      setItemDialogVisible(isDialog ? true : itemDialogVisible);

      dispatch(resetToppings());
    } else {
      dispatch(
        addToCart({
          item: customCurrItem,
          toppings: null,
          discPrice: calculatePrice(currPrice),
        })
      );
    }
  };

  const handleClick = (size) => {
    // is not manually closed

    if (vacationActiveToday) {
      dispatch(vacationsDialogToggle(true));
    } else {
      if (item.inStock) {
        if (!(currItem.id === item.id && currItem.size === size.name)) {
          dispatch(
            customizeItem({
              ...item,
              offerItemAvailable,
              offerMessage,
              itemHasSize: hasSize,
            })
          );

          const currPrice = sizedPrices.find(
            (price) => price.sizeId === size.id && price.itemId === item.id
          );

          const customCurrItem = {
            ...item,
            offerItemAvailable,
            offerMessage,
            itemHasSize: hasSize,
            size: size.name,
            price: currPrice.price,
          };

          setCurrentSelectedItemInfo({
            currPrice,
            customCurrItem,
          });

          if (todayTime.open && isOrderPossible) {
            // check if item has topping

            if (categories?.find((el) => el.id === item.catId)?.type === "offer") {
              if (offerItemAvailable) {
                if (
                  categoryTime === "before" &&
                  !isShopCurrentlyOpen?.status &&
                  isShopCurrentlyOpen?.message === "less"
                ) {
                  if (!isOfferTimeItemSelected) {
                    setOpenItemOfferDialog(true);
                  } else {
                    openItemDetails(customCurrItem, currPrice);
                  }
                } else if (categoryTime === "before" && isShopCurrentlyOpen?.status) {
                  if (!isOfferTimeItemSelected) {
                    setOpenItemOfferDialog(true);
                  } else {
                    openItemDetails(customCurrItem, currPrice);
                  }
                } else if (categoryTime === "between" && isShopCurrentlyOpen?.status) {

                  dispatch(setIsOfferTimeItemSelected(true));
                  dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));
                  openItemDetails(customCurrItem, currPrice);
                  showSnack(`${item.name} ${t("notify.addedToCart")} `, "success", false);
                } else if (categoryTime === "after") {
                  showSnack(
                    `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                    "info",
                    true
                  );
                } else if (categoryTime === "") {
                  openItemDetails(customCurrItem, currPrice);
                }
                // if (itemHasTopping) {
                //   setItemDialogVisible(isDialog ? true : itemDialogVisible);

                //   dispatch(resetToppings());
                // } else {
                //   dispatch(
                //     addToCart({
                //       item: customCurrItem,
                //       toppings: null,
                //       discPrice: calculatePrice(currPrice),
                //     })
                //   );
                //   showSnack(`${item.name} ${t("notify.addedToCart")} `, "success", false);
                // }
              } else {
                showSnack(
                  `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                  "info",
                  true
                );
              }
            } else {
              openItemDetails(customCurrItem, currPrice);
            }

            itemSizeClickHandler(item.id, size.id, size.name);
          } else {
            dispatch(timeDialogToggle(true));
          }
        } else {
          if (isDialog) {
            dispatch(resetCustomize());
          }
        }
      } else {
        showSnack(`${item.name} ${t("notify.isNotAvailable")}`, "warning", false);
      }
    }
  };

  const handleProceed = () => {
    dispatch(setOrderTime({ time: chosenInterval, delayed: true }));
    dispatch(setIsOfferTimeItemSelected(true));
    dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));
    setClose();

    if (itemHasTopping) {
      setItemDialogVisible(isDialog ? true : itemDialogVisible);

      dispatch(resetToppings());
    } else {
      dispatch(
        addToCart({
          item: currentSelectedItemInfo?.customCurrItem,
          // item: customCurrItem,
          toppings: null,
          discPrice: calculatePrice(currentSelectedItemInfo?.currPrice),
          // discPrice: calculatePrice(currPrice),
        })
      );

      showSnack(`${item.name} ${t("notify.addedToCart")} `, "success", false);
    }

    setItemDialogVisible(true);
  };

  const setClose = () => {
    setOpenItemOfferDialog(false);
  };

  const [itemSelectedTemp, setItemSelectedTemp] = useState(0);

  useEffect(() => {
    if (orderType !== "" && itemSelectedTemp !== 0) {
      handleClick(itemSelectedTemp);
      setItemSelectedTemp(0);
    }
  }, [orderType]);

  useEffect(() => {
    if (!showAreaSelection) {
      setItemSelectedTemp(0);
    }
  }, [showAreaSelection]);

  const ShowNamedSizes = useMemo(() => {
    return (
      <ButtonGroup
        disabled={item.inStock ? false : true}
        // disableRipple={true}
        className="relative mx-1 "
        fullWidth
        flexwrap="nowrap"
        size="small"
        // color="primary"
        color={"primary"}
        // variant="outlined"
        // className="SizePrice"
      >
        {filteredCatSizes.map((size) => (
          <>
            {itemPrices?.find((el) => el.sizeId === size.id).price !== 0 && (
              <Button
                variant={itemHasTopping ? "outlined" : "contained"}
                color={itemHasTopping ? "primary" : "secondary"}
                size="small"
                key={size.id}
                onClick={(e) => {
                  // if user has selected an orderType(delivery , pickup) then call handleClick
                  if (orderType !== "") {
                    handleClick(size);
                  } else {
                    dispatch(selectAreaToggle(true));
                    setItemSelectedTemp(size);
                  }
                }}
                sx={{ padding: "3px" }}
              >
                {itemHasTopping ? null : <FaCartPlus style={{ paddingRight: 10, fontSize: 25 }} />}
                <Box className="flex flex-col-reverse items-center justify-center h-full  ">
                  <span
                    className="w-[100%] "
                    style={{ fontSize: "12px", textTransform: "capitalize", lineHeight: "14px" }}
                  >
                    {size.name}
                  </span>
                  <span className="font-bold ">
                    {itemPrices.map((price, index2) => {
                      if (price.sizeId === size.id) {
                        return (
                          <PriceCalculator
                            item={item}
                            key={index2}
                            price={price.price}
                            toppingAmount={0}
                            priceFormat={priceFormat}
                          />
                        );
                        // return priceFormat.format(price.price);
                      } else {
                        return null;
                      }
                    })}
                  </span>
                </Box>
              </Button>
            )}
          </>
        ))}
      </ButtonGroup>
    );
  }, [itemPrices, orderType, isOrderPossible]);

  // const ShowIconSizes = () => {

  //   return filteredCatSizes.map((size, index) => {
  //     return (
  //       <ItemSizeCard
  //         filteredCatSizes={filteredCatSizes}
  //         index={index + 1}
  //         handleClick={handleClick}
  //         key={size.id}
  //         price={size.price}
  //         name={size.name}
  //         size={size}
  //         height={height}
  //       >
  //         {itemPrices.map((price) => {
  //           if (price.sizeId === size.id) {
  //             return priceFormat.format(price.price);
  //             // return "hello"
  //           } else {
  //             return null;
  //           }
  //         })}
  //       </ItemSizeCard>
  //     );
  //   });
  // };

  return (
    <>
      <div className="container-div">{ShowNamedSizes} </div>
      {openItemOfferDialog && (
        <ItemOfferDialog
          itemName={item?.name}
          handleProceed={handleProceed}
          open={openItemOfferDialog}
          setClose={setClose}
          offerMessage={offerMessage}
          categoryOfferTimes={categoryOfferTimes}
          categoryTime={categoryTime}
          setChosenInterval={setChosenInterval}
          chosenInterval={chosenInterval}
        />
      )}
    </>
  );

  // return <div className="container-div">{isDialog ? <ShowNamedSizes /> : <ShowIconSizes />}</div>;
};

export default ItemSizePrices;
