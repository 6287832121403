import React from "react";
import "./AllergyInfo.css";

import { Close, InfoOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, Popover, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";

export default function AllergyInfo(props) {
  const { infoOpen, setInfoOpen } = props;
  const { screenWidth } = useWindowDimensions();
  const { t } = useTranslation();
  const handleClose = (e) => {
    e.stopPropagation();

    setInfoOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setInfoOpen(true);
        }}
      >
        <InfoOutlined color="warning" />
      </IconButton>
      <Dialog
        fullScreen={props?.additionalInfo !== "" && screenWidth < 600}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={infoOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {t("More product information")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
          sx={{
            minWidth: "450px",
          }}
          dividers
        >
          {props.itemAllergies.length > 0 && (
            <div>
              <Typography variant="h5">{t("allergy.allergyInfo")}</Typography>
              <ul>
                {props.itemAllergies.map((a) => (
                  <li key={a.id}>
                    <b>{a.name}: </b>
                    <span>{a.detail}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {props?.additionalInfo !== "" && props?.additionalInfo !== null && (
            <div className="mt-2">
              {/* <Typography variant="h5">{t("allergy.additionalInfo")}</Typography> */}
              <div
                className="additional-info"
                dangerouslySetInnerHTML={{ __html: props?.additionalInfo }}
              ></div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
