import React from 'react'

const CartCounter = (props) => {

    return (
        <div className="ItemCounts">
            {props.itemCount ? props.itemCount : 0}
        </div>
    )
}

export default CartCounter
