import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Favorite, FavoriteBorder } from "@mui/icons-material";

import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { addFavItem, removeFavItem, userDialogToggle } from "../../../ToolKit/Slices/UserSlice";

const FavItem = (props) => {
  const { t } = useTranslation();

  const loggedIn = useSelector((state) => state.user.loggedIn);
  const currUser = useSelector((state) => state.user.currUser);
  const favItems = useSelector((state) => state.user.favItems);
  const dispatch = useDispatch();
  let itemExist = false;

  if (favItems === null || favItems === undefined) {
    itemExist = false;
  } else {
    itemExist = favItems.find((favItem) => (favItem === props.item.id ? true : false));
  }

  const showSnack = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };



  return (
    <>
      {itemExist ? (
        <Favorite
          color="primary"
          onClick={() => {
            dispatch(removeFavItem({ userId: currUser.id, itemId: props.item.id }));
            showSnack(`${props.item.name} ${t("notify.removedFromFav")}`, "success");
          }}
        />
      ) : (
        <FavoriteBorder
          color="disabled"
          onClick={
            loggedIn
              ? () => {
                  dispatch(addFavItem({ userId: currUser.id, itemId: props.item.id }));
                  showSnack(`${props.item.name} ${t("notify.addedToFav")}`, "success");
                }
              : () => {
                  dispatch(userDialogToggle({ toggle: true, userAction: "login" }));
                  showSnack(t("notify.loginToAddFav"), "info");
                }
          }
        />
      )}
    </>
  );
};

export default FavItem;
