import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputForm from "../../UI/Forms/InputForm/InputForm";
import { Button } from "@mui/material";
import { addressForm, userUpdateForm, changePassForm } from "../../UI/Forms/Forms";
import { checkValidity } from "../../UI/Forms/Validation";

import { v4 as uuidv4 } from "uuid";

import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

import { useTranslation } from "react-i18next";
import { addAddress, updateUser, changePassword } from "../../../ToolKit/Slices/UserSlice";

const AddressForm = (props) => {
  const { t } = useTranslation();

  const [userForm, setUserForm] = useState(
    props.userAction === "add"
      ? addressForm
      : props.userAction === "edit"
      ? userUpdateForm
      : changePassForm
  );
  const [formIsValid, setFormValid] = useState(false);
  const [userAction, setUserAction] = useState(props.userAction);

  const dispatch = useDispatch();

  const area = useSelector((state) => state.order.selectedArea);
  const user = useSelector((state) => state.user.currUser);

  const closeHandler = () => {
    props.closeHandler();
  };

  useEffect(() => {
    const node = document.getElementById("bottom");
    smoothScrollIntoView(node, {
      behavior: "smooth",
      block: "bottom",
      inline: "bottom",
    });
    return () => {};
  }, []);

  const addressFormHandler = (formDetails) => {
    let formData = {};
    for (let formElementIdentifier in formDetails) {
      formData[formElementIdentifier] = formDetails[formElementIdentifier].value;
    }
    formData = {
      ...formData,
      addrId: uuidv4(),
      postalCode: area.postalCode,
      city: area.area,
      userAreaId: area.id,
      selected: true,
    };
    dispatch(addAddress({ userId: user.id, formData }));
    //this.props.onRegisterUser(formData);
  };

  const userUpdateFormHandler = (formDetails) => {
    let formData = {};
    for (let formElementIdentifier in formDetails) {
      formData[formElementIdentifier] = formDetails[formElementIdentifier].value;
    }

    dispatch(updateUser(formData ));
  };

  const passwordFormHandler = (formDetails) => {
    let formData = {};
    for (let formElementIdentifier in formDetails) {
      formData[formElementIdentifier] = formDetails[formElementIdentifier].value;
    }
    dispatch(changePassword(formData));
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedForm = {
      ...userForm,
    };
    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      userAction === "changePassword" ? userForm.newPassword.value : null
    );
    updatedFormElement.touched = true;
    updatedForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    setUserForm(updatedForm);
    setFormValid(formIsValid);
  };

  const formElementsArray = [];
  for (let key in userForm) {
    formElementsArray.push({
      id: key,
      config: userForm[key],
    });
  }
  let form = (
    <form className="UserForm">
      {formElementsArray.map((formElement) => (
        <InputForm
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
    </form>
  );

  return (
    <div>
      {form}
      {userAction === "add" ? (
        <div className="UserButton" id="bottom">
          {formIsValid ? null : (
            <span style={{ fontSize: 10, color: "red" }}>
              {t("userDialog.registerError")} <br />
            </span>
          )}
          <Button
            onClick={() => {
              addressFormHandler(userForm);
              closeHandler();
            }}
            variant="contained"
            color="primary"
            disabled={!formIsValid}
          >
            {t("userProfile.add")}
          </Button>
        </div>
      ) : (
        <div className="UserButton" id="bottom">
          {formIsValid ? null : (
            <span style={{ fontSize: 10, color: "red" }}>
              {t("userDialog.registerError")} <br />
            </span>
          )}
          <Button
            // onClick={() => this.loginHandler(this.state.userForm)}
            variant="contained"
            color="primary"
            disabled={!formIsValid}
            onClick={
              userAction === "edit"
                ? () => {
                    userUpdateFormHandler(userForm);
                    closeHandler();
                  }
                : () => {
                    passwordFormHandler(userForm);
                    closeHandler();
                  }
            }
          >
            {t("userProfile.update")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddressForm;
