import { useSelector } from "react-redux";
import { useCountDownWithDate } from "../Timer/useCountDownWithDate";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const ShopOpeningTime = () => {
  const { t } = useTranslation();
  const isShopAboutToOpen = useSelector((state) => state.shop.isShopAboutToOpen);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const todayTiming = useSelector((state) => state.shop.todayTiming);

  const shopOpeningDate = useSelector((state) => state.shop.shopOpeningDate);
  const serverDate = useSelector((state) => state.shop.serverDate);
  const manuallyClosed = useSelector((state) => state.shop.manuallyClosed);
  const vacationActiveToday = useSelector((state) => state.shop.vacationActiveToday);



  const [days, hours, minutes, seconds] = useCountDownWithDate(shopOpeningDate, serverDate);

  // console.log('isShopAboutToOpen', isShopAboutToOpen)
  // startMinutes, startMessage, endingMessage, startHours, format
  //  || todayTiming?.holiday



  const isShopOpenToday = () => {
    // !todayTiming?.open this works in both the scenarios holiday and normal time
    if (manuallyClosed || vacationActiveToday || !todayTiming?.open) {
      return false;
    } else {
      return true;
    }
  };
  //  for closing
  return (
    isShopOpenToday() &&
    isShopCurrentlyOpen?.status === false &&
    isShopAboutToOpen?.shopStatus === true &&
    hours >= 0 &&
    minutes >= 0 &&
    seconds >= 0 && (
      <Box
        className={`flex gap-2 items-center justify-center bg-[#83fa83] rounded-lg shadow-md  text-black p-1 font-bold`}
      >
        <Box className="flex gap-2 items-center justify-center">
          <Typography>
            {t("Shop Opening In")}
            &nbsp;
            <span className="font-bold">
              {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
              {String(seconds).padStart(2, "0")}
            </span>
          </Typography>
        </Box>
      </Box>
    )
  );
};

export default ShopOpeningTime;
