import React from "react";

function ErrorReportHtml(props) {
  const { shop, device, runtimeError, message, contact } = props;

  return (
    <div style={{ margin: "20px" }}>
      <h3>{shop.name}</h3>
      <p>{shop.website}</p>
      <h3>{message ? "User Message:" : "Error:"}</h3>
      <p>{message ? message : runtimeError.info.componentStack}</p>
      {contact ? (
        <p>
          <b>User Contact: </b>
          {contact}
        </p>
      ) : null}
      <h3>User Device</h3>
      <ul>
        <li>OS: {device?.OS}</li>
        <li>Browser: {device?.Browser}</li>
        <li>mobile: {device?.mobile}</li>
        <li>deviceType: {device?.deviceType}</li>
        <li>viewport: {device?.viewport}</li>
        <li>info: {device?.info}</li>
      </ul>
    </div>
  );
}

export default ErrorReportHtml;
