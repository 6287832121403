import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  openEighteenPlusItemDialogOpen,
  setCartCheckout,
} from "../../../ToolKit/Slices/OrderSlice";

const DrinkDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.order.isEighteenPlusItemDialogOpen);
  const itemWithRestrictedDrink = useSelector((state) => state.order.eighteenPlusItem);
  const cartCheckout = useSelector((state) => state.order.cartCheckout);
  const handleProceed = () => {
    dispatch(setCartCheckout(!cartCheckout));
    dispatch(openEighteenPlusItemDialogOpen(false));
  };

  const handleBackToCart = () => {
    dispatch(openEighteenPlusItemDialogOpen(false));
  };

  const setClose = () => {
    dispatch(openEighteenPlusItemDialogOpen(false));
  };

  const customDialogActions = (
    <Box className="w-full flex flex-col gap-2">
      <Box className="w-full flex justify-end gap-2">
        <Button onClick={handleBackToCart} color="primary" fullWidth variant="outlined">
          {/* Save */}
          {t("button.backToCart")}
          {/* {t("Back To Cart")} */}
        </Button>
        <Button onClick={handleProceed} color="primary" fullWidth variant="contained">
          {/* Save */}
          {t("Proceed")}
        </Button>
      </Box>
    </Box>
  );

  return (
    <CustomDialog
      customSize={"sm"}
      open={open}
      setClose={setClose}
      title={t("Attention")}
      isDialogActions={true}
      customDialogActions={customDialogActions}
    >
      <Box className="p-3 flex flex-col justify-center items-center gap-4 ">
        <Typography>
          {t(
            "Your order contains at least 1 item that is not permitted to be received by minors. You must prove your age"
          )}
          &nbsp;
          {itemWithRestrictedDrink?.drinkInfo && itemWithRestrictedDrink?.drinkInfo?.minAge}+&nbsp;
          {t("with a valid ID to receive your order.")}
        </Typography>
      </Box>
    </CustomDialog>
  );
};

export default DrinkDialog;
