import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./TimingsDialog.css";

import { Close } from "@mui/icons-material";

import {
  Button,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Dialog,
  Box,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { vacationsDialogToggle } from "../../../ToolKit/Slices/ShopSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VacationsDialog() {
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const manuallyClosed = useSelector((state) => state.shop.manuallyClosed);
  const open = useSelector((state) => state.shop.vacationsDialogOpen);
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);

  const vacationsClosing = useSelector((state) => state.shop.settings.vacationsClosing);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(vacationsDialogToggle(false));
  };

  // const dateOptions = window.$dateOptions;

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="title">
          {manuallyClosed
            ? t("timingDialog.Temporarily Closed")
            : todayTime?.open
            ? !isOrderPossible
              ? t("timingDialog.closedAlready")
              : t("timingDialog.weAreNotOpenYet")
            : t("timingDialog.weAreClosedToday")}
          <IconButton
            edge="end"
            color="inherit"
            className="closeCross"
            onClick={handleClose}
            aria-label="close"
            style={{ float: "right" }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          {/* <DialogContentText>we are on vacations</DialogContentText> */}

          <Box>
            {/* <Box className="flex flex-col gap-1">
              <Typography fontWeight={"bold"}>{t("Vacation Dates")}</Typography>
              <Box className="flex items-center gap-4">
                <Typography>
                  {new Date(vacationsClosing?.startDate).toLocaleDateString("de-DE", dateOptions)}{" "}
                  <span className="mx-2">-</span>
                </Typography>
                <Typography>
                  {new Date(vacationsClosing?.endDate).toLocaleDateString("de-DE", dateOptions)}
                </Typography>
              </Box>
            </Box> */}

            <Box>
              {/* <Typography fontWeight={"bold"}>{t("Reason")} </Typography> */}
              {/* <Typography> {vacationsClosing?.message}</Typography> */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Fa-delightful-and-well-designed-banner-illustration-AKovvmeuTcm5OWvELgCKMQ-pFLDWGNsSBWZ_w-hGDVLXA.png?alt=media&token=82e62f37-f88b-4d04-b2ee-be9168fb635e"
                alt=""
              />
              <Box className="p-3">
                <div
                  className="additional-info mt-3 "
                  dangerouslySetInnerHTML={{ __html: vacationsClosing?.message }}
                ></div>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ paddingRight: 20 }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
