import { useEffect, useState } from "react";

const useCountDownWithDate = (targetDate, serverDate) => {
  const [countDown, setCountDown] = useState(
    new Date(targetDate).getTime() - new Date(serverDate).getTime()
  );
  // make sure it doesn't run always
  useEffect(() => {
    let initialTime = new Date(serverDate).getTime();
    const interval = setInterval(() => {
      //   setCountDown(countDownDate - new Date().getTime()  );
      setCountDown(new Date(targetDate).getTime() - initialTime);
      initialTime += 1000;
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountDownWithDate };
