import React from "react";

import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

const OrderTimer = (props) => {
  const { t } = useTranslation();
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return t("tracker.willArriveSoon");
    } else {
      // Render a countdown
      if (hours) {
        return (
          <span style={{ fontSize: 30 }}>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      } else {
        return (
          <span style={{ fontSize: 30 }}>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      }
    }
  };

  return <Countdown date={parseInt(props.duration, 10)} renderer={renderer} />;
};

export default OrderTimer;
