import "./CategoryNav.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoryImage from "./CategoryImage/CategoryImage";
import { AppBar, Box, SwipeableDrawer, Tab, Tabs, useTheme } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import {
  setIsCategoryManuallySelected,
  setIsCategorySideBar,
  setProgress,
  viewBestCategory,
  viewCategory,
  viewFavCategory,
  viewSearchCategory,
} from "../../ToolKit/Slices/MenuSlice";
import { resetCustomize } from "../../ToolKit/Slices/ItemSlice";
import CustomProgress from "./CustomProgress";
import { useTranslation } from "react-i18next";
import { Events, Link, scroller } from "react-scroll";
import useWindowDimensions from "../UI/ScreenSize/ScreenSize";
import ShopClosingTime from "./ShopClosingTime";

const CategoryTabs = (props) => {
  const {
    tabsRef,
    value,
    handleChange,
    selectedCat,
    setHelp,
    help,
    isCategorySideBar,
    loggedIn,
    userOptions,
    bestSellers,
    filteredCats,
    regexExp,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tabs
      selectionFollowsFocus={true}
      ref={tabsRef}
      orientation={props.orientation}
      value={value}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="primary"
      variant="scrollable"
      scrollButtons={true}
      aria-label="scrollable auto tabs example"
    >
      <Tab
        label={<Search />}
        style={{ maxWidth: "41px", minWidth: "41px" }}
        key={1111}
        value={1111}
        onClick={() => {
          if (selectedCat?.name !== "search") {
            window.scrollTo({ top: 0, behavior: "smooth" });

            dispatch(viewSearchCategory());
            dispatch(resetCustomize());
          } else {
            dispatch(resetCustomize());
            setHelp(!help);
          }

          if (isCategorySideBar) {
            dispatch(setIsCategorySideBar(false));
          }
        }}
      />
      {loggedIn
        ? userOptions.map((option, index) => (
            <Tab
              onClick={() => {
                if (selectedCat?.name === "search") {
                  dispatch(viewFavCategory());
                  setTimeout(() => {
                    scroller.scrollTo(`category-main-${123456}`, {
                      smooth: true,
                    });
                  }, 1500);
                }
              }}
              label={
                selectedCat?.name !== "search" ? (
                  <Link
                    className={`category-main-${123456} w-full h-full flex justify-center items-center px-[10px]`}
                    to={`category-main-${123456}`}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    onClick={() => {
                      if (isCategorySideBar) {
                        dispatch(setIsCategorySideBar(false));
                      }
                    }}
                    onSetActive={() => {
                      dispatch(viewFavCategory());
                    }}
                    style={{
                      color:
                        123456 === selectedCat.id
                          ? theme.palette.primary.main
                          : theme.palette.mode === "dark"
                          ? "white"
                          : "black",
                    }}
                  >
                    <div
                      className={`flex${
                        isCategorySideBar
                          ? "flex-row items-left justify-start text-left w-full"
                          : "flex-col items-center"
                      } gap-2 p-2  text-[14px]`}
                    >
                      <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                        💕
                      </span>
                      {option}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`flex${
                      isCategorySideBar
                        ? "flex-row items-left justify-start text-left w-full"
                        : "flex-col items-center"
                    } gap-2 p-2  text-[14px]`}
                  >
                    <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                      💕
                    </span>
                    {option}
                  </div>
                )
              }
              key={123456}
              value={123456}
            />
          ))
        : null}

      {bestSellers?.length > 0 ? (
        <Tab
          onClick={() => {
            if (selectedCat?.name === "search") {
              dispatch(viewBestCategory());
              setTimeout(() => {
                scroller.scrollTo(`category-main-${999}`, {
                  smooth: true,
                });
              }, 1500);
            }
            if (isCategorySideBar) {
              dispatch(setIsCategorySideBar(false));
            }
          }}
          sx={{
            p: 0,
          }}
          label={
            selectedCat?.name !== "search" ? (
              <Link
                className={`category-main-${999} w-full h-full flex justify-center items-center px-[10px]`}
                to={`category-main-${999}`}
                spy={true}
                smooth={true}
                onClick={() => {
                  if (isCategorySideBar) {
                    dispatch(setIsCategorySideBar(false));
                  }
                }}
                onSetActive={() => {
                  dispatch(viewBestCategory());
                }}
                delay={selectedCat?.name === "search" ? 1000 : 100}
                style={{
                  color:
                    999 === selectedCat.id
                      ? theme.palette.primary.main
                      : theme.palette.mode === "dark"
                      ? "white"
                      : "black",
                }}
              >
                {/* className="flex flex-col lg:flex-row items-center" */}
                <div
                  className={`flex${
                    isCategorySideBar
                      ? "flex-row items-left justify-start text-left w-full"
                      : "flex-col items-center"
                  } gap-2 p-2  text-[14px]`}
                >
                  <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                    ✨
                  </span>
                  {t("categoryNav.bestsellers")}
                </div>
              </Link>
            ) : (
              <div
                className={`flex${
                  isCategorySideBar
                    ? "flex-row items-left justify-start text-left w-full"
                    : "flex-col items-center"
                } gap-2 p-2  text-[14px]`}
              >
                <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                  ✨
                </span>
                {t("categoryNav.bestsellers")}
              </div>
            )
          }
          key={999}
          value={999}
        />
      ) : null}
      {filteredCats?.map((cat, index) => (
        <Tab
          onClick={() => {
            if (selectedCat?.name === "search") {
              dispatch(viewCategory(cat.id));
              setTimeout(() => {
                scroller.scrollTo(`category-main-${cat.id}`, {
                  smooth: true,
                });
              }, 1500);
            }
            if (isCategorySideBar) {
              dispatch(setIsCategorySideBar(false));
            }
          }}
          // key={cat.id}
          key={index}
          value={cat.id}
          sx={{
            p: 0,
          }}
          label={
            selectedCat?.name !== "search" ? (
              <Link
                className={`category-main-${cat.id} w-full h-full flex justify-center items-center px-[10px]`}
                to={`category-main-${cat.id}`}
                spy={true}
                onClick={() => {
                  if (isCategorySideBar) {
                    dispatch(setIsCategorySideBar(false));
                  }
                }}
                smooth={true}
                onSetActive={() => {
                  dispatch(viewCategory(cat.id));
                  // if (isCategorySideBar) {
                  //   dispatch(setIsCategorySideBar(false));
                  // }
                }}
                delay={selectedCat?.name === "search" ? 1000 : 100}
                style={{
                  color:
                    cat.id === selectedCat.id
                      ? theme.palette.primary.main
                      : theme.palette.mode === "dark"
                      ? "white"
                      : "black",
                }}
              >
                <div
                  className={`flex  ${
                    isCategorySideBar
                      ? "flex-row items-left justify-start text-left w-full"
                      : "flex-col items-center"
                  } gap-2 p-2  text-[14px]`}
                >
                  {regexExp.test(cat.name) ? (
                    <span role="img" aria-label="cat-thumb">
                      {cat.name.slice(0, 2)}
                    </span>
                  ) : null}
                  {cat.name.replace(regexExp, "")}
                </div>
              </Link>
            ) : (
              <div
                className={`flex${
                  isCategorySideBar
                    ? "flex-row items-left justify-start text-left w-full"
                    : "flex-col items-center"
                } gap-2 p-2  text-[14px]`}
              >
                {regexExp.test(cat.name) ? (
                  <span role="img" aria-label="cat-thumb">
                    {cat.name.slice(0, 2)}
                  </span>
                ) : null}
                {cat.name.replace(regexExp, "")}
              </div>
            )
          }
        />
      ))}
    </Tabs>
  );
};

const CategoryTable = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { screenWidth } = useWindowDimensions();
  const theme = useTheme();

  // selectors
  const totalShopCategories = useSelector((state) => state.menu.totalShopCategories);
  const categories = useSelector((state) => state.menu.categories);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const selectedCat = useSelector((state) => state.menu.selectedCategory);
  const bestSellers = useSelector((state) => state.menu.bestSellers);
  const categoryProgress = useSelector((state) => state.menu.categoryProgress);
  const isCategoryManuallySelected = useSelector((state) => state.menu.isCategoryManuallySelected);
  const items = useSelector((state) => state.menu.items);
  const offerClosed = useSelector((state) => state.user.offerClosed);
  const cartVisible = useSelector((state) => state.cart.cartVisible);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const currDateLocal = useSelector((state) => state.shop.serverDate);
  const isCategorySideBar = useSelector((state) => state.menu.isCategorySideBar);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);

  const [value, setValue] = useState(selectedCat?.id);
  const [top, setTop] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [filteredCats, setFilteredCats] = useState([]);

  useEffect(() => {
    let tempHeight = document.getElementById("mainHeader")?.offsetHeight;
    // tempHeight += 66;
    setTop(tempHeight);
  }, [screenWidth, offerClosed, cartVisible]);

  useEffect(() => {
    setValue(selectedCat?.id);
    return () => {};
  }, [selectedCat?.id]);

  useEffect(() => {
    if (isCategoryManuallySelected) {
      setTimeout(() => {
        dispatch(setIsCategoryManuallySelected(false));
      }, 1000);
    }
  }, [isCategoryManuallySelected]);

  useEffect(() => {
    if (categories?.length > 0) {
      let temp = categories.filter((cat) => {
        return getOfferTimeBoolean(cat).offerItemAvailable !== false || cat.alwaysShow !== false;
      });
      setFilteredCats(temp);
    }
  }, [categories]);

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });
  }, []);
  const tabsRef = React.useRef(null);
  const handleChange = (event, newVal) => {
    setValue(newVal);
    scrollTabToVisible(newVal);
  };
  const scrollTabToVisible = (index) => {
    if (tabsRef.current) {
      const tab = tabsRef.current.children[index];
      if (tab) {
        const scrollContainer = tabsRef.current.parentElement;
        const containerWidth = scrollContainer.offsetWidth;
        const tabOffset = tab.offsetLeft;

        // Check if the tab is not already at the start
        if (tabOffset > containerWidth) {
          const newScrollLeft = tabOffset - containerWidth;
          scrollContainer.scrollTo({ left: newScrollLeft, behavior: "smooth" });
        }
      }
    }
  };

  const handleProgress = (item) => {
    let progress = item.displayOrder;

    if (loggedIn) {
      progress = progress + 3;
    } else {
      progress = progress + 2;
    }

    dispatch(setProgress(progress));
  };

  const userOptions = [t("categoryNav.favorites")];
  // const thumbSrc = "../../media/thumbs/pizza.svg";
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

  const handleChangeCat = useCallback(
    (cat) => {
      dispatch(setIsCategoryManuallySelected(true));
      handleProgress(cat);
      dispatch(viewCategory(cat.id));
      // scroller.scrollTo(`category-main-${cat.id}`, {
      //   // duration: 1500,
      //   // delay: 100,
      //   smooth: true,
      //   // containerId: 'ContainerElementID',
      //   // offset: -100, // Scrolls to element - 100 pixels down the page
      //   // ... other options
      // });
      // dispatch(resetCustomize());
    },
    [selectedCat]
  );

  const [help, setHelp] = useState(false);
  const getOfferTimeBoolean = (currCat) => {
    let offerMessage = "";
    let offerItemAvailable = false;
    if (currCat?.type === "offer") {
      let filteredDays = null;
      let offerDate = false;
      let offerDay = false;
      let offerTime = false;
      const days = [
        t("days.Sunday"),
        t("days.Monday"),
        t("days.Tuesday"),
        t("days.Wednesday"),
        t("days.Thursday"),
        t("days.Friday"),
        t("days.Saturday"),
      ];
      filteredDays = days.filter((day, index) =>
        currCat?.offerDays.some((offerDay) => offerDay - 1 === index)
      );

      // today day
      // let temp = currDateLocal
      const d = new Date(currDateLocal);

      const today = d.getDay() + 1; //0-6

      offerDay = currCat?.offerDays.some((day) => {
        if (day === today) return true;
        else return false;
      });

      // offer days
      if (filteredDays.length > 0) {
        offerMessage += `${filteredDays.map((day) => day + " ")} `;
      }

      // offer dates
      if (currCat.offerDates !== null) {
        const startDate =
          currCat?.offerDates[0].slice(8, 10) +
          "-" +
          currCat?.offerDates[0].slice(5, 7) +
          "-" +
          currCat?.offerDates[0].slice(0, 4);
        const endDate =
          currCat?.offerDates[1].slice(8, 10) +
          "-" +
          currCat?.offerDates[1].slice(5, 7) +
          "-" +
          currCat?.offerDates[1].slice(0, 4);

        offerMessage += ` ${t("mittagMessage.from")} ${startDate} ${t(
          "mittagMessage.to"
        )} ${endDate}`;

        const offerStartDate = new Date(currCat?.offerDates[0]);
        const offerEndDate = new Date(currCat?.offerDates[1]);
        const todayDate = new Date(currDateLocal);

        if (todayDate >= offerStartDate && todayDate <= offerEndDate) {
          // offerItemAvailable = true;
          offerDate = true;
        } else {
          // offerItemAvailable = false;
          offerDate = false;
        }
      }

      // offer times
      if (currCat.offerTimes !== null) {
        offerMessage += `${t("mittagMessage.between")} ${currCat?.offerTimes?.[0]?.slice(
          0,
          5
        )} - ${currCat?.offerTimes?.[1]?.slice(0, 5)}`;
        let currTime = currTimeLocal < 1000 ? "0" + currTimeLocal : currTimeLocal;
        const offerStartTime =
          currCat?.offerTimes?.[0]?.slice(0, 2) + currCat?.offerTimes?.[0]?.slice(3, 5);
        const offerEndTime =
          currCat?.offerTimes?.[1]?.slice(0, 2) + currCat?.offerTimes?.[1]?.slice(3, 5);

        if (currTime >= offerStartTime && currTime <= offerEndTime) {
          // offerItemAvailable = true
          offerTime = true;
        } else {
          // offerItemAvailable = false
          offerTime = false;
        }
      }

      // today is offer day - true
      if (offerDay) {
        // offerDate not null in database
        if (currCat?.offerDates !== null) {
          // today is offer date - true
          if (offerDate) {
            // offerTime not null in database
            if (currCat?.offerTimes !== null) {
              // today is offer time - true
              if (offerTime) {
                offerItemAvailable = true;
              }
              // today is not offer time - false
              else {
                offerItemAvailable = false;
              }
            }
            // offerTime null in database but offer date valid
            else {
              offerItemAvailable = true;
            }
          }
          // today is not offer date - false
          else {
            offerItemAvailable = false;
          }
        } else {
          // offerTime not null in database
          if (currCat?.offerTimes !== null) {
            // today is offer time - true
            if (offerTime) {
              offerItemAvailable = true;
            }
            // today is not offer time - false
            else {
              offerItemAvailable = false;
            }
          }
          // offerTime null in database but offer day valid
          else {
            offerItemAvailable = true;
          }
        }
      } else {
        offerItemAvailable = false;
      }
    }

    return { offerMessage, offerItemAvailable };
  };
  useEffect(() => {
    const itemDefault = categories?.find((el) => el.isDefault === true);
    if (itemDefault?.id) {
      dispatch(viewCategory(itemDefault.id));
      setTimeout(() => {
        scroller.scrollTo(`category-main-${itemDefault.id}`, {
          smooth: true,
        });
      }, 2000);
    }
  }, [help]);

  // useEffect(() => {
  //   if (isCategorySideBar) {
  //     setTabsOrientation("vertical");
  //   }
  //   // if (!isCategorySideBar) {
  //   //   setTimeout(() => {
  //   //     setTabsOrientation("horizontal");
  //   //   }, 200);
  //   // } else {
  //   //   setTabsOrientation("vertical");
  //   // }
  // }, [isCategorySideBar]);
  // console.log("Hello");

  return (
    <React.Fragment>
      <div id="catImage" style={{ display: selectedCat?.name === "search" ? "none" : "unset" }}>
        <CategoryImage image={selectedCat?.image} />
      </div>

      <AppBar
        position="sticky"
        sx={{
          top: `${top}px`,
        }}
        color="inherit"
      >
        <SwipeableDrawer
          // className="min-w-[300px]"
          anchor={"left"}
          open={isCategorySideBar}
          onClose={() => {
            dispatch(setIsCategorySideBar(false));
          }}
          onOpen={() => {
            // setTabsOrientation("vertical");
          }}
          // onOpen={toggleDrawer(anchor, true)}
        >
          <Box className="min-w-[250px]">
            <CategoryTabs
              orientation={"vertical"}
              tabsRef={tabsRef}
              value={value}
              handleChange={handleChange}
              selectedCat={selectedCat}
              setHelp={setHelp}
              help={help}
              isCategorySideBar={isCategorySideBar}
              loggedIn={loggedIn}
              userOptions={userOptions}
              bestSellers={bestSellers}
              filteredCats={filteredCats}
              regexExp={regexExp}
            />
          </Box>
        </SwipeableDrawer>
        {!isCategorySideBar ? (
          <>
            <CategoryTabs
              orientation={"horizontal"}
              tabsRef={tabsRef}
              value={value}
              handleChange={handleChange}
              selectedCat={selectedCat}
              setHelp={setHelp}
              help={help}
              isCategorySideBar={isCategorySideBar}
              loggedIn={loggedIn}
              userOptions={userOptions}
              bestSellers={bestSellers}
              filteredCats={filteredCats}
              regexExp={regexExp}
            />
          </>
        ) : (
          <div className="h-[59px]" />
        )}
        {isShopCurrentlyOpen?.status === true && <ShopClosingTime />}

        {/* <Tabs
          selectionFollowsFocus={true}
          ref={tabsRef}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={<Search />}
            style={{ maxWidth: "41px", minWidth: "41px" }}
            key={1111}
            value={1111}
            onClick={() => {
              if (selectedCat.name !== "search") {
                window.scrollTo({ top: 0, behavior: "smooth" });

                dispatch(viewSearchCategory());
                dispatch(resetCustomize());
              } else {
                dispatch(resetCustomize());
                setHelp(!help);
              }
            }}
          />
          {loggedIn
            ? userOptions.map((option, index) => (
                <Tab
                  onClick={() => {
                    if (selectedCat.name === "search") {
                      dispatch(viewFavCategory());
                      setTimeout(() => {
                        scroller.scrollTo(`category-main-${123456}`, {
                          smooth: true,
                        });
                      }, 1500);
                    }
                  }}
                  label={
                    selectedCat.name !== "search" ? (
                      <Link
                        className={`category-main-${123456} w-full h-full flex justify-center items-center px-[10px]`}
                        to={`category-main-${123456}`}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        onSetActive={() => {
                          dispatch(viewFavCategory());
                        }}
                        style={{
                          color:
                            123456 === selectedCat.id
                              ? theme.palette.primary.main
                              : theme.palette.mode === "dark"
                              ? "white"
                              : "black",
                        }}
                      >
                        <div className="flex flex-col lg:flex-row items-center">
                          <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                            💕
                          </span>
                          {option}
                        </div>
                      </Link>
                    ) : (
                      <div className="flex flex-col lg:flex-row items-center">
                        <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                          💕
                        </span>
                        {option}
                      </div>
                    )
                  }
                  key={123456}
                  value={123456}
                />
              ))
            : null}

          {bestSellers.length > 0 ? (
            <Tab
              onClick={() => {
                if (selectedCat.name === "search") {
                  dispatch(viewBestCategory());
                  setTimeout(() => {
                    scroller.scrollTo(`category-main-${999}`, {
                      smooth: true,
                    });
                  }, 1500);
                }
              }}
              sx={{
                p: 0,
              }}
              label={
                selectedCat.name !== "search" ? (
                  <Link
                    className={`category-main-${999} w-full h-full flex justify-center items-center px-[10px]`}
                    to={`category-main-${999}`}
                    spy={true}
                    smooth={true}
                    onSetActive={() => {
                      dispatch(viewBestCategory());
                    }}
                    delay={selectedCat.name === "search" ? 1000 : 100}
                    style={{
                      color:
                        999 === selectedCat.id
                          ? theme.palette.primary.main
                          : theme.palette.mode === "dark"
                          ? "white"
                          : "black",
                    }}
                  >
                    <div className="flex flex-col lg:flex-row items-center">
                      <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                        ✨
                      </span>
                      {t("categoryNav.bestsellers")}
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-col lg:flex-row items-center">
                    <span role="img" aria-label="cat-thumb" style={{ fontSize: "20px" }}>
                      ✨
                    </span>
                    {t("categoryNav.bestsellers")}
                  </div>
                )
              }
              key={999}
              value={999}
            />
          ) : null}
          {filteredCats.map((cat, index) => (
            <Tab
              onClick={() => {
                if (selectedCat.name === "search") {
                  dispatch(viewCategory(cat.id));
                  setTimeout(() => {
                    scroller.scrollTo(`category-main-${cat.id}`, {
                      smooth: true,
                    });
                  }, 1500);
                }
              }}
              // key={cat.id}
              key={index}
              value={cat.id}
              sx={{
                p: 0,
              }}
              label={
                selectedCat.name !== "search" ? (
                  <Link
                    className={`category-main-${cat.id} w-full h-full flex justify-center items-center px-[10px]`}
                    to={`category-main-${cat.id}`}
                    spy={true}
                    smooth={true}
                    onSetActive={() => {
                      dispatch(viewCategory(cat.id));
                    }}
                    delay={selectedCat.name === "search" ? 1000 : 100}
                    style={{
                      color:
                        cat.id === selectedCat.id
                          ? theme.palette.primary.main
                          : theme.palette.mode === "dark"
                          ? "white"
                          : "black",
                    }}
                  >
                    <div className="flex flex-col gap-2 p-2 items-center text-[14px]">
                      {regexExp.test(cat.name) ? (
                        <span role="img" aria-label="cat-thumb">
                          {cat.name.slice(0, 2)}
                        </span>
                      ) : null}
                      {cat.name.replace(regexExp, "")}
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-col gap-2 p-2 items-center text-[14px]">
                    {regexExp.test(cat.name) ? (
                      <span role="img" aria-label="cat-thumb">
                        {cat.name.slice(0, 2)}
                      </span>
                    ) : null}
                    {cat.name.replace(regexExp, "")}
                  </div>
                )
              }
            />
          ))}
        </Tabs> */}
      </AppBar>
    </React.Fragment>
  );
};

export default CategoryTable;
