import React from "react";
import { Popover, Typography } from "@mui/material";

import { Warning } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

export default function NotAvailable() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Warning onClick={handleClick} aria-describedby={id} fontSize="small" color="error" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ padding: 2 }}>{t("item.itemNotAvailable")}</Typography>
      </Popover>
    </>
  );
}
