import { ArrowDropDown, ShoppingBagRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderItems from "../../Orders/OrderSummary/OrderItems";
import {
  calculatePrice,
  findToppingsPrice,
  getOfferTimeBoolean,
} from "../../../ToolKit/Slices/utils";
import { addToCart } from "../../../ToolKit/Slices/CartSlice";
import { timeDialogToggle, vacationsDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  selectAreaToggle,
  setIsOfferTimeItemSelected,
  setOrderTime,
  setSelectedCategoryOfferTimes,
} from "../../../ToolKit/Slices/OrderSlice";
import ItemOfferDialog from "../../ItemTable/Item/ItemOfferDialog";
import ItemDialog from "../../ItemTable/Item/ItemDialog";
import {
  iniMinLimits,
  initToppingLimit,
  resetToppings,
} from "../../../ToolKit/Slices/ToppingSlice";
import {
  customizeItem,
  setCurrItemToppingCats,
  setSelectedSizeId,
  updateSizePrice,
} from "../../../ToolKit/Slices/ItemSlice";
import PriceCalculator from "../../ItemTable/Item/PriceCalculator";
import { FaCartPlus } from "react-icons/fa";

const OrderItemsAccordion = (props) => {
  const { order } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const priceFormat = window.$priceFormater;

  const menuItems = useSelector((state) => state.menu.items);
  const menuCategories = useSelector((state) => state.menu.categories);
  const discounts = useSelector((state) => state.shop.settings?.discounts);
  const toppingAndItem = useSelector((state) => state.topping.toppingAndItem);
  const toppingSizePrices = useSelector((state) => state.topping.toppingSizePrices);
  const vacationActiveToday = useSelector((state) => state.shop.vacationActiveToday);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);
  const orderType = useSelector((state) => state.cart.orderType);
  const currTimeLocal = useSelector((state) => state.shop.serverTime);
  const currDateLocal = useSelector((state) => state.shop.serverDate);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const isOfferTimeItemSelected = useSelector((state) => state.order.isOfferTimeItemSelected);
  const sizedPrices = useSelector((state) => state.menu.prices);
  const sizes = useSelector((state) => state.menu.sizes);
  const toppingCategories = useSelector((state) => state.topping.toppingCategories);

  const [latestItems, setLatestItems] = useState(null);
  const [openItemOfferDialog, setOpenItemOfferDialog] = useState(false);
  const [chosenInterval, setChosenInterval] = useState("");
  const [selectedCategoryOfferDetails, setSelectedCategoryOfferDetails] = useState(null);
  const [itemToAdd, setItemToAdd] = useState(null);
  const [itemDialogVisible, setItemDialogVisible] = useState(false);
  const [itemHasTopping, setItemHasTopping] = useState(false);

  useEffect(() => {
    if (order) {
      //   const latestItems = items.map((singleItem) => {
      //     const latest = findLatest(singleItem);
      //     const isOffer = isOfferCategory(singleItem);

      //     return {
      //       ...singleItem,
      //       price: latest?.price,
      //       isCategoryOffer: isOffer,
      //     };
      //   });

      setLatestItems(menuItems);
    }
  }, [order]);

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const assignToppings = (item) => {
    const tCatItems = toppingAndItem.filter((tItem) => tItem.itemId === item.id);

    let tCategories = toppingCategories.filter((tCat) =>
      tCatItems.some((tCatItem) => tCat.tCatId === tCatItem.tCatId)
    );

    dispatch(setCurrItemToppingCats(tCategories));

    const catLimits = tCategories.map((tCat) => {
      return (({ tCatId, max, free, min }) => ({ tCatId, max, free, min }))(tCat);
    });
    const minLimCats = catLimits.filter((lim) => lim.min > 0);

    dispatch(initToppingLimit(catLimits));
    dispatch(iniMinLimits(minLimCats));

    dispatch(setCurrItemToppingCats(tCategories));
  };

  // const isOfferCategory = (item) => {
  //   const latestCategory = menuCategories.find(
  //     (menuCategory) => menuCategory?.id === item.catId && menuCategory?.type === "offer"
  //   );
  //   if (latestCategory) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // const findLatest = (item) => {
  //   const latestItem = menuItems.find((menuItem) => menuItem?.id === item.id);
  //   if (latestItem) {
  //     return latestItem;
  //   } else {
  //     return item;
  //   }
  // };

  const itemSizeClickHandler = (itemId, sizeId, sizeName) => {
    const currPrice = sizedPrices.find(
      (price) => price.sizeId === sizeId && price.itemId === itemId
    );

    dispatch(setSelectedSizeId(sizeId));

    dispatch(updateSizePrice({ itemSize: sizeName, itemPrice: currPrice.price }));
  };

  const openItemDialog = (item, size, customCurrItem) => {
    if (customCurrItem) {
      dispatch(
        customizeItem({
          ...customCurrItem,
          // offerItemAvailable: categoryStatus?.offerItemAvailable,

          // offerMessage: categoryStatus?.offerMessage,
          // itemHasSize: categories,
          itemHasSize: item?.price === null ? true : false,
          // itemHasSize: true,
        })
      );
      assignToppings(customCurrItem);
      // assignToppings(item);
    } else {
      dispatch(
        customizeItem({
          ...item,
          // offerItemAvailable: categoryStatus?.offerItemAvailable,

          // offerMessage: categoryStatus?.offerMessage,
          // itemHasSize: categories,
          itemHasSize: item?.price === null ? true : false,
          // itemHasSize: true,
        })
      );
      assignToppings(item);
    }
    if (size) {
      itemSizeClickHandler(item?.id, size.id, size.name);
    }
    setItemDialogVisible(true);
  };

  const addItemToCart = (itemToAdd, categoryStatus) => {
    const hasSize = itemToAdd?.price === null;

    const hasTopping = toppingAndItem?.find((x) => x.itemId === itemToAdd.id);
    setItemHasTopping(hasTopping);
    if (hasSize && hasTopping) {
      const size = sizes.find((el) => el.name === itemToAdd?.size);

      const currPrice = sizedPrices.find(
        (price) => price.sizeId === size.id && price.itemId === itemToAdd.id
      );

      const customCurrItem = {
        ...itemToAdd,
        // offerItemAvailable: categoryStatus?.offerItemAvailable,
        // offerMessage: categoryStatus?.offerMessage,
        itemHasSize: hasSize,
        size: size.name,
        price: currPrice.price,
      };
      openItemDialog(itemToAdd, size, customCurrItem);
    } else if (hasSize) {
      const size = sizes.find((el) => el.name === itemToAdd?.size);

      const currPrice = sizedPrices.find(
        (price) => price.sizeId === size.id && price.itemId === itemToAdd.id
      );

      const customCurrItem = {
        ...itemToAdd,
        // offerItemAvailable: categoryStatus?.offerItemAvailable,
        // offerMessage: categoryStatus?.offerMessage,
        itemHasSize: hasSize,
        size: size.name,
        price: currPrice.price,
      };

      openItemDialog(itemToAdd, undefined, customCurrItem);
    } else if (hasTopping) {
      const customCurrItem = {
        ...itemToAdd,
        // offerItemAvailable: categoryStatus?.offerItemAvailable,
        // offerMessage: categoryStatus?.offerMessage,
        itemHasSize: false,
        size: null,
        price: itemToAdd.price,
      };
      openItemDialog(itemToAdd, undefined, customCurrItem);
      assignToppings(itemToAdd);
      dispatch(resetToppings());
    } else {
      dispatch(
        addToCart({
          item: itemToAdd,
          toppings: null,
          discPrice: calculatePrice(itemToAdd, discounts, orderType),
          // quantity: itemToAdd?.quantity ? itemToAdd?.quantity : 1,
        })
      );
      showSnack(`${itemToAdd.name} ${t("notify.addedToCart")}`, "success", false);
    }
  };

  const setClose = () => {
    setOpenItemOfferDialog(false);
  };

  const handleProceed = () => {
    dispatch(setOrderTime({ time: chosenInterval, delayed: true }));
    dispatch(setIsOfferTimeItemSelected(true));
    dispatch(setSelectedCategoryOfferTimes(selectedCategoryOfferDetails?.categoryOfferTimes));
    addItemToCart(itemToAdd);
    setClose();
    // dispatch(
    //   addToCart({
    //     item: itemToAdd,
    //     toppings: null,
    //     discPrice: calculatePrice(itemToAdd, discounts, orderType),
    //   })
    // );
    // for single size
  };

  const handleReOrder = (singleItem) => {
    const latestItem = latestItems?.find((el) => el?.id === singleItem.id);
    console.log("old item => ", singleItem);
    console.log("newItem =>", latestItem);
    if (!latestItem) {
      showSnack(`${singleItem.name} ${t("notify.isNotAvailable")}`, "warning", false);

      return;
    }
    const itemToAdd = {
      ...latestItem,
      size: singleItem.size,
      toppings: singleItem.toppings,
    };

    const currCat = menuCategories.find((f) => f.id === itemToAdd.catId);

    const categoryStatus = getOfferTimeBoolean(currCat, currDateLocal, currTimeLocal);
    itemToAdd.offerItemAvailable = categoryStatus?.offerItemAvailable;
    itemToAdd.offerMessage = categoryStatus?.offerMessage;
    setSelectedCategoryOfferDetails(categoryStatus);
    setItemToAdd(itemToAdd);
    console.log("itemToAdd", itemToAdd);
    const { offerMessage, offerItemAvailable, categoryTime, categoryOfferTimes } = categoryStatus;
    console.log("categoryStatus", categoryStatus);
    if (vacationActiveToday) {
      dispatch(vacationsDialogToggle(true));
    } else {
      if (itemToAdd?.inStock) {
        if (todayTiming.open && isOrderPossible) {
          if (orderType !== "") {
            if (currCat.type === "offer") {
              if (offerItemAvailable) {
                if (
                  categoryTime === "before" &&
                  !isShopCurrentlyOpen?.status &&
                  isShopCurrentlyOpen?.message === "less"
                ) {
                  if (!isOfferTimeItemSelected) {
                    setOpenItemOfferDialog(true);
                  } else {
                    addItemToCart(itemToAdd);
                  }
                } else if (categoryTime === "before" && isShopCurrentlyOpen?.status) {
                  if (!isOfferTimeItemSelected) {
                    setOpenItemOfferDialog(true);
                  } else {
                    addItemToCart(itemToAdd);
                  }
                  // when shop is closed (false and less) and categoryTime is b/w
                  // it is not being handled
                  // coz this is admin's mistake
                  // categoryTime is currently active but shop is closed i-e, false
                } else if (categoryTime === "between" && isShopCurrentlyOpen?.status) {
                  dispatch(setIsOfferTimeItemSelected(true));
                  dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));
                  addItemToCart(itemToAdd);
                } else if (categoryTime === "after") {
                  showSnack(
                    `${itemToAdd.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                    "info",
                    true
                  );
                } else if (categoryTime === "") {
                  addItemToCart(itemToAdd);
                }
              } else {
                showSnack(
                  `${itemToAdd.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                  "info",
                  true
                );
              }
            } else {
              addItemToCart(itemToAdd);

              // if (hasTopping !== undefined) {
              //     assignToppings(item);
              //     dispatch(
              //       customizeItem({
              //         ...item,
              //         offerItemAvailable,
              //         offerMessage,
              //         // itemHasSize: categories,
              //         itemHasSize: hasSize,
              //         // itemHasSize: true,
              //       })
              //     );
              //     dispatch(resetToppings());
              //   } else {
              //     dispatch(
              //       addToCart({
              //         item,
              //         toppings: null,
              //         discPrice: calculatePrice(),
              //       })
              //     );
              //     showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
              //   }
            }
          } else {
            dispatch(selectAreaToggle(true));
          }
        } else {
          dispatch(timeDialogToggle(true));
        }
      } else {
        showSnack(`${itemToAdd.name} ${t("notify.isNotAvailable")}`, "warning", false);
      }
    }
  };

  const handleClick = (size) => {
    itemSizeClickHandler(itemToAdd.id, size.id, size.name);
  };

  return (
    <>
      <Accordion elevation={1} sx={{ mt: 1 }}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box className="flex items-center gap-1">
            <Typography>{t("Re Order")}</Typography>
            <Typography variant="caption">
              ({t("Prices may differ.")})
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {order?.items?.map((singleItem, key) => {
              return (
                <Box key={key} className="flex items-start gap-1">
                  <IconButton
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      "&:hover ": {
                        background: theme.palette.primary.light,
                      },
                      mt: 0.5,
                    }}
                    onClick={() => handleReOrder(singleItem)}
                  >
                    <FaCartPlus fontSize={".9rem"} />
                  </IconButton>

                  <OrderItems
                    isLast={true}
                    //   isLast={index2 !== currOrderItems[item].items.length - 1 && index}
                    key={singleItem?.id}
                    item={singleItem}
                    discounts={discounts}
                    orderType={order.orderType}
                    userDiscount={
                      order.userData?.discounts !== undefined ? order.userData?.discounts : null
                    }
                    // itemDiscount={
                    //   el2?.isDiscount ? { isDiscount: el2?.isDiscount, ...el2?.discounts } : null
                    // }
                    itemDiscount={
                      singleItem?.isDiscount
                        ? order?.userData?.itemDiscount
                          ? order.userData?.itemDiscount?.find(
                              (itemDs) => itemDs.id === singleItem.id
                            )
                          : null
                        : null
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>

      {openItemOfferDialog && (
        <ItemOfferDialog
          itemName={itemToAdd?.name}
          handleProceed={handleProceed}
          open={openItemOfferDialog}
          setClose={setClose}
          offerMessage={selectedCategoryOfferDetails?.offerMessage}
          categoryOfferTimes={selectedCategoryOfferDetails?.categoryOfferTimes}
          categoryTime={selectedCategoryOfferDetails?.categoryTime}
          setChosenInterval={setChosenInterval}
          chosenInterval={chosenInterval}
        />
      )}

      {itemDialogVisible && itemHasTopping && (
        <ItemDialog
          handleClick={handleClick}
          open={itemDialogVisible}
          setOpen={setItemDialogVisible}
          item={{
            ...itemToAdd,
            offerItemAvailable: selectedCategoryOfferDetails?.offerItemAvailable,
            offerMessage: selectedCategoryOfferDetails?.offerMessage,
          }}
          hasSize={itemToAdd?.price ? false : true}
          offerItemAvailable={selectedCategoryOfferDetails?.offerItemAvailable}
          offerMessage={selectedCategoryOfferDetails?.offerMessage}
        />
      )}
    </>
  );
};

export default OrderItemsAccordion;
