import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import UserData from "./UserData";
import UserOrders from "../UserOrders/UserOrders";

import {
  Dialog,
  AppBar,
  DialogContent,
  DialogContentText,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import "./Profile.css";

import { useTranslation } from "react-i18next";
import { deleteAccountToggle, profileToggle } from "../../../ToolKit/Slices/UserSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = () => {
  const { screenWidth } = useWindowDimensions();

  const { t } = useTranslation();

  const profileVisible = useSelector((state) => state.user.profileVisible);
  const currUser = useSelector((state) => state.user.currUser);
  const isDeleteAccount = useSelector((state) => state.user.isDeleteAccount);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(profileVisible);
  const [selection, setSelection] = useState("profile");

  const handleClose = () => {
    setOpen(false);
    dispatch(deleteAccountToggle(false));
    dispatch(profileToggle(false));
    if (window.location.pathname === "/delete-account") {
      window.location.pathname = "/";
    }
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialogTitle-root": {
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            // backgroundColor: "#eeeeee",
            boxShadow: "1px 2px 9px #737373",
            zIndex: "2",
          },
        }}
        maxWidth={"md"}
        fullWidth
        fullScreen={screenWidth < 660 ? true : false}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        // className={classes.DialogContent}
      >
        <DialogTitle sx={{ py: 1 }}>
          <Typography variant="h6">{t("userProfile.userProfile")}</Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent
          style={{
            // minWidth: screenWidth < 660 ? "350px" : "500px",
            width: "100%",
            padding: "0px",
          }}
        >
          {/* <DialogContentText id="alert-dialog-slide-description" className="OrderDoneSign">
            {t("userProfile.welcome")} {currUser?.name}
          </DialogContentText> */}
          {selection === "profile" ? (
            <div className="ProfileContainer">
              <UserData isCart={false} />
            </div>
          ) : (
            <div className="ProfileContainer">
              <UserOrders />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Profile;
