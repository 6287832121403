import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { DeleteOutline } from "@mui/icons-material";

import "./CartItem.css";
import { Divider, IconButton, useTheme } from "@mui/material";
import { ButtonGroup, Button } from "@mui/material";
import ChefNote from "./ChefNote";

import { useTranslation } from "react-i18next";

import {
  decreaseQuantity,
  increaseQuantity,
  removeCartItem,
} from "../../../ToolKit/Slices/CartSlice";

const CartItem = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // custom hook

  // const {selectedToppingCount} = useCountToppings();

  // states
  const discounts = useSelector((state) => state.shop.settings?.discounts);

  const priceFormat = window.$priceFormater;
  const { t } = useTranslation();

  const showSnack = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };

  const { item, orderType } = props;


  const [selectedToppingCount, setSelectedToppingCount] = useState([]);

  //   redux state
  const selectedToppings = useSelector((state) => state.topping.currToppings.toppings);

  // intializing temporary Array
  const tempArray = [];

  let duplicateToppings = item.toppings;

  useEffect(() => {
    // loop through each topping
    if (duplicateToppings) {
      duplicateToppings.map((element) => {
        // counting occurences of each of topping

        const tCount = duplicateToppings.filter((f) => f.tId === element.tId).length;
        // push to temporary Array
        tempArray.push({ item: element, count: tCount });
      });
    }

    // removing duplicate occurences
    const itemTIds = tempArray.map((tA) => tA.item.tId);
    const filteredArray = tempArray.filter((f, index) => !itemTIds.includes(f.item.tId, index + 1));

    // setting filteredArray to toppingCount state(local)
    setSelectedToppingCount(filteredArray);
  }, [item.toppings]);

  return (
    <div>
      <div className="CartItem">
        {/* item quantity, name and toppings */}
        <div className="ItemTopping ">
          <div className=" flex items-center">
            <span
              className="ItemQuantity w-[auto]"
              style={{
                color: theme.palette.primary.main,
              }}
            >
              {item.quantity}x
            </span>

            <span className="ItemQuantity text-left">{item.name}</span>
            {item?.drinkInfo && item?.drinkInfo?.minAge > 0 && (
              <div className=" h-[22px] w-[22px] text-[10px] text-white border border-1  flex justify-center items-center rounded-[50%] bg-[red]">
                {item?.drinkInfo?.minAge}+
              </div>
            )}
          </div>

          {item.size ? (
            <span className="CartTopping">
              <b>{item.size}</b>
            </span>
          ) : null}

          <div className="CartTopping-container">
            {item.toppings
              ? selectedToppingCount.map((topping, index) => (
                  <div key={index} className="CartTopping">
                    <div className="grid grid-cols-[12%_auto]  w-full">
                      <span className="text-left">x{topping.count}</span>
                      <span className="text-left">{topping.item.name}</span>
                    </div>
                    <span>{priceFormat.format(topping.item.price * topping.count)}</span>
                  </div>
                ))
              : null}
          </div>
        </div>
        {/*  */}
        {/* delete single item */}
        <div className="ItemOp">
          <div className="ItemPrice">
            {/* &&
            (item.deliveryDisc || item.pickupDisc) */}
            {/* item.deliveryDisc || item.pickupDisc is not available in  */}
            {item.discPrice &&
            item.totalDiscPrice !== item.totalPrice  ? (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    paddingRight: "5px",
                  }}
                >
                  {/* original price */}
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "11px",
                      color: "grey",
                    }}
                  >
                    {item.totalPrice.toFixed(2)}
                  </span>
                  {/* discount amount/percentage */}
                  <span className="ItemDiscPer">
                    -
                    {!item.isDiscount
                      ? orderType === "delivery"
                        ? discounts.deliveryDiscount
                        : discounts.pickupDiscount
                      : orderType === "delivery"
                      ? item.discounts.deliveryType === "percentage"
                        ? item.discounts.delivery + "%"
                        : item.discounts.delivery + " €"
                      : item.discounts.pickupType === "percentage"
                      ? item.discounts.pickup + "%"
                      : item.discounts.pickup + " €"}
                    {/* show percentage symbol if item has discount inactive */}
                    {!item.isDiscount ? "%" : null}
                  </span>
                </div>
                {/*  */}
                <span style={{ paddingRight: "0" }} className="ItemQuantity ">
                  {priceFormat.format(item.totalDiscPrice)}
                </span>
              </div>
            ) : (
              <div className="ItemQuantity " style={{ paddingTop: "8px" }}>
                {priceFormat.format(item.totalPrice)}
              </div>
            )}
          </div>
          <div className="ItemQ">
            <ButtonGroup variant="outlined" size="medium" aria-label="small outlined button group">
              <Button
                style={{ minWidth: "30px", fontSize: "16px", lineHeight: "1" }}
                // disabled={item.quantity === 1 ? true : false}
                onClick={() => {
                  if (item.quantity === 1) {
                    dispatch(removeCartItem(item));
                  } else {
                    dispatch(
                      decreaseQuantity({
                        id: item.cartId,
                        discount: item.discPrice ? item.discPrice : item.price + item.toppingAmount,
                        price: item.price + item.toppingAmount,
                      })
                    );
                  }
                }}
              >
                -
              </Button>
              <Button
                style={{ minWidth: "30px", fontSize: "16px", lineHeight: "1" }}
                onClick={() =>
                  dispatch(
                    increaseQuantity({
                      id: item.cartId,
                      discount: item.discPrice ? item.discPrice : item.price + item.toppingAmount,
                      price: item.price + item.toppingAmount,
                    })
                  )
                }
              >
                +
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      {/* for item size */}
      {/* for tops and del btn */}
      {/* <div className="grid grid-cols-3 place-content-start ">
     
        <div className="CartTopping-container">
          {item.toppings
            ? selectedToppingCount.map((topping, index) => (
                <div key={index} className="CartTopping">
                  <div>
                    <span className="item-count">x{topping.count}</span>
                    <span className="item-count">{topping.item.name}</span>
                  </div>
                  <span>{priceFormat.format(topping.item.price * topping.count)}</span>
                </div>
              ))
            : null}
        </div>
    
      </div> */}

      {item.chefNote ? (
        <ChefNote cartId={item.cartId} chefNote={item.chefNote ? item.chefNote : ""} />
      ) : (
        <ChefNote cartId={item.cartId} chefNote={item.chefNote ? item.chefNote : ""} />
      )}
      <Divider />
    </div>
  );
};

export default CartItem;
