import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import { useTranslation } from "react-i18next";

import "./DataPolicy-Terms.css";

import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Slide,
  LinearProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { policyDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import { useEffect } from "react";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DataPolicy = () => {
  const { screenWidth } = useWindowDimensions();

  const { t } = useTranslation();

  const shopData = useSelector((state) => state.shop.shopData);
  const policyVisible = useSelector((state) => state.shop.policyVisible);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(policyVisible);
  const [text, setText] = useState("");

  useEffect(() => {
    if (policyVisible) {
      axios.get(`${import.meta.env.VITE_API_URL}/common/terms`).then((res) => {
        setText(res.data.dataPolicy);
        setLoading(false);
      });
    }
  }, [policyVisible]);
  const handleClose = () => {
    setOpen(false);
    dispatch(policyDialogToggle(false));
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullScreen
        // fullScreen={screenWidth < 660 ? true : false}
        //
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className="DialogTitle">
          <Typography variant="h6">{t("topBar.dataPolicy")}</Typography>
          <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="DialogContent">
          {loading && <LinearProgress />}
          <div
            className="shopDataContainer"
            style={{
              margin: "0 auto",
              padding: screenWidth < 600 ? "10px" : "30px",
              columnCount: screenWidth < 600 ? 1 : 2,
              columnGap: "40px",
            }}
          >
            <h3>Verantwortliche Stelle:</h3>
            <p>
              {shopData.name}
              <br />
              {shopData.street},
              <br />
              {shopData.postalCode} {shopData.city}
              <br />
              E-Mail: {shopData.email}
            </p>
            <br />
            <div className="additional-info" dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DataPolicy;
