import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
  Avatar,
  useTheme,
  Box,
} from "@mui/material";

import Topping from "./Topping/Topping";
import SelectedToppings from "../SelectedToppings";
import { useTranslation } from "react-i18next";
import "./ToppingCategory.css";
import {
  addSingleTopping,
  addToppings,
  iniMinLimits,
  removeSingleTopping,
  removeTopping,
} from "../../../ToolKit/Slices/ToppingSlice";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useCountToppings from "../useCountToppings";

const ToppingCategory = (props) => {
  const { screenWidth } = useWindowDimensions();
  const { selectedToppingCount } = useCountToppings();
  const { tCategory } = props;

  const dispatch = useDispatch();
  const theme = useTheme();
  // local states

  const [selected, setSelected] = useState(null);
  // set intial topping size for mobile and other devices
  const initialSize = screenWidth < 550 ? 3 : 6;
  const [sliceSize, setSliceSize] = useState(initialSize);
  const [currDrink, setCurrDrink] = useState(-1);
  const ref = useRef();
  // redux states
  const allToppings = useSelector((state) => state.topping.toppings);
  const allToppingCategories = useSelector((state) => state.topping.toppingCategories);
  const minLimits = useSelector((state) => state.topping.minLimits);

  const currItemSize = useSelector((state) => state.item.currItem.size);
  const currItem = useSelector((state) => state.item.currItem);
  const sizes = useSelector((state) => state.menu.sizes);
  const toppingSizePrices = useSelector((state) => state.topping.toppingSizePrices);
  const limit = useSelector((state) => state.topping.limitCounter);
  const currToppings = useSelector((state) => state.topping.currToppings);
  const selectedToppings = useSelector((state) => state.topping.currToppings.toppings);
  const toppingAndItem = useSelector((state) => state.topping.toppingAndItem);

  const tempCurrToppings = currToppings.toppings;
  const [isMounted, setIsMounted] = React.useState(false);

  // useEffect(() => {
  //   const defaultTopping = toppings?.filter((t) => t.isDefault === true);
  //   console.log("tCategory", tCategory.max);
  //   if (
  //     defaultTopping[0] &&
  //     selected === null &&
  //     tCategory.max === 1 &&
  //     selectedToppings?.filter((el) => el.tId === defaultTopping[0].tId).length === 0
  //   ) {
  //     console.log("tCategory id->", tCategory.tCatId);
  //     if (tCategory.hasSize) {
  //       console.log("Hello from size");
  //       let tPrices = toppingSizePrices.filter((tPrice) => tPrice.sizeId === tSize?.id);
  //       dispatch(
  //         addSingleTopping({
  //           ...defaultTopping[0],
  //           price: tPrices.find((tPrice) => tPrice?.tId === defaultTopping[0].tId)
  //             ? tPrices.find((tPrice) => tPrice?.tId === defaultTopping[0].tId).price
  //             : null,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         addSingleTopping({
  //           ...defaultTopping[0],
  //           price:
  //             defaultTopping[0].price !== null &&
  //             defaultTopping[0].price !== 0 &&
  //             tCategory.free === 0
  //               ? defaultTopping[0].price
  //               : 0,
  //         })
  //       );
  //     }

  //     setSelected(defaultTopping[0].tId);
  //   }
  // }, []);

  const { t } = useTranslation();

  const priceFormat = window.$priceFormater;

  const [tSize, setTSize] = useState(
    sizes
      ?.filter((s) =>
        s.tCatId !== null && s.tCatIds === null
          ? s.tCatId === tCategory.tCatId
          : s.tCatIds?.includes(tCategory.tCatId)
      )
      ?.find((size) => size.name === currItemSize)
  );

  const [catLimit, setCatLimit] = useState(
    limit?.filter((lim) => lim.tCatId === tCategory.tCatId)[0]
  );
  const [toppingsSize, setToppingsSize] = useState(0);
  const [isHandleMore, setIsHandleMore] = useState(true);
  const [toggle, setToggle] = useState(false);

  const parentRef = useRef();
  useEffect(() => {
    setCatLimit(limit?.filter((lim) => lim.tCatId === tCategory.tCatId)[0]);
  }, [limit, tSize]);

  let toppingsDisplay = "";

  useEffect(() => {
    setTSize(
      sizes
        .filter((s) =>
          s.tCatId !== null && s.tCatIds === null
            ? s.tCatId === tCategory.tCatId
            : s.tCatIds?.includes(tCategory.tCatId)
        )
        .find((size) => size.name === currItemSize)
    );
  }, [currItemSize]);

  const [toppings, setToppings] = useState(
    allToppings?.filter(
      (topping) => topping.tCatId === tCategory.tCatId && topping.inStock === true
    )
  );

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  // const [isSticky, setIsSticky] = useState(false);
  // const stickyElementRef = useRef(null);
  // console.log("props.parentRef", props.parentRef);
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       // Check if the top of the sticky element intersects the viewport
  //       // setIsSticky(true)
  //       console.log('entry.intersectionRatio', entry.intersectionRatio)
  //       console.log('entry.boundingClientRect.top', entry.boundingClientRect.top)
  //       setIsSticky(entry.intersectionRatio < 1 && entry.boundingClientRect.top <= 0);
  //     },
  //     { threshold: [1], root: parentRef.current }
  //   );

  //   const element = stickyElementRef.current;
  //   if (element) {
  //     observer.observe(element);
  //   }

  //   return () => {
  //     if (element) {
  //       observer.unobserve(element);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log("isSticky", isSticky);
  // }, [isSticky]);

  // React.useEffect(() => {
  //   if (isMounted) {
  //     const itemDialiogScroll = document.querySelector(".item-dialog-scroll");
  //     if (itemDialiogScroll) {
  //       const computedStyle = window.getComputedStyle(itemDialiogScroll);
  //       const resilt = computedStyle.position === "sticky";
  //       console.log('resilt', resilt)
  //     }
  //     console.log("itemDialiogScroll", itemDialiogScroll);
  //     itemDialiogScroll.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (isMounted) {
  //       const itemDialiogScroll = document.querySelector(".item-dialog-scroll");
  //       itemDialiogScroll?.removeEventListener("scroll", handleScroll);
  //       setIsMounted(false)
  //     }
  //   };
  // }, [isMounted]);

  // const handleScroll = () => {
  //   console.log("User is scrolling!", window.scrollY);
  //   // Add your function logic here
  //   // if (descRef.current) {
  //   //   console.log("red");
  //   //   const rect = descRef.current.getBoundingClientRect();
  //   //   const isVisible =
  //   //     rect.top >= 0 &&
  //   //     rect.left >= 0 &&
  //   //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //   //     rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  //   //   setIsVisible(isVisible);
  //   // }
  // };

  // let toppings = [];
  // toppings = ;

  useEffect(() => {
    setToppings(
      allToppings?.filter(
        (topping) => topping.tCatId === tCategory.tCatId && topping.inStock === true
      )
    );
  }, [tCategory, tSize]);

  useEffect(() => {
    if (tCategory.hasSize) {
      let tPrices = toppingSizePrices.filter((tPrice) => tPrice.sizeId === tSize?.id);

      let newToppings = [];
      toppings.forEach((topping) => {
        newToppings.push({
          ...topping,
          price: tPrices.find((tPrice) => tPrice?.tId === topping.tId)
            ? tPrices.find((tPrice) => tPrice?.tId === topping.tId).price
            : null,
        });
      });
      setToppings([...newToppings]);
    }
  }, [tCategory.hasSize, tSize]);

  useEffect(() => {
    const defaultTopping = toppings.filter((t) => t.isDefault === true);
    const toppingData = defaultTopping[0];
    if (defaultTopping.length > 0) {
      if (tCategory.max === 1) {
        // dispatch(addSingleTopping(toppingData));
        if (tCategory.hasSize) {
          let tPrices = toppingSizePrices.filter((tPrice) => tPrice.sizeId === tSize?.id);
          dispatch(
            addSingleTopping({
              ...defaultTopping[0],
              price: tPrices.find((tPrice) => tPrice?.tId === defaultTopping[0].tId)
                ? tPrices.find((tPrice) => tPrice?.tId === defaultTopping[0].tId).price
                : null,
            })
          );
        } else {
          dispatch(
            addSingleTopping({
              ...defaultTopping[0],
              price:
                defaultTopping[0].price !== null &&
                defaultTopping[0].price !== 0 &&
                tCategory.free === 0
                  ? defaultTopping[0].price
                  : 0,
            })
          );
        }
      } else {
        dispatch(addToppings(toppingData));
      }
      setSelected(toppingData.tId);
    }
  }, [currItemSize]);

  useEffect(() => {
    let size = 0;
    toppings
      .filter((t) => t.price !== null)
      .map((t) => {
        size++;
      });
    setToppingsSize(size);
  }, [toppings]);

  const handleShowLess = () => {
    setSliceSize(initialSize);
    setIsHandleMore(true);
  };

  const handleShowMore = () => {
    // setPadSize(toppingsSize);
    setSliceSize(toppingsSize);
    setIsHandleMore(false);
  };

  const onDrinkChange = (index) => {
    setSelected((prev) => (index === prev ? null : index));
  };

  const onCheckBoxChange = (drink, cIndex, status) => {
    // this func will be called only when the user removes an already selected topping
    // the bug was in the removeSingleTopping Reducer function
    // as it was getting limitCounter from the redux which was already empty
    // so minLimits never updated when user remvoed the topping
    const tCatItems = toppingAndItem.filter((tItem) => tItem.itemId === currItem.id);

    let tCategories = allToppingCategories.filter((tCat) =>
      tCatItems.some((tCatItem) => tCat.tCatId === tCatItem.tCatId)
    );

    const catLimits = tCategories.map((tCat) => {
      return (({ tCatId, max, free, min }) => ({ tCatId, max, free, min }))(tCat);
    });
    const minLimCats = catLimits.filter((lim) => lim.min > 0);

    // if (wasToppingSelectedPreviously !== undefined && status === "remove") {
    const checkItem = selectedToppings.filter((f) => f.tId === drink.tId);

    // dispatch(removeTopping({ id: drink.tId, tCatId: drink.tCatId, count: 1 }));
    dispatch(iniMinLimits(minLimCats));
    // }
  };

  // console.log("window.scrollY", window.scrollY);

  // const defaultTopping = toppings.filter((t) => t.isDefault === true);
  // const [singleTopping, setTopping] = useState(defaultTopping[0]);

  const ShowLimitedToppings = useMemo(() => {
    // setSliceSize(padSize);

    return toppings
      .filter((t) => t.price !== null)
      .slice(0, sliceSize)
      .map((topping, index) => {
        return (
          <Topping
            tIndex={index}
            key={topping.tId}
            topping={topping}
            isSelected={tempCurrToppings.find((f) => f.tId === topping.tId)}
            toppingCount={tempCurrToppings.filter((f, i) => f.tId === topping.tId).length}
            price={tCategory.free > 0 ? 0 : topping.price}
            tCat={tCategory}
            setToggle={setToggle}
            toggle={toggle}
            limitedFreeTops={catLimit}
            isMultiple={true}
          />
        );
      });
  }, [sliceSize, tempCurrToppings, tCategory, toppings]);

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor:
          theme.palette.mode === "dark" ? theme.palette.paper : theme.palette.background.light,
      },
      children: `${name.split(" ")[0][0]}`,
      // ${name.split(" ")[1][0]}
    };
  }

  const selectedTops = (
    <div
      style={
        screenWidth < 550
          ? { position: "sticky", top: "0", zIndex: "9", width: "100%" }
          : {}
      }
      className="ExpSummary"
    >
      {/* style={{ fontSize: props.inStepper ? "1rem" : null,fontWeight: "bold" }} */}
      <div className="flex  mb-2">
        {/* tCategory.image */}
        {screenWidth > 550 &&
          // !tCategory.image  ?
          (!tCategory.image ? (
            <Avatar sx={{ bgcolor: "red" }} {...stringAvatar(tCategory?.description)} />
          ) : (
            <Avatar src={tCategory.image} />
          ))}
        <p className="ToppingCatDescription item-dialog-scroll">
          {(screenWidth < 550) & (window.scrollY > 10)
            ? tCategory.description
            : tCategory.description}
          {/* {tCategory.description} */}
          {tCategory.min > 0 ? <span> *</span> : null}

          <span style={{ marginLeft: "0.5rem", fontWeight: "bold" }}>
            {tCategory?.free > 0
              ? `(${t("toppings.free")}: ${tCategory?.free - catLimit?.free}/${tCategory?.free})`
              : // here
                null}
          </span>
        </p>
      </div>
      {/* <div className="SelectedToppingContainer">
        {!(currCat === tCategory.tCatId) ? (
          <SelectedToppings tCatId={tCategory.tCatId} toggle={toggle} setToggle={setToggle} />
        ) : null}
      </div> */}
    </div>
  );

  const handleSingleToppingClick = (topping, index) => {
    setCurrDrink(currDrink + 1);
    if (selected === topping.tId) {
      setSelected(-1);
      dispatch(
        removeSingleTopping({
          // removeTopping({
          ...topping,
          price:
            topping.price !== null && topping.price !== 0 && tCategory.free === 0
              ? topping.price
              : 0,
        })
      );
      onCheckBoxChange(topping, index);
    } else {
      // onCheckBoxChange(topping, index, "add");

      setSelected(topping.tId);
      dispatch(
        addSingleTopping({
          ...topping,
          price:
            topping.price !== null && topping.price !== 0 && tCategory.free === 0
              ? topping.price
              : 0,
        })
      );
    }
  };

  if (tCategory.max === 1 && (tCategory.min === 1 || tCategory.min === 0)) {
    toppingsDisplay = (
      <Box className={` topping-selection-required-${tCategory.tCatId} `}>
        <div>
          {/* topping-selection-required */}

          <div className={`ExpSummary  `}>
            {/* className="ToppingCatDescription" */}
            {!props.inStepper && (
              <p style={{ fontWeight: "bold" }}>
                {tCategory.description}
                {/* {tCategory.price} */}
                {tCategory.min > 0 ? <span> *</span> : null}
              </p>
            )}
          </div>
        </div>
        <div
          className="Toppings"
          ref={parentRef}
          style={{
            gap: screenWidth > 550 ? "10px" : "4px",
          }}
        >
          {/* for individual checkboxes  */}
          {toppings.map((topping, index) => {
            const price =
              topping.price !== null && topping.price !== 0 && tCategory.free === 0
                ? topping.price
                : 0;

            return (
              <Topping
                tIndex={index}
                key={topping.tId}
                topping={topping}
                isSelected={selected}
                toppingCount={tempCurrToppings.filter((f, i) => f.tId === topping.tId).length}
                price={price}
                tCat={tCategory}
                setToggle={setToggle}
                toggle={toggle}
                limitedFreeTops={catLimit}
                isMultiple={false}
                handleSingleClick={handleSingleToppingClick}
              />
            );
          })}
        </div>
        {/* 
        <FormGroup
          // className={` topping-selection-required-${tCategory.tCatId} `}
          sx={{
            display: "grid",
            gridTemplateColumns: screenWidth > 780 ? "1fr 1fr 1fr 1fr" : "1fr",
          }}
          name="controlled-radio-buttons-group"
          aria-labelledby="demo-controlled-radio-buttons-group"
          row
        >
          {toppings.map((topping, index) => {
            const price =
              topping.price !== null && topping.price !== 0 && tCategory.free === 0
                ? priceFormat.format(topping.price)
                : priceFormat.format(0);
            return (
              <FormControlLabel
                value={topping.name}
                key={index}
                // checked={index === selected}
                checked={topping.tId === selected}
                onChange={() => {
                  setCurrDrink(currDrink + 1);
                  if (selected === topping.tId) {
                    setSelected(-1);
                    dispatch(
                      removeSingleTopping({
                        // removeTopping({
                        ...topping,
                        price:
                          topping.price !== null && topping.price !== 0 && tCategory.free === 0
                            ? topping.price
                            : 0,
                      })
                    );
                    onCheckBoxChange(topping, index);
                  } else {
                    // onCheckBoxChange(topping, index, "add");

                    setSelected(topping.tId);
                    dispatch(
                      addSingleTopping({
                        ...topping,
                        price:
                          topping.price !== null && topping.price !== 0 && tCategory.free === 0
                            ? topping.price
                            : 0,
                      })
                    );
                  }
                }}
                control={<Checkbox />}
                label={topping.name + " +" + price}
              />
            );
          })}
        </FormGroup> */}
      </Box>
    );
  } else {
    toppingsDisplay = (
      <Box className={` topping-selection-required-${tCategory.tCatId} `}>
        {screenWidth > 550 ? selectedTops : null}

        <div
          className="Toppings"
          style={{
            gap: screenWidth > 550 ? "10px" : "4px",
          }}
        >
          {/* {screenWidth < 550 ? selectedTops : null} */}
          {ShowLimitedToppings}
          {/* <ShowLimitedToppings /> */}
        </div>
        {toppingsSize > 6 && screenWidth > 550 && (
          <>
            {isHandleMore ? (
              <Button
                fullWidth
                variant="outlined"
                endIcon={<KeyboardArrowDown />}
                onClick={handleShowMore}
              >
                {t("button.showMore")}
              </Button>
            ) : (
              // handleShowLess
              <Button
                fullWidth
                variant="outlined"
                endIcon={<KeyboardArrowUp />}
                onClick={handleShowLess}
              >
                {t("button.showLess")}
              </Button>
            )}
          </>
        )}
        {toppingsSize > 3 && screenWidth < 550 && (
          <>
            {isHandleMore ? (
              <Button
                fullWidth
                variant="outlined"
                endIcon={<KeyboardArrowDown />}
                onClick={handleShowMore}
                sx={{ mt: 1 }}
              >
                {t("button.showMore")}
              </Button>
            ) : (
              // handleShowLess
              <Button
                fullWidth
                variant="outlined"
                endIcon={<KeyboardArrowUp />}
                onClick={handleShowLess}
                sx={{ mt: 1 }}
              >
                {t("button.showLess")}
              </Button>
            )}
          </>
        )}
      </Box>
    );
  }

  return (
    <React.Fragment>
      <div className="ToppingCategory ">
        {/* <span>min: {tCategory.min} max: {tCategory.max} free: {tCategory.free}</span> */}
        {toppingsDisplay}
      </div>
    </React.Fragment>
  );
};

export default ToppingCategory;
