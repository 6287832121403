import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Dialoges.css";

import { HighlightOff } from "@mui/icons-material";

import styled, { keyframes } from "styled-components";
import { slideInDown } from "react-animations";

import { isWindows, isMacOs, isIOS } from "react-device-detect";
import { hideSmartBar, setSmartBar } from "../../../ToolKit/Slices/UserSlice";

const slideAnimation = keyframes`${slideInDown}`;

const SlideDiv = styled.div`
  animation: 0.5s ${slideAnimation};
`;

function MobileAppDialog() {
  const androidURL = useSelector((state) =>
    state.shop.homepage.androidURL ? state.shop.homepage.androidURL : ""
  );
  const iosURL = useSelector((state) =>
    state.shop.homepage.iosURL ? state.shop.homepage.iosURL : ""
  );

  const ShopData = useSelector((state) => state.shop.shopData);
  const manifest = ShopData?.manifest;
  const isManifestPresent = useSelector((state) => state.shop.isManifestPresent);
  const [imageSource, setImageSource] = useState("");

  const appAdActive = useSelector((state) => state.shop.appAdActive);
  const isHideSmartBar = useSelector((state) => state.user.hideSmartBar);

  useEffect(() => {
    if (!isHideSmartBar) {
      setTimeout(() => {
        
        dispatch(setSmartBar(false));
      }, 9000);
    }
  }, []);

  useEffect(() => {
    checkImageSource();
  }, [ShopData]);

  const checkImageSource = () => {
    if (ShopData) {
      if (isManifestPresent) {
        if (manifest["120xauto"] !== "") {
          setImageSource(manifest["120xauto"]);
        } else {
          setImageSource("/logos/logo.svg");
        }
      } else {
        setImageSource("/logos/logo.svg");
      }
    }
  };

  const dispatch = useDispatch();

  const appInfo = {
    imageUrl: "./logos/logo192.png",
    badge: isIOS ? "ios.png" : "play.png",
    linkUrl: isIOS ? iosURL : androidURL,
  };

  return (
    <SlideDiv className="SmartBar">
      <div className="flex justify-center items-center p-2 py-0">
        <div className="">
          <img
            style={{
              transition: "width .5s ease",
              // position: "absolute",
            }}
            // 100
            width={60}
            src={imageSource}
            alt="logo"
          />
        </div>
        <div className="flex items-center">
          <img
            src={`https://orionid-images.s3.eu-central-1.amazonaws.com/common/app/${appInfo.badge}`}
            // width="200px"
            width="160px"
            alt="play-download"
            onClick={() => window.open(appInfo.linkUrl)}
          />
          {isWindows || isMacOs ? (
            <img
              src={`https://orionid-images.s3.eu-central-1.amazonaws.com/common/app/ios.png`}
              // width="200px"
              width="160px"
              alt="play-download"
              // style={{ marginLeft: "10px" }}
              onClick={() => window.open(iosURL)}
            />
          ) : null}
        </div>
        <div className="SmartBarClose">
          <HighlightOff onClick={() => dispatch(hideSmartBar())} />
        </div>
      </div>
    </SlideDiv>
  );
}

export default MobileAppDialog;
