import React, { useCallback, useEffect, useState } from "react";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import { Box, Button, useTheme } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
  ExpressCheckoutElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

import "./stripe.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ordersContainerToggle,
  setStripeSession,
  toggleNewOrder,
  updatePaymentStatusStripe,
} from "../../../ToolKit/Slices/OrderSlice";
import { registerWithOrder } from "../../../ToolKit/Slices/UserSlice";

// const CheckoutForm = ({ clientSecret, line_items }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [errorMessage, setErrorMessage] = useState(null);
//   const onClick = ({ resolve }) => {
//     const options = {
//       line_items,
//     };
//     resolve(options);
//   };
//   const [loading, setLoading] = useState(false);

//   const [paymentRequest, setPaymentRequest] = useState(null);
//   useEffect(() => {
//     if (stripe) {
//       console.log("here");
//       const pr = stripe.paymentRequest({
//         country: "GR",
//         currency: "eur",
//         total: {
//           label: "Total",
//           amount: 2000,
//         },
//         requestPayerName: true,
//         requestPayerEmail: true,
//       });

//       pr.canMakePayment()
//         .then((result) => {
//           console.log("result", result);
//           if (result) {
//             console.log("pr", pr);
//             setPaymentRequest(pr);
//           }
//         })
//         .catch((err) => {
//           console.log("err", err);
//         });

//       pr.on("paymentmethod", async (event) => {
//         const { error } = await stripe.confirmCardPayment(
//           clientSecret,
//           {
//             payment_method: event.paymentMethod.id,
//           },
//           { handleActions: false }
//         );

//         if (error) {
//           event.complete("fail");
//         } else {
//           event.complete("success");
//           // window.location.href = "/";
//         }
//       });
//     }
//   }, [stripe]);

//   const handleError = (error) => {
//     setLoading(false);
//     setErrorMessage(error.message);
//   };
//   const onConfirm = async (event) => {
//     // We don't want to let default form submission happen here,
//     // which would refresh the page.
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js hasn't yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     const { error: submitError } = await elements.submit();
//     if (submitError) {
//       handleError(submitError);
//       return;
//     }

//     const { error } = await stripe.confirmPayment({
//       //`Elements` instance that was used to create the Payment Element
//       elements,
//       confirmParams: {
//         return_url: "https://example.com/order/123/complete",
//       },
//       clientSecret,
//       redirect: "if_required",
//     });

//     if (error) {
//       // This point will only be reached if there is an immediate error when
//       // confirming the payment. Show error to your customer (for example, payment
//       // details incomplete)
//       setErrorMessage(error.message);
//     } else {
//       console.log("event", event);
//       // Your customer will be redirected to your `return_url`. For some payment
//       // methods like iDEAL, your customer will be redirected to an intermediate
//       // site first to authorize the payment, then redirected to the `return_url`.
//     }
//   };

//   // const onConfirm = async () => {
//   //   const { error } = await stripe.confirmPayment({
//   //     // `Elements` instance that's used to create the Express Checkout Element.
//   //     elements,
//   //     // `clientSecret` from the created PaymentIntent
//   //     clientSecret,
//   //     confirmParams: {
//   //       return_url: "http://localhost:5173/",
//   //     },
//   //     // Uncomment below if you only want redirect for redirect-based payments.
//   //     // redirect: 'if_required',
//   //     // handl
//   //   });

//   //   if (error) {
//   //     // This point is reached only if there's an immediate error when confirming the payment. Show the error to your customer (for example, payment details incomplete).
//   //   } else {
//   //     // Your customer will be redirected to your `return_url`.
//   //   }
//   // };
//   // Use a traditional checkout form.
//   return (
//     <Box>
//       {/* <PaymentRequestButtonElement options={{ paymentRequest }} /> */}
//       {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
//       <PaymentElement />
//       {/* <ExpressCheckoutElement onClick={onClick} onConfirm={onConfirm} /> */}
//       <Button variant="contained" fullWidth onClick={onConfirm}>
//         Pay
//       </Button>
//       {errorMessage && <div>{errorMessage}</div>}
//     </Box>
//   );
// };

const StripeForm = (props) => {
  const { closeDialog, session } = props;
  const dispatch = useDispatch();
  const theme = useTheme();

  const currUser = useSelector((state) => state.user.currUser);
  const stripeData = useSelector((state) => state.shop.shopData?.stripe);

  useEffect(() => {
    dispatch(setStripeSession(session));
  }, []);

  const stripePromise = (async () => {
    try {
      return await loadStripe(stripeData.sandBox ? stripeData.pkTest : stripeData.pkLive);
    } catch (err) {
      console.log("err", err);
      console.error(err);
      window.location.reload();
    }
  })();

  const handleSucces = () => {
    axios
      .post(`${import.meta.env.VITE_API_URL}/stripe/get-session-data`, {
        checkoutSessionId: session.sessionId,
      })
      .then((response) => {
        if (response.data.payment_status === "paid") {
          dispatch(
            updatePaymentStatusStripe({
              orderId: session.orderId,
              payment: response.data?.payment_intent,
              status: "payed",
              currUser,
            })
          ).then((res1) => {
            closeDialog(true);
            if (session?.userData !== null) {
              dispatch(registerWithOrder(session.userData));
              dispatch(ordersContainerToggle(true));
              dispatch(toggleNewOrder(true));
            }
          });
        }
      });
  };

  const StripeTitle = () => {
    return (
      <img
        style={{
          filter: theme.palette.mode === "dark" && "contrast(115%) grayscale(100%) brightness(100)",
        }}
        className="w-[80px]"
        src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2FStripe%20wordmark%20-%20blurple%20(large).png?alt=media&token=0c1c3149-07f4-4693-a92f-9fb1c61b11f8"
        alt="Stripe Title"
      />
    );
  };
  // const options = { clientSecret: session.clientSecret };
  const options = {};
  // console.log("options", options);
  return (
    <CustomDialog
      customSize={"lg"}
      open={true}
      setClose={() => closeDialog(false)}
      title={<StripeTitle />}
    >
      <Box className="">
        {/* <Elements options={options} stripe={stripePromise}>
          <CheckoutForm line_items={session.line_items} clientSecret={session.clientSecret} />
        </Elements> */}
        <Elements options={options} stripe={stripePromise}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: session.clientSecret,
              onComplete: handleSucces,
            }}
          >
            <ExpressCheckoutElement />
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Elements>
      </Box>
    </CustomDialog>
  );
};

export default StripeForm;
