const addressForm = {
  //street,floor,city,name,email
  // leaflet: {
  //   elementType: "leaflet",
  //   elementName: "leaflet",
  //   elementConfig: {
  //     type: "text",
  //     placeholder: "Address",
  //     error: "Please enter your street name and house number",
  //   },
  //   value: "",
  //   validation: {
  //     required: true,
  //     numericRequired: true,
  //   },
  //   valid: false,
  //   touched: false,
  // },
  street: {
    elementType: "input",
    elementName: "street",
    elementConfig: {
      type: "text",
      placeholder: "Street and house number",
      error: "Please enter your street name and house number",
    },
    value: "",
    validation: {
      required: true,
      numericRequired: true,
    },
    valid: false,
    touched: false,
  },
  floor: {
    elementType: "input",
    elementName: "floor",
    elementConfig: {
      type: "text",
      placeholder: "Floor",
    },
    value: "",
    validation: {
      required: false,
    },
    valid: true,
    touched: true,
  },
  bellName: {
    elementType: "input",
    elementName: "bellName",
    elementConfig: {
      type: "text",
      placeholder: "Bell Name",
    },
    value: "",
    validation: {
      required: false,
    },
    valid: true,
    touched: true,
  },
};

const contactForm = {
  //name,email,phone
  name: {
    elementType: "input",
    elementName: "name",
    elementConfig: {
      type: "text",
      placeholder: "Full Name",
      error: "Please enter your full name",
    },
    value: "",
    validation: {
      required: true,
      isFullName: true,
    },
    valid: false,
    touched: false,
  },
  phone: {
    elementType: "input",
    elementName: "phone",
    elementConfig: {
      type: "text",
      placeholder: "Phone Number",
      error: "Please enter valid phone number",
    },
    value: "",
    validation: {
      required: true,
      minLength: 6,
      maxLength: 20,
      isNumeric: true,
    },
    valid: false,
    touched: false,
  },
  email: {
    elementType: "input",
    elementName: "email",
    elementConfig: {
      type: "email",
      placeholder: "E-Mail",
      error: "Please enter valid E-mail",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
  },
};

const optionsForm = {
  //deliveryTime,message
  deliveryTime: {
    elementType: "select",
    elementName: "deliveryTime",
    elementConfig: {
      placeholder: "Order Time?",
      default: "As soon as possible",
    },
    value: "As soon as possible",
    // value: "Choose Time",
    validation: { timeRequire: true },
    valid: true,
  },
  message: {
    elementType: "textarea",
    elementName: "message",
    elementConfig: {
      type: "textarea",
      placeholder: "Message",
    },
    value: "",
    validation: {
      required: false,
      maxLength: 500,
    },
    valid: true,
    touched: false,
  },
};

const guestForm = {
    ...addressForm, //street,floor,city
    ...contactForm, //name,phone,email
    // phone: {
    //   ...contactForm.phone,
    //   validation: {
    //     ...contactForm.phone.validation,
    //     required: false,
    //   },
    // },
    // email: {
    //   ...contactForm.email,
    //   validation: {
    //     ...contactForm.email.validation,
    //     required: false,
    //   },
    // },

    // password: {
    //     elementType: 'input',
    //     elementName: 'guestPass',
    //     elementConfig: {
    //         type: 'password',
    //         placeholder: 'Set Password',
    //         error: 'Password must be at least 6 characters'
    //     },
    //     value: '',
    //     validation: {
    //         minLength: 6,
    //         required: true,
    //     },
    //     valid: false,
    //     touched: false
    // },
    // confirmPassword: {
    //     elementType: 'input',
    //     elementName: 'guestConfirmPass',
    //     elementConfig: {
    //         type: 'password',
    //         placeholder: 'Confirm Password',
    //         error: 'Password does not match'
    //     },
    //     value: '',
    //     validation: {
    //         required: true,
    //         match: true
    //     },
    //     valid: false,
    //     touched: false
    // },
    ...optionsForm, //phone,deliveryTime,message
  },
  regOrderForm = {
    ...addressForm, //street,floor,city
    ...contactForm, //name,phone,email
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Set Password",
        error: "Password must be at least 6 characters",
      },
      value: "",
      validation: {
        minLength: 6,
        required: true,
      },
      valid: false,
      touched: false,
    },
    confirmPassword: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Confirm Password",
        error: "Password does not match",
      },
      value: "",
      validation: {
        required: true,
        match: true,
      },
      valid: false,
      touched: false,
    },
    ...optionsForm, //deliveryTime,message
  };

const guestPickupForm = {
  ...contactForm,
  // phone: {
  //   ...contactForm.phone,
  //   valid: true,
  //   validation: {
  //     ...contactForm.phone.validation,
  //     required: false,
  //   },
  // },
  // email: {
  //   ...contactForm.email,
  //   valid: true,
  //   validation: {
  //     ...contactForm.email.validation,
  //     required: false,
  //   },
  // },
  ...optionsForm,
};

const regPickupForm = {
  ...contactForm,
  password: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Set Password",
      error: "Password must be at least 6 characters",
    },
    value: "",
    validation: {
      minLength: 6,
      required: true,
    },
    valid: false,
    touched: false,
  },
  confirmPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Confirm Password",
      error: "Password does not match",
    },
    value: "",
    validation: {
      required: true,
      match: true,
    },
    valid: false,
    touched: false,
  },
  ...optionsForm,
};

const registrationForm = {
  ...addressForm,
  ...contactForm,
  password: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Set Password",
      error: "Password must be at least 6 characters",
    },
    value: "",
    validation: {
      minLength: 6,
      required: true,
    },
    valid: false,
    touched: false,
  },
  confirmPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Confirm Password",
      error: "Password does not match",
    },
    value: "",
    validation: {
      required: true,
      match: true,
    },
    valid: false,
    touched: false,
  },
};

const loginForm = {
  email: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "E-Mail",
      error: "Please enter valid E-mail",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
  },
  password: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Password",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
  },
};

const kioskForm = {
  name: {
    elementType: "input",
    elementName: "name",
    elementConfig: {
      type: "text",
      placeholder: "Full Name",
      error: "Please enter your full name",
    },
    value: "",
    validation: {
      required: true,
      isFullName: true,
    },
    valid: false,
    touched: false,
  },
};

const userUpdateForm = {
  //name,phone
  name: {
    elementType: "input",
    elementName: "name",
    elementConfig: {
      type: "text",
      placeholder: "Full Name",
      error: "Please enter your full name",
    },
    value: "",
    validation: {
      required: true,
      isFullName: true,
    },
    valid: false,
    touched: false,
  },
  phone: {
    elementType: "input",
    elementName: "phone",
    elementConfig: {
      type: "text",
      placeholder: "Phone Number",
      error: "Please enter valid phone number",
    },
    value: "",
    validation: {
      required: true,
      minLength: 6,
      maxLength: 20,
      isNumeric: true,
    },
    valid: false,
    touched: false,
  },
};

const changePassForm = {
  oldPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Old Password",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
  },
  newPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "New Password",
      error: "Password must be at least 6 characters",
    },
    value: "",
    validation: {
      minLength: 6,
      required: true,
    },
    valid: false,
    touched: false,
  },
  confirmPassword: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Confirm Password",
      error: "Password does not match",
    },
    value: "",
    validation: {
      required: true,
      match: true,
    },
    valid: false,
    touched: false,
  },
};

const forgotPassword = {
  email: {
    elementType: "input",
    elementName: "email",
    elementConfig: {
      type: "email",
      placeholder: "E-Mail",
      error: "Please enter valid E-mail",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
  },
};

export { addressForm };
export { contactForm };
export { guestForm };
export { regOrderForm };
export { loginForm };
export { registrationForm };
export { guestPickupForm };
export { regPickupForm };
export { optionsForm };
export { userUpdateForm };
export { changePassForm };
export { forgotPassword };
export { kioskForm };
