import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  LinearProgress,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

const Disclaimer = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  const getData = () => {
    axios
      .get(`${import.meta.env.VITE_API_URL}/common/terms`)
      .then((res) => {
        setText(res.data.disclaimer);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);


  return (
    <div>
      <Accordion defaultExpanded>
        {loading && <LinearProgress />}
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={"bold"}>{t("Disclaimer")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <div className="p-5">
            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                1. Informationen auf der Website:
              </Typography>
              <p>
                Die Informationen auf unserer Website dienen nur zu Informationszwecken und stellen
                keine rechtliche, finanzielle oder professionelle Beratung dar. Wir bemühen uns,
                sicherzustellen, dass die Informationen auf unserer Website korrekt und aktuell
                sind, übernehmen jedoch keine Gewähr für deren Richtigkeit, Vollständigkeit oder
                Aktualität. Jegliche Handlungen, die auf den Informationen auf unserer Website
                basieren, erfolgen auf eigenes Risiko des Nutzers.
              </p>
            </div>

            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                2. Verfügbarkeit der Website:
              </Typography>
              <p>
                Wir bemühen uns, sicherzustellen, dass unsere Website jederzeit zugänglich ist,
                können jedoch keine Gewähr für die kontinuierliche Verfügbarkeit oder die
                Unterbrechungsfreiheit der Website übernehmen. Wir behalten uns das Recht vor, den
                Zugriff auf unsere Website jederzeit und ohne Vorankündigung zu unterbrechen oder
                einzuschränken.
              </p>
            </div>

            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                3. Externe Links:
              </Typography>
              <p>
                Unsere Website kann Links zu externen Websites Dritter enthalten. Wir haben keinen
                Einfluss auf den Inhalt und die Verfügbarkeit dieser Websites und übernehmen daher
                keine Verantwortung oder Haftung für Inhalte Dritter oder für Schäden, die aus der
                Nutzung externer Links entstehen.
              </p>
            </div>

            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                4. Haftungsausschluss für Bestellungen und Lieferungen:
              </Typography>
              <p>
                Wir bemühen uns, Ihre Bestellungen sorgfältig entgegenzunehmen und die vereinbarten
                Produkte pünktlich zu liefern. Wir übernehmen jedoch keine Haftung für
                Verzögerungen, Fehler oder Schäden im Zusammenhang mit der Annahme, Bearbeitung oder
                Lieferung von Bestellungen, es sei denn, sie beruhen auf grober Fahrlässigkeit oder
                Vorsatz unsererseits
              </p>
            </div>
            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                5. Haftungsausschluss für Schäden:
              </Typography>
              <p>
                In keinem Fall haften wir für direkte, indirekte, zufällige, besondere oder
                Folgeschäden, die sich aus der Nutzung unserer Website oder aus Handlungen oder
                Unterlassungen im Zusammenhang mit der Nutzung unserer Website ergeben, es sei denn,
                dies ist gesetzlich vorgeschrieben.
              </p>
            </div>
            <div className="mb-2">
              <Typography fontSize={".9rem"} fontWeight={"bold"}>
                6. Änderungen am Haftungsausschluss:
              </Typography>
              <div>
                <p>
                  Wir behalten uns das Recht vor, diesen Haftungsausschluss jederzeit nach eigenem
                  Ermessen zu ändern oder zu aktualisieren. Die Änderungen werden auf unserer
                  Website veröffentlicht.
                </p>
                <p>
                  Wir behalten uns das Recht vor, diesen Haftungsausschluss jederzeit nach eigenem
                  Ermessen zu ändern oder zu aktualisieren. Die Änderungen werden auf unserer
                  Website veröffentlicht.
                </p>
              </div>
            </div>
          </div> */}
          <div className="additional-info" dangerouslySetInnerHTML={{ __html: text }}></div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Disclaimer;
