import React from "react";
import { useSelector } from "react-redux";

import { Paper } from "@mui/material";

import { IconContext } from "react-icons";
import { FaPaypal } from "react-icons/fa";
import { GrCreditCard } from "react-icons/gr";
import { IoIosCash } from "react-icons/io";

import { useTranslation } from "react-i18next";

import "./PaymentMethods.css";
import { CreditCard } from "@mui/icons-material";
import StripeLogo from "../../UI/StripeLogo/StripeLogo";

function PaymentMethods() {
  const paymentMethods = useSelector((state) => state.shop.paymentMethods);
  const orderType = useSelector((state) => state.cart.orderType);
  const { t } = useTranslation();






  return (
    <div className="PaymentMethods">
      {paymentMethods?.map((method, index) => {
        if (method.isActive)
          return (
            <Paper
              className="PaymentMethodsPaper2 w-[40px] min-h-0 cursor-default"
              key={index}
              elevation={0}
              value={method}
            >
              <div className="flex items-center justify-center ">
                <IconContext.Provider value={{ size: "2em" }}>
                  {method.method === "Cash on Delivery" ? (
                    <IoIosCash color="#61b585" />
                  ) : method.method === "Paypal" ? (
                    <FaPaypal color="rgb(26, 76, 197)" />
                  ) : method.method === "Stripe" ? (
                    <StripeLogo />
                  ) : (
                    <CreditCard
                      sx={{
                        fontSize: "1.9em",
                      }}
                    />
                    // <GrCreditCard
                    //   style={{
                    //     color: "white",
                    //   }}
                    // />
                  )}
                </IconContext.Provider>
              </div>
              <div style={{ textAlign: "center" }}>
                {method.method === "Cash on Delivery" ? t("checkout.cash") : method.method}
              </div>
            </Paper>
          );
      })}
    </div>
  );
}

export default PaymentMethods;
