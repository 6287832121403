import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import useWindowDimensions from "../ScreenSize/ScreenSize";

const CustomDialog = (props) => {
  const {
    open,
    setClose,
    title,
    overflowType,
    isDialogActions,
    customDialogActions,
    children,
    customSize,
    keyUp,
  } = props;
  const theme = useTheme();
  const { screenWidth } = useWindowDimensions();
  return (
    <Dialog
      onKeyDown={(event) => props.keyPress !== undefined && props.keyPress(event)}
      onKeyUp={(event) => props.keyUp !== undefined && props.keyUp(event)}
      fullWidth={props.notFullWidth !== undefined ? false : true}
      // fullWidth
      fullScreen={screenWidth <= 852}
      maxWidth={customSize ? customSize : "lg"}
      open={open}
      onClose={setClose}
    >
      <DialogTitle
        className="flex justify-between items-center"
        // color={ props.customColor !== undefined ? "white" : theme.palette.secondary.dark}
        sx={{
          py: 1,
          px: 2,
          // backgroundColor:
          //   props.customColor !== undefined
          //     ? props.customColor
          //     : "white",
          //  themeOptions.palette.secondary.dark

          borderBottom: ".5px solid lightgrey",
        }}
      >
        <Box>
          {props.breadCrumb !== undefined && props.breadCrumb}
          <Box className="flex items-center gap-2">
            {props.customIcon !== undefined && props.customIcon}
            <Typography variant="h6" fontWeight={"bold"} className="" component="div">
              {title}
            </Typography>
            {props?.isActiveComp !== undefined ? props.isActiveComp : null}
          </Box>
        </Box>
        <IconButton edge="start" color="inherit" onClick={setClose} aria-label="close">
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 1,
          my: 1,
          overflow: overflowType || "auto",
        }}
      >
        {children}
      </DialogContent>
      {isDialogActions ? (
        <DialogActions sx={{ padding: 2 }}>{customDialogActions}</DialogActions>
      ) : null}
    </Dialog>
  );
};

export default CustomDialog;
