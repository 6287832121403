import React from "react";
import { useSelector } from "react-redux";

import CartToggle from "../Cart/CartToggle/CartToggle";
import Cart from "../Cart/Cart";
import Menu from "../Menu/Menu";
import PulseLoader from "react-spinners/PulseLoader";
// import { Helmet } from "react-helmet";
import "./Home.css";

import useWindowDimensions from "../UI/ScreenSize/ScreenSize";
import { Box, Fab, useTheme } from "@mui/material";
import CartHeader from "../Cart/CartHeader/CartHeader";
import Header from "../UI/Header/Header";
import { FoodBank } from "@mui/icons-material";

const Home = () => {
  const { screenWidth, screenHeight } = useWindowDimensions();

  const cartVisible = useSelector((state) => state.cart.cartVisible);
  const shopLoading = useSelector((state) => state.shop.loading);
  const version = useSelector((state) => state.shop.shopData?.settings?.version);
  const menuLoading = useSelector((state) => state.menu.loading);
  const toppingLoading = useSelector((state) => state.topping.loading);
  const orderLoading = useSelector((state) => state.order.loading);
  // const cartVisible = useSelector((state) => state.cart.cartVisible);
  const offerMessage = useSelector((state) => state.shop.homepage.offerMessage);
  const offerClosed = useSelector((state) => state.user.offerClosed);

  const theme = useTheme();
  // const homepage = useSelector((state) => state.shop.homepage);

  return (
    <div
      style={
        {
          // marginTop:
          //   offerMessage === "" || offerMessage === undefined
          //     ? screenWidth < 1000
          //       ? "0px"
          //       : "24px"
          //     : offerClosed
          //     ? screenWidth < 1000
          //       ? "0px"
          //       : "24px"
          //     : "42px",
        }
      }
    >
      {shopLoading || menuLoading || toppingLoading || orderLoading ? (
        <div style={{ paddingTop: "144px", height: "100vh" }}>
          {/* <PulseLoader color={theme.palette.primary.main} /> */}
          <PulseLoader color={theme.palette.mode === "light" ? "#000" : "#fff"} />
        </div>
      ) : (
        <>
          {cartVisible && screenWidth < 1000 ? <CartHeader /> : <Header />}
          <div>
            {screenWidth > 1000 ? (
              <div className="AppContainer">
                <Menu />

                <Cart />
              </div>
            ) : (
              <React.Fragment>
                <div className="AppContainer" style={{ display: cartVisible ? "none" : "block" }}>
                  <Menu />
                </div>
                {cartVisible ? (
                  <div
                    className="AppContainer"
                    style={
                      {
                        // height: screenHeight,
                      }
                    }
                  >
                    <Cart />
                  </div>
                ) : null}
              </React.Fragment>
            )}
          </div>
          {screenWidth > 1000 || cartVisible ? null : <CartToggle cartToggle2={true} />}
        </>
      )}
      <div
        className="DesktopFooter"
        style={{
          color: theme.palette.mode === "dark" ? "white" : "grey",
          background: theme.palette.mode === "dark" ? theme.palette.background.paper : "#eeeeee",
        }}
      >
        {/* <Box className="flex justify-center items-center gap-4">
          <span>
            Unterstützt von <b>Orionid Software Solutions</b>
          </span>
        </Box> */}
        <span>
          Version <b>{version}</b>
        </span>
        <p>
          Alle Preise verstehen sich in Euro und sind inklusive Mehrwertsteuer. Abbildung kann vom
          original abweichen.
        </p>
      </div>
    </div>
  );
};

export default Home;
