import React from "react";

import "../../Cart/TotalCalculator/TotalCalculator.css";

import { useTranslation } from "react-i18next";

const OrderTotals = (props) => {
  const { t } = useTranslation();

  const { order } = props;

  const priceFormat = window.$priceFormater;

  return (
    <table
      style={{
        listStyle: "none",
        textAlign: "right",
        fontSize: "14px",
        width: "95%",
        margin: "auto",
        marginTop: "10px",
      }}
    >
      <tbody>
        <tr>
          <td>{t("cart.subtotal")}: </td>
          <td
            style={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "2px",
            }}
          >
            <b>{priceFormat.format(order.subTotal)}</b>
          </td>
        </tr>
        {order.voucher ? (
          <React.Fragment>
            <tr style={{ color: "#00b700" }}>
              <td>{t("cart.voucherDiscount")}: </td>
              <td
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: "2px",
                }}
              >
                <b>
                  -
                  {order.voucher.discountType === "amount"
                    ? order.voucher.discountAmount + "€"
                    : order.voucher.discountPercent + "%"}
                  {order.voucher.code}
                  
                </b>
              </td>
            </tr>
            <tr style={{ color: "#00b700" }}>
              <td style={{ padding: "2px" }}>{t("cart.afterDiscount")}</td>
              <td
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: "2px",
                }}
              >
                <b>{priceFormat.format(order.afterDiscount)}</b>
              </td>
            </tr>
          </React.Fragment>
        ) : null}
        {order.orderType === "delivery" ? (
          <tr>
            <td style={{ padding: "2px" }}>{t("cart.deliveryCharges")}: </td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: "2px",
              }}
            >
              <b>
                {order.deliveryCharges
                  ? "+" + priceFormat.format(order.deliveryCharges)
                  : t("cart.free")}
              </b>
            </td>
          </tr>
        ) : null}
        <tr>
          <td style={{ padding: "2px" }}>{t("cart.grandTotal")}: </td>
          <td
            style={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "2px",
            }}
          >
            <b>{priceFormat.format(order.grandTotal)}</b>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "2px" }}> </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderTotals;
