import React from "react";

import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { useTranslation } from "react-i18next";
import ShowTime from "./ShowTime";
import { useSelector } from "react-redux";
import Headers from "./Headers";

const TimingsTable = (props) => {
  const { t } = useTranslation();
  const separateTimings = useSelector((state) => state.shop.settings?.separateTimings);

  return (
    <>
      {separateTimings && (
        <div>
          <Headers />
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 290, overflow: "hidden", p: 0 }}
          size="small"
          aria-label="simple table"
        >
          <TableBody sx={{}}>
            {props.timings?.map((time) => {
              return (
                <TableRow
                  key={time.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: separateTimings ? "35% 30% 30%" : "30% 1fr",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <b>{t(`days.${time.day}`)}</b>
                  </TableCell>
                  {/* t("shopTimings.closed") */}

                  <TableCell sx={{ display: separateTimings ? "flex" : "none" }} align="right">
                    {time.open ? (
                      <ShowTime timings={time.pickupTimings} styleType={"col"} fontWeight={"400"} />
                    ) : (
                      t("shopTimings.closed")
                    )}
                  </TableCell>

                  <TableCell sx={{ display: separateTimings ? "flex" : "none" }} align="right">
                    {time.open ? (
                      <ShowTime
                        timings={time.deliveryTimings}
                        styleType={"col"}
                        fontWeight={"400"}
                      />
                    ) : (
                      t("shopTimings.closed")
                    )}
                  </TableCell>

                  <TableCell sx={{ display: separateTimings ? "none" : "flex" }} align="right">
                    {time.open ? (
                      <ShowTime timings={time.timings} fontWeight={"400"} />
                    ) : (
                      t("shopTimings.closed")
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TimingsTable;
