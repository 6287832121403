import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm/UserForm";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
  IconButton,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { deleteAccountToggle, userDialogToggle } from "../../../ToolKit/Slices/UserSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDialog() {
  const { screenWidth } = useWindowDimensions();
  const { t } = useTranslation();

  const dialogVisible = useSelector((state) => state.user.userDialogVisible);
  const userAction = useSelector((state) => state.user.userAction);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(dialogVisible);

  const handleClose = () => {
    setOpen(false);
    dispatch(userDialogToggle({ toggle: false, userAction: "" }));
    dispatch(deleteAccountToggle(false));
    if (window.location.pathname === "/delete-account") {
      window.location.pathname = "/";
    }
  };

  return (
    <Dialog
      fullScreen={userAction === "register" ? (screenWidth < 660 ? true : false) : false}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialogTitle-root": {
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          // backgroundColor: "#eeeeee",
          boxShadow: "1px 2px 9px #737373",
          zIndex: "2",
        },
      }}
    >
      <DialogTitle sx={{ py: 1, px: 2, bgColor: "background.default" }}>
        <Typography variant="h6">
          {userAction === "login"
            ? t("userDialog.login")
            : userAction === "register"
            ? t("userDialog.register")
            : t("userDialog.forgetPassword")}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            mt: 2,
          }}
        >
          {userAction === "login" ? (
            t("userDialog.loginMessage")
          ) : userAction === "register" ? (
            t("userDialog.registerMessage")
          ) : (
            <React.Fragment>
              <b>{t("userDialog.forgotPasswordNoProblem")}</b>
              <br />
              <br />
              {t("userDialog.emailWithTemporaryPasswordWillSent")}
            </React.Fragment>
          )}
        </DialogContentText>
        <UserForm />
      </DialogContent>
    </Dialog>
  );
}
