import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./OrderContainer.css";

import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";
import UserOrders from "../../Users/UserOrders/UserOrders";
import OrderPaper from "../OrderSummary/OrderPaper";

import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Slide,
  Button,
  Icon,
  useTheme,
} from "@mui/material";

import { Close, LocalMallSharp, Refresh } from "@mui/icons-material";

// import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
import OrderSummary from "../OrderSummary/OrderSummary";
import PulseLoader from "react-spinners/PulseLoader";
import { IoMdReturnLeft } from "react-icons/io";
import { ordersContainerToggle } from "../../../ToolKit/Slices/OrderSlice";
const slideAnimation = keyframes`${pulse}`;

const SlideDiv = styled.div`
  animation: 0.6s ${slideAnimation};
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdersContainer = () => {
  const { screenWidth } = useWindowDimensions();
  const { t } = useTranslation();

  const currLiveOrder = useSelector((state) => state.order.currOrder);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const newOrderPlaced = useSelector((state) => state.order.newOrderPlaced);
  const shop = useSelector((state) => state.shop.shopData);
  const [currOrderLocal, setCurrOrderLocal] = useState({});

  const serverTime = useSelector((state) => state.shop.serverTime);

  const orderError = useSelector((state) => state.order.error);
  const isManifestPresent = useSelector((state) => state.shop.isManifestPresent);

  const manifest = shop?.manifest;

  const logoSrc = `https://${shop.website}/logos/o_logo.png`;
  const shopLogo = isManifestPresent
    ? manifest["100x100"] !== ""
      ? manifest["100x100"]
      : manifest["120xauto"] !== ""
      ? manifest["120xauto"]
      : logoSrc
    : logoSrc;
  // console.log('shopLogo', shopLogo)
  useEffect(() => {
    setCurrOrderLocal(currLiveOrder);
  }, [currLiveOrder]);

  const ordersContainerVisible = useSelector((state) => state.order.ordersContainerVisible);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(ordersContainerVisible);
  const [showSummary, setShowSummary] = useState(false);
  const loading = useSelector((state) => state.order.loading);

  const handleClose = () => {
    if (currOrderLocal) {
      // socket.emit("leave", currOrder.id);
    }
    setOpen(false);
    dispatch(ordersContainerToggle(false));
  };

  useEffect(() => {
    if (newOrderPlaced) {
      setShowSummary(true);
      // enqueueSnackbar(t("Order has been sent successfully!"), { variant: "success" });
    }
    if (orderError !== null) {
      setShowSummary(true);
      // enqueueSnackbar(t("Order Sending Failed!"), { variant: "error" });
    }
    return () => {};
  }, [newOrderPlaced]);

  const categories = useSelector((state) => state.menu.categories);

  let currOrderCustomized = {};

  if (currLiveOrder !== null) {
    currLiveOrder?.items?.forEach((el) => {
      let category = categories?.find((el2) => el2.id === el.catId);
      if (category) {
        if (category.id + "" in currOrderCustomized) {
          currOrderCustomized = {
            ...currOrderCustomized,
            [category.id]: {
              ...currOrderCustomized[category.id],
              items: [...currOrderCustomized[category.id].items, el],
            },
          };
        } else {
          currOrderCustomized = {
            ...currOrderCustomized,
            [category.id]: {
              value: category,
              items: [el],
            },
          };
        }
      }
    });
  }

  const now = serverTime;

  const theme = useTheme();
  return (
    <div>
      <Dialog
        fullScreen={screenWidth < 660 ? true : false}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        // className={classes.DialogContent}
      >
        {/* <OrderTable currOrder={currOrder} shop={shop} discounts={discounts} /> */}
        <div className="appBar">
          <div className="orders">
            <div>
              {showSummary ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    // socket.emit("leave", currOrder.id);
                    setShowSummary(false);
                  }}
                  // startIcon={<IoMdReturnLeft />}
                >
                  {t("button.goBack")}
                </Button>
              ) : (
                <Icon edge="start" color="inherit" style={{ height: "unset" }}>
                  <LocalMallSharp />
                </Icon>
              )}
              <Typography
                variant="h6"
                // className={classes.title}
              >
                {showSummary ? null : t("userProfile.orders")}
              </Typography>
              <IconButton>
                <Refresh />
              </IconButton>
            </div>
            <IconButton
              sx={{ mr: 1 }}
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <DialogContent
          style={{
            minWidth: "350px",
            // backgroundColor: "#eeeeee",
            padding: "10px",
          }}
        >
          {loading ? (
            <div style={{ alignSelf: "center", textAlign: "center", padding: 30 }}>
              <PulseLoader color={theme.palette.primary.main} />
              <p>{t("orderSummary.pleaseWait")}</p>
            </div>
          ) : (
            <div>
              {showSummary ? (
                <div>
                  {orderError === null ? (
                    currOrderLocal?.status === "completed" ||
                    now - new Date(currOrderLocal?.orderTimestamp).getTime() > 64800000 ? (
                      // only pops when order is completed
                      <OrderPaper
                        currOrder={currOrderLocal}
                        currOrderItems={currOrderCustomized}
                        userDiscount={
                          currOrderLocal?.userData?.discounts !== undefined
                            ? currOrderLocal?.userData?.discounts
                            : null
                        }
                        shop={shop}
                        discounts={discounts}
                        shopLogo={shopLogo}
                      />
                    ) : (
                      <OrderSummary shopLogo={shopLogo} currOrderCustomized={currOrderCustomized} />
                    )
                  ) : (
                    <OrderSummary shopLogo={shopLogo} currOrderCustomized={currOrderCustomized} />
                  )}
                  {/* <OrderSummary /> */}
                </div>
              ) : null}

              <div style={showSummary ? { display: "none" } : { display: "block" }}>
                {/* <h1>HI this is user orders</h1> */}
                <UserOrders
                  showSummary={showSummary}
                  setShowSummary={setShowSummary}
                  setCurrOrderLocal={setCurrOrderLocal}
                  currOrder={currLiveOrder}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrdersContainer;
