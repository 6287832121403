import React from "react";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";

import "./CartHeader.css";
import { ButtonGroup, Button, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { showCartToggle } from "../../../ToolKit/Slices/CartSlice";

const CartHeader = (props) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <Box
      className="CartHeader"
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <span className="CartLanguageButtons">
        <ButtonGroup size="small">
          <Button
            style={{ color: i18n.language === "de" ? theme.palette.primary.main : "lightgrey" }}
            onClick={() => {
              i18n.changeLanguage("de");
            }}
          >
            DE
          </Button>
          <Button
            style={{ color: i18n.language === "en" ? theme.palette.primary.main : "lightgrey" }}
            onClick={() => {
              i18n.changeLanguage("en");
            }}
          >
            EN
          </Button>
        </ButtonGroup>
      </span>
      <div className="CartCross">
        <Close
          fontSize="large"
          style={{ float: "right" }}
          onClick={() => dispatch(showCartToggle())}
        />
      </div>
    </Box>
  );
};

export default CartHeader;
