import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setProgress } from "../../ToolKit/Slices/MenuSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const CustomProgress = (props) => {
  const { value } = props;

  const dispatch = useDispatch();

  const selectedCategory = useSelector((state) => state.menu.selectedCategory);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const isCategoryManuallySelected = useSelector((state) => state.menu.isCategoryManuallySelected);

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.name === "best") {
        if (loggedIn) {
          dispatch(setProgress(2));
        } else {
          dispatch(setProgress(1));
        }
      } else if (selectedCategory.name === "favorites") {
        dispatch(setProgress(1));
      } else {
        let progress = 0;
        progress = selectedCategory?.displayOrder;
        if (loggedIn) {
          progress = progress + 3;
        } else {
          progress = progress + 2;
        }

        dispatch(setProgress(progress));
      }
    }
  }, []);

  useEffect(() => {

    if (!isCategoryManuallySelected) {
      if (selectedCategory.name === "best") {
        if (loggedIn) {
          dispatch(setProgress(2));
        } else {
          dispatch(setProgress(1));
        }
      } else if (selectedCategory.name === "favorites") {
        dispatch(setProgress(1));
      } else {
        let progress = selectedCategory.displayOrder;
        if (loggedIn) {
          progress = progress + 3;
        } else {
          progress = progress + 2;
        }



        dispatch(setProgress(progress));
      }
    }
  }, [selectedCategory]);
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        variant="determinate"
        value={value}
      />
    </Box>
  );
};

export default CustomProgress;
