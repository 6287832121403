import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// importing slice actions
import { addNotification } from "./NotifierSlice";
import { selectArea, setIncompleteOrdersCount, setLocalOrders } from "./OrderSlice";
import { setDeliveryCharge } from "./CartSlice";
import jwtDecode from "jwt-decode";
import i18next, { t } from "i18next";

// initial state
export const initialState = {
  userDialogVisible: false,
  profileVisible: false,
  userAction: "",

  currUser: null,
  addresses: null,
  favItems: null,
  orders: [],

  isLoading: null,
  error: null,
  snackMsg: null,
  userOrderLoading: false,

  userDevice: null,

  loggedIn: false,
  feedbackSent: false,
  cookieAccepted: false,
  offerClosed: false,
  changeLoading: false,

  viewSmartBar: false, //for delayed toggle
  hideSmartBar: false,
  notificationPermission: "default",
  // notificationPermission: "ask",

  addingNewAddress: false,
  theme: "light",
  isUserTheme: false,
  isDeleteAccount: false,
};

// #################  Async Thunks ###############

// 1. FOR REGISTER USER

export const registerUser = createAsyncThunk("/user/register", async (formData, thunkApi) => {
  const { dispatch } = thunkApi;
  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/register`, formData);

    if (response.status === 200) {
      const token = response.data.token;
      const userData = response.data.user;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem("token", token);

      dispatch(
        addNotification({
          key: 1,
          message: "Registration Complete",
          type: "success",
        })
      );
      dispatch(userDialogToggle({ toggle: false, userAction: "" }));
      return userData;
    }

    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        key: 1,
        message: "Registration Failed:" + error.response.data,
        type: "error",
      })
    );
    dispatch(userDialogToggle({ toggle: false, userAction: "" }));

    return thunkApi.rejectWithValue(error.response.data);
  }
});

//  2.   FOR LOGIN-USER

// Note :- Some imports remaining ... selectAddressLocal onwards ...
export const loginUser = createAsyncThunk(
  "/user/login",
  async ({ formDetails, areas, deliveryON, orderType }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const userDataReq = {
        email: formDetails.email.value,
        password: formDetails.password.value,
      };

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/login`, userDataReq);

      if (response.status === 200) {
        const token = response.data.token;
        const userData = response.data.user;

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        localStorage.setItem("token", token);
        // const userData = decoded.userData;

        if (userData.addresses[0].street && deliveryON) {
          const selectedAdd = userData.addresses.find((a) => a.selected);
          dispatch(
            selectAddressOnLogin({
              addrId: selectedAdd.addrId,
              addresses: userData.addresses,
            })
          );

          dispatch(selectArea(areas.find((area) => area.id === selectedAdd.userAreaId)));

          if (orderType === "delivery") {
            dispatch(
              setDeliveryCharge(
                areas.find((area) => area.id === selectedAdd.userAreaId)?.deliveryCharges
              )
            );
          }

          let addresses = userData.addresses;
          let selectedAddress = addresses.find((address) => address.selected === true);
          // console.log("selectedAddress", selectedAddress);
          if (selectedAddress) {
            // if user saved address is available in db
            // console.log("areas", areas);
            let selectedAddressAvailable = areas?.find(
              (area) => area.id === selectedAddress?.userAreaId
            );
            // console.log("selectedAddressAvailable", selectedAddressAvailable);

            if (selectedAddressAvailable === undefined || !selectedAddressAvailable?.active) {
              dispatch(
                addNotification({
                  key: 2,
                  message: `${t("Delivery not available at this time")} ${selectedAddress?.city}, ${
                    selectedAddress?.postalCode
                  }.`,
                  type: "error",
                  persist: true,
                })
              );
              // showSnack(
              //   `${selectedAddress?.city}, ${selectedAddress?.postalCode}. ${t(
              //     "Delivery not available at this time"
              //   )}`,
              //   "error",
              //   true
              // );
            }
          }

          dispatch(
            addNotification({
              key: 1,
              message: userData.name,
              type: "info",
              withName: true,
            })
          );
        }
      }

      return response.data;
    } catch (error) {
      // dispatch(setLocalOrders([]));
      console.log("rejected ", error);
      dispatch(
        addNotification({
          key: 1,
          message: "Incorrect Email or Password!",
          type: "error",
        })
      );
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

//  3.   FECTCH ORDERS
// imports remaining
export const fetchUserOrders = createAsyncThunk(
  "/user/get_orders",
  async ({ currUser, localOrders }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const orderIds = localOrders.map((o) => o.id);

      if (currUser) {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/user/get_orders`);

        if (response.status === 200) {
          dispatch(setUserOrders({ orders: response.data }));
          const incompleteOrders = response.data.filter((o) =>
            o
              ? o.status !== "completed" &&
                o.status !== "ready" &&
                new Date().getTime() - new Date(o.orderTimestamp).getTime() < 64800000 &&
                (o.paymentStatus === null || o.paymentStatus === "payed") // 64800000 miliseconds = 18 hours
              : false
          );
          dispatch(setIncompleteOrdersCount({ incOrders: incompleteOrders.length }));
        }

        return response.data;
      }
      if (orderIds.length > 0 && currUser === null) {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/user/get_orders`);

        if (response.status === 200) {
          dispatch(setLocalOrders(response.data));
          dispatch(setUserOrders({ orders: response.data }));
          const incompleteOrders = response.data.filter((o) =>
            o
              ? o.status !== "completed" &&
                o.status !== "ready" &&
                new Date().getTime() - new Date(o.orderTimestamp).getTime() < 64800000 // 64800000 miliseconds = 18 hours
              : false
          );
          dispatch(setIncompleteOrdersCount({ incOrders: incompleteOrders.length }));

          return response.data;
        }
        return response.data;
      }
    } catch (error) {
      console.log("error", error);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 4. For Change Password

export const changePassword = createAsyncThunk("/user/change_password", async (data, thunkApi) => {
  const { dispatch } = thunkApi;

  try {
    const userData = {
      passwords: data,
    };

    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/user/change_password`,
      userData
    );

    if (response.status === 200) {
      dispatch(
        addNotification({
          key: 1,
          message: "Password Changed Successfully",
          type: "info",
        })
      );
    }

    return response.data;
  } catch (error) {
    console.log("error", error);
    dispatch(
      addNotification({
        key: 1,
        message: "Wrong Old Password",
        type: "error",
      })
    );
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// 5. FOR DELETE ACCOUNT
// Importing addNotification from notifier remaining
export const deleteAccount = createAsyncThunk("/user/delete_account", async (id, thunkApi) => {
  const { dispatch } = thunkApi;

  try {
    const userData = {
      id: id,
    };
    dispatch(profileToggle(false));
    dispatch(logoutUser());

    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/user/delete_account`,
      userData
    );

    dispatch(
      addNotification({
        key: 1,
        message: "Your account has been deleted successfully!",
        type: "info",
      })
    );

    return response.data;
  } catch (error) {
    console.log("error", error);
    dispatch(
      addNotification({
        key: 1,
        message: "Something went wrong!",
        type: "error",
      })
    );
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// 6. FOR SEND-TEMP-PASSWORD
// import add Notification

export const sendTempPassword = createAsyncThunk("/user/temp_password", async (email, thunkApi) => {
  const { dispatch } = thunkApi;

  try {
    const data = {
      email: email,
    };
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/temp_password`, data);

    dispatch(
      addNotification({
        key: 1,
        message: "Temporary password sent to your email",
        type: "info",
      })
    );
    dispatch(userDialogToggle({ toggle: false, userAction: "login" }));

    return response.data;
  } catch (error) {
    dispatch(userDialogToggle({ toggle: false, userAction: "login" }));
    dispatch(
      addNotification({
        key: 1,
        message: "This email is not registered on our website",
        type: "error",
      })
    );
    console.log("error", error);
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// 7. registerWithOrder

export const registerWithOrder = createAsyncThunk("/user/register", async (formData, thunkApi) => {
  const { dispatch } = thunkApi;

  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/register`, formData);
    if (response.status === 200) {
      const token = response.data.token;
      const userData = response.data.user;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem("token", token);

      dispatch(
        addNotification({
          key: 1,
          message: "Registration Complete",
          type: "success",
        })
      );

      return userData;
    }
    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        key: 1,
        message: "Registration Failed:" + error,
        type: "error",
      })
    );
    console.log("error", error);
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// 8. ADD FAV ITEM

export const addFavItem = createAsyncThunk(
  "/user/add_favitem",
  async ({ userId, itemId }, thunkApi) => {
    try {
      const itemData = {
        userId: userId,
        itemId: itemId,
      };

      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/user/add_favitem`,
        itemData
      );

      return itemId;
    } catch (error) {
      console.log("error", error);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 9. REMOVE FAV ITEM

export const removeFavItem = createAsyncThunk(
  "/user/remove_favitem",
  async ({ userId, itemId }, thunkApi) => {
    try {
      const itemData = {
        userId: userId,
        itemId: itemId,
      };

      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/user/remove_favitem`,
        itemData
      );

      return itemId;
    } catch (error) {
      console.log("error", error);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 10. ADD ADDRESS

export const addAddress = createAsyncThunk(
  "/user/add_address",
  async ({ userId, formData }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const data = {
        userId: userId,
        formData: formData,
      };

      dispatch(addAddressLocal(formData));

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/add_address`, data);

      dispatch(
        addNotification({
          key: 1,
          message: "New address added successfully!",
          type: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          key: 1,
          message: "Error:" + error.response.data,
          type: "error",
        })
      );
      console.log("error", error);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 11. REMOVE ADDRESS

export const removeAddress = createAsyncThunk(
  "/user/remove_address",
  async ({ userId, addrId }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const data = {
        userId: userId,
        addrId: addrId,
      };

      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/user/remove_address`,
        data
      );

      dispatch(removeAddressLocal({ addrId }));

      dispatch(
        addNotification({
          key: 1,
          message: "Address removed successfully!",
          type: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          key: 1,
          message: "Error:" + error.response.data,
          type: "error",
        })
      );
      console.log("error", error);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 12. Select Address

export const selectAddress = createAsyncThunk(
  "user/select_address",
  async ({ userId, addrId }, thunkApi) => {
    const { dispatch } = thunkApi;
    const data = {
      userId: userId,
      addrId: addrId,
    };

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/user/select_address`,
        data
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
      dispatch(
        addNotification({
          key: 1,
          message: "Error:" + error.response?.data,
          type: "error",
        })
      );
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

// 13. UPDATE USER

export const updateUser = createAsyncThunk("user/update_user", async (userData, thunkApi) => {
  const { dispatch } = thunkApi;

  const data = {
    userData: userData,
  };

  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/update_user`, data);

    if (response.status === 200) {
      dispatch(updateUserLocal({ userData }));
    }
    dispatch(
      addNotification({
        key: 1,
        message: "Contact details updated",
        type: "success",
      })
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    dispatch(
      addNotification({
        key: 1,
        message: "Error:" + error.response.data,
        type: "error",
      })
    );
    return thunkApi.rejectWithValue(error.response.data);
  }
});

// 14. Verify Auth

export const VerifyAuth = createAsyncThunk(
  "user/verify",
  async ({ isUserToken, currUser, localOrders }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/user/verify`, { token });

        if (response.status === 200) {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          // dispatch(verifyAuthSuccess(userData));

          return dispatch(fetchUserOrders({ currUser: response.data, localOrders })).then(
            (response2) => {
              return { currUser: response.data, isUserToken, userOrders: response2.payload };
            }
          );

          // if (currUser) {
          //   dispatch(
          //     addNotification({
          //       key: 1,
          //       message: "Verified! ",
          //       type: "success",
          //     })
          //   );
          // }
        } else {
          localStorage.removeItem("token");
          dispatch(setLocalOrders([]));
          return thunkApi.rejectWithValue({ response: response.data, isUserToken });
          // return { response: response.data, isUserToken };
        }
      }
    } catch (error) {
      console.log("error", error);
      localStorage.removeItem("token");
      dispatch(setLocalOrders([]));
      dispatch(
        addNotification({
          key: 1,
          message: i18next.t("Login Expired"),
          // message: "Error: You are not verified",
          type: "error",
        })
      );

      console.log(" error.response.data", error.response.data);
      return thunkApi.rejectWithValue(error.message, isUserToken);
    }
  }
);

//  THE SLICE
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    deleteAccountToggle(state, action) {
      state.isDeleteAccount = action.payload;
    },
    userDialogToggle(state, action) {
      return {
        ...state,
        userDialogVisible: action.payload.toggle,
        userAction: action.payload.userAction,
      };
    },
    // not used
    loginUserLocal(state, action) {
      return {
        ...state,
        currUser: action.payload.user,
        userDialogVisible: false,
        loggedIn: true,
      };
    },
    logoutUser(state, action) {
      localStorage.removeItem("token");
      return {
        ...state,
        currUser: initialState.currUser,
        favItems: initialState.favItems,
        addresses: initialState.addresses,
        orders: initialState.orders,
        loggedIn: false,
      };
    },
    profileToggle(state, action) {
      return { ...state, profileVisible: action.payload };
    },
    addAddressLocal(state, action) {
      let prevSelected = state.addresses.filter((a) => a.selected);
      prevSelected = { ...prevSelected[0], selected: false };

      const withoutPrev = state.addresses.filter((a) => !a.selected);
      return {
        ...state,
        addresses: [...withoutPrev, prevSelected, action.payload],
      };
    },
    removeAddressLocal(state, action) {
      return {
        ...state,
        addresses: state.addresses.filter((addr) => addr.addrId !== action.payload.addrId),
      };
    },
    selectAddressLocal(state, action) {
      const addressLocal = state.addresses.map((addr) => {
        if (addr.addrId === action.payload.addrId) {
          addr.selected = true;
          return addr;
        } else {
          addr.selected = false;
          return addr;
        }
      });
      state.addresses = addressLocal;
      // return {
      //   ...state,
      //   addresses: addressLocal,
      // };
    },
    selectAddressOnLogin(state, action) {
      const id = action.payload.addrId;
      return {
        ...state,
        addresses: action.payload.addresses.map((addr) => {
          if (addr.addrId === id) {
            addr.selected = true;
            return addr;
          } else {
            addr.selected = false;
            return addr;
          }
        }),
      };
    },

    updateUserLocal(state, action) {
      // return {
      //   ...state,
      state.currUser = {
        ...state.currUser,
        name: action.payload.userData.name,
        phone: action.payload.userData.phone,
      };
      // };
    },
    acceptCookies(state, action) {
      return { ...state, cookieAccepted: true };
    },
    setFirstOrder(state, action) {
      return { ...state, orders: [action.payload] };
    },
    setFeedbackSent(state, action) {
      return { ...state, feedbackSent: action.payload };
    },
    setUserDevice(state, action) {
      return { ...state, userDevice: action.payload };
    },
    setOfferClose(state, action) {
      return { ...state, offerClosed: true };
    },
    hideSmartBar(state, action) {
      return { ...state, hideSmartBar: true, viewSmartBar: false };
    },
    setSmartBar(state, action) {
      return { ...state, viewSmartBar: action.payload.toggle };
    },
    setUserOrders(state, action) {
      return { ...state, orders: action.payload.orders };
    },
    setNotificationPermission(state, action) {
      return { ...state, notificationPermission: action.payload.notify };
    },
    updateUserOrders(state, action) {
      return { ...state, orders: [...state.orders, action.payload.newOrder] };
    },
    setAddingNewAddress(state, action) {
      return { ...state, addingNewAddress: action.payload };
    },
    setTheme(state, action) {
      return { ...state, theme: action.payload };
    },
    setUserTheme(state, action) {
      return { ...state, isUserTheme: action.payload };
    },
  },
  extraReducers: {
    // Login
    [loginUser.pending]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        userDialogVisible: true,
      };
    },
    [loginUser.fulfilled]: (state, action) => {
      return {
        ...state,
        currUser: {
          id: action.payload.user.id,
          name: action.payload.user.name,
          email: action.payload.user.email,
          phone: action.payload.user.phone,
        },
        addresses: action.payload.user.addresses,
        favItems: action.payload.user.favItems,
        orders: action.payload.user.orders === null ? [] : action.payload.user.orders,
        userDialogVisible: false,
        loggedIn: true,
        isLoading: false,
        error: null,
      };
    },
    [loginUser.rejected]: (state, action) => {
      return {
        ...state,
        currUser: null,
        addresses: null,
        favItems: null,
        orders: [],
        userAction: "login",
        userDialogVisible: true,
        loggedIn: false,
        isLoading: false,
        error: action?.payload?.error,
      };
    },

    // verifyAuth
    // --------------------------------------------------------------------
    [VerifyAuth.pending]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [VerifyAuth.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        currUser: action.payload.isUserToken
          ? {
              id: action.payload.currUser.id,
              name: action.payload.currUser.name,
              email: action.payload.currUser.email,
              phone: action.payload.currUser.phone,
            }
          : null,
        addresses: action.payload.isUserToken ? action.payload.currUser.addresses : null,
        favItems: action.payload.isUserToken ? action.payload.currUser.favItems : null,
        loggedIn: action.payload.isUserToken,
        error: null,
      };
    },
    [VerifyAuth.rejected]: (state, action) => {
      return {
        ...state,
        currUser: null,
        addresses: null,
        favItems: null,
        orders: [],
        userAction: action.isUserToken ? "login" : "",
        userDialogVisible: action.isUserToken,
        loggedIn: false,
        isLoading: false,
        error: action.error,
      };
    },
    // Fetch Orders
    // a little recheck
    [fetchUserOrders.pending]: (state, action) => {
      return {
        ...state,
        userOrderLoading: true,
      };
    },
    [fetchUserOrders.fulfilled]: (state, action) => {
      return {
        ...state,
        userOrderLoading: false,
      };
    },
    // Change Passwords
    [changePassword.pending]: (state, action) => {
      return {
        ...state,
        changeLoading: true,
      };
    },
    [changePassword.fulfilled]: (state, action) => {
      return {
        ...state,
        changeLoading: false,
      };
    },
    [changePassword.rejected]: (state, action) => {
      return {
        ...state,
        changeLoading: false,
        error: action?.payload?.error,
      };
    },
    // Delete Account
    [deleteAccount.pending]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [deleteAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        currUser: initialState.currUser,
        isLoading: false,
      };
    },
    [deleteAccount.rejected]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
      };
    },
    // REGISTER USER
    [registerUser.pending]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [registerUser.fulfilled]: (state, action) => {
      return {
        ...state,
        currUser: {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
        },
        addresses: action.payload.addresses,
        userDialogVisible: false,
        loggedIn: true,
        userAction: "",
        isLoading: false,
        error: null,
      };
    },
    [registerUser.rejected]: (state, action) => {
      return {
        ...state,
        currUser: null,
        userAction: "",
        userDialogVisible: true,
        loggedIn: false,
        isLoading: false,
        error: action.payload.error,
      };
    },
    // REGISTER WITH ORDER SUCCESS
    [registerWithOrder.fulfilled]: (state, action) => {
      // console.log('action.payload', action.payload)
      return {
        ...state,
        currUser: {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
        },
        addresses: action.payload.addresses,
        userDialogVisible: false,
        loggedIn: true,
        isLoading: false,
        error: null,
      };
    },
    // ADD FAV ITEM
    [addFavItem.fulfilled]: (state, action) => {
      return {
        ...state,
        favItems: state.favItems !== null ? [...state.favItems, action.payload] : [action.payload],
      };
    },
    // REMOVE FAV ITEM
    [removeFavItem.fulfilled]: (state, action) => {
      return {
        ...state,
        favItems: state.favItems.filter((favItem) => favItem !== action.payload),
      };
    },
  },
});

// Some Async Remaining ...

export const {
  userDialogToggle,
  loginUserLocal,
  logoutUser,
  profileToggle,
  addAddressLocal,
  removeAddressLocal,
  selectAddressLocal,
  updateUserLocal,
  acceptCookies,
  setFirstOrder,
  setFeedbackSent,
  setUserDevice,
  setOfferClose,
  hideSmartBar,
  setSmartBar,
  setUserOrders,
  setNotificationPermission,
  updateUserOrders,
  setAddingNewAddress,
  selectAddressOnLogin,
  setTheme,
  setUserTheme,
  deleteAccountToggle,
} = UserSlice.actions;
