import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const SocialIcons = () => {
  const settings = useSelector((state) => state.shop.settings);

  const socialLinks = settings?.socialLinks;

  if (socialLinks) {
    return (
      <Box className="mx-auto flex items-center justify-center  gap-11 mt-2">
        {socialLinks?.x !== "" && socialLinks?.x !== undefined && (
          <a href={socialLinks?.x} target="_blank" rel="noreferrer">
            <img
              style={{ height: "20px", width: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Ftwitter.png?alt=media&token=cad06d58-c5e6-4254-b02d-783d811f6020"
              alt="Facebook"
            />
          </a>
        )}
        {socialLinks?.instagram !== "" && socialLinks?.instagram !== undefined && (
          <a href={socialLinks?.instagram} target="_blank" rel="noreferrer">
            <img
              style={{ height: "30px", width: "30px" }}
              src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Finstagram.png?alt=media&token=d7059cb2-f39f-4d61-8c3e-48f89c905cce"
              alt="Facebook"
            />
          </a>
        )}
        {socialLinks?.facebook !== "" && socialLinks?.facebook !== undefined && (
          <a href={socialLinks?.facebook} target="_blank" rel="noreferrer">
            <img
              style={{ height: "25px", width: "30px" }}
              src={
                "https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Ffacebook.png?alt=media&token=4bc635de-6803-4200-8138-35a23756c3c5"
              }
              // src="/media/facebook.png"
              alt="Facebook"
            />
          </a>
        )}

        {socialLinks?.tiktok !== "" && socialLinks?.tiktok !== undefined && (
          <a href={socialLinks?.tiktok} target="_blank" rel="noreferrer">
            <img
              style={{ height: "30px", width: "30px" }}
              src={
                "https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Ftiktok.svg?alt=media&token=d80a447e-df2c-4a47-b06f-3216fcff1b98"
              }
              // src="/media/facebook.png"
              alt="Tiktok"
            />
          </a>
        )}
      </Box>
    );
  }
};

export default SocialIcons;
