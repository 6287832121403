import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import TimingsTable from "../ShopData/TimingsTable/TimingsTable";
import ShopMap from "../ShopMap/ShopMap";
import useWindowDimensions from "../../UI/ScreenSize/ScreenSize";

import {
  Slide,
  Paper,
  Typography,
  IconButton,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import "./ShopData.css";
import PaymentMethods from "../PaymentMethods/PaymentMethods";

import { useTranslation } from "react-i18next";
import { shopDataToggle } from "../../../ToolKit/Slices/ShopSlice";
import HolidayTimings from "./TimingsTable/HolidayTimings";
import Disclaimer from "./Disclaimer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShopData = () => {
  const { screenWidth } = useWindowDimensions();

  const { t } = useTranslation();

  const shopDataVisible = useSelector((state) => state.shop.shopDataVisible);
  const shopData = useSelector((state) => state.shop.shopData);
  const shopTimings = useSelector((state) => state.shop.shopTimings);
  const shopTimingsModified = useSelector((state) => state.shop.shopTimingsModified);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(shopDataVisible);

  const handleClose = () => {
    setOpen(false);
    dispatch(shopDataToggle(false));
  };

  const isOfficialData = () => {
    if (
      shopData.officials.ustId ||
      shopData.officials.registerEntry ||
      shopData.officials.registerCourt ||
      shopData.officials.registerNumber ||
      shopData.officials.insurance ||
      shopData.officials.officialApproval
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={screenWidth < 660 ? true : false}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className="DialogTitle" sx={{ backgroundColor: "background.default" }}>
          <Typography variant="h6">{shopData.name}</Typography>
          <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="DialogContent">
          <div className="shopDataContainer">
            <Paper elevation={2} className="ShopDataPaper w-full md:w-[91%]">
              <div className="ShopAddressMap">
                <div className="ShopMap">
                  <ShopMap />
                </div>
                <div className="ShopAddress">
                  <h4 style={{ color: "grey" }}>IMPRESSUM</h4>
                  {/* <h4 style={{ color: "grey" }}>{t("shopData.address")}</h4> */}
                  <p>
                    <b>{t("shopData.address")}: </b>
                    <br />
                    {shopData.street}
                    <br />
                    {shopData.postalCode} {shopData.city}
                  </p>
                  <p>
                    <b>{t("forms.Phone Number")}: </b>
                    {/* {shopData.phone} */}
                    <span className="flex gap-4">
                      {shopData.phoneNumbers.map((phoneNumber) => {
                        return <span>{phoneNumber.phone}</span>;
                      })}
                    </span>
                  </p>
                  {shopData.email && (
                    <p>
                      <b>{t("forms.E-Mail")}: </b> {shopData.email}
                    </p>
                  )}
                  {shopData.ownerName && (
                    <p>
                      <span  className="italic">
                        Der Betreiber vom Restaurant/Lieferservice ist für alle Inhalte auf diesem
                        Web-Shop verantwortlich. Dazu gehören Bilder und Texte.
                      </span>
                      <br />
                      <b>{t("Owner")}: </b>
                      {shopData.ownerName}
                    </p>
                  )}
                  {isOfficialData() && <br />}
                  {shopData.officials.ustId && (
                    <p>
                      <b>USt-ID: </b> {shopData.officials.ustId}
                    </p>
                  )}
                  {shopData.officials.registerEntry && (
                    <p>
                      <b>{t("officials.registerEntry")}: </b> {shopData.officials.registerEntry}
                    </p>
                  )}
                  {shopData.officials.registerNumber && (
                    <p>
                      <b>{t("officials.registerNum")}: </b> {shopData.officials.registerNumber}
                    </p>
                  )}
                  {shopData.officials.registerCourt && (
                    <p>
                      <b>{t("officials.registerCourt")}: </b> {shopData.officials.registerCourt}
                    </p>
                  )}
                  {shopData.officials.officialApproval && (
                    <p>
                      <b>{t("officials.approval")}: </b> {shopData.officials.officialApproval}
                    </p>
                  )}
                  {shopData.officials.insurance && (
                    <p>
                      <b>{t("officials.insurance")}: </b> {shopData.officials.insurance}
                    </p>
                  )}
                </div>
              </div>
            </Paper>
            <Paper elevation={2} className="ShopDataPaper">
              <div className="ShopTimming">
                <h4 style={{ color: "grey" }}>{t("shopData.shopTimings")}</h4>
                <TimingsTable timings={shopTimingsModified} />
              </div>
            </Paper>
            <Paper elevation={2} className="ShopDataPaper">
              <HolidayTimings />
            </Paper>
            <Paper elevation={2} className="ShopDataPaper">
              <div className="ShopPayments">
                <h4 style={{ color: "grey" }}>{t("shopData.paymentMethods")}</h4>
                <PaymentMethods />
              </div>
            </Paper>
            <Paper elevation={2} className="ShopDataPaper">
              <Disclaimer />
            </Paper>
            {/* <Paper elevation={2} className="ShopDataPaper">
              <div className="ShopAddressMap">
                <div className="ShopAddress">
                  <h4 style={{ color: "grey" }}>IMPRESSUM</h4>
                  {shopData.officials.ustId && (
                    <p>
                      <b>USt-ID: </b> {shopData.officials.ustId}
                    </p>
                  )}

                  {shopData.officials.registerEntry && (
                    <p>
                      <b>{t("officials.registerEntry")}: </b> {shopData.officials.registerEntry}
                    </p>
                  )}
                  {shopData.officials.registerNumber && (
                    <p>
                      <b>{t("officials.registerNum")}: </b> {shopData.officials.registerNumber}
                    </p>
                  )}
                  {shopData.officials.registerCourt && (
                    <p>
                      <b>{t("officials.registerCourt")}: </b> {shopData.officials.registerCourt}
                    </p>
                  )}
                  {shopData.officials.officialApproval && (
                    <p>
                      <b>{t("officials.approval")}: </b> {shopData.officials.officialApproval}
                    </p>
                  )}
                  {shopData.officials.insurance && (
                    <p>
                      <b>{t("officials.insurance")}: </b> {shopData.officials.insurance}
                    </p>
                  )}
                </div>
              </div>
            </Paper> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShopData;
