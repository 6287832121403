import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import { persistor, store } from "./ToolKit/Store/index";

import "./index.css";

import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "typeface-roboto";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import PulseLoader from "react-spinners/PulseLoader";
import ErrorFallbackSimple from "./Components/UI/Reporting/ErrorFallbackSimple";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setTheme, setUserTheme } from "./ToolKit/Slices/UserSlice";
import { useEffect } from "react";
import { useState } from "react";
import { SocketContext, superSocket } from "./context/socket";
import { BrowserRouter, Route, Routes } from "react-router-dom";

if (import.meta.env.MODE !== "dev") {
  Sentry.init({
    dsn: "https://d0b2331c9d01708a01edc74d3f7104f1@o4506472462811136.ingest.sentry.io/4506474525491200",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [`${import.meta.env.VITE_API_URL}/shop`],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// window.localStorage.setItem("expirationDate", "test");
// const currentMode = useSelector((state)=> state.user.theme)
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#ff5c8d",
      main: "#d81b60",
      dark: "#a00037",
      contrastText: "#fff",
    },
    secondary: {
      light: "#cfff95",
      main: "#9ccc65",
      dark: "#6b9b37",
      contrastText: "#000",
    },
    // addToCart: {
    //   light: "#cfff95",
    //   main: "#9ccc65",
    //   dark: "#9CCC65",
    //   contrastText: "#000"
    // }
  },
});

window.$priceFormater = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
}); //global variable

window.$dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

const Loader = () => (
  <div style={{ textAlign: "center", width: "100%", height: "100vh", marginTop: "200px" }}>
    <PulseLoader color={"#000"} />
  </div>
);

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("sw.js");
}

// console.log("i18n", i18n);

const RedirectPage = () => {
  return (
    <>
      <p>Hello from redirect page</p>
      <p>window location: {window.location.pathname}</p>
    </>
  );
};

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
function ToggleColorMode() {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.user.theme);
  const primaryColor = useSelector((state) => state.shop?.homepage?.primaryColor);

  const [primaryStatic, setPrimaryStatic] = useState({
    light: "#ff5c8d",
    main: "#d81b60",
    dark: "#a00037",
    contrastText: "#fff",
  });

  useEffect(() => {
    if (primaryColor) {
      setPrimaryStatic(primaryColor);
    }
  }, [primaryColor]);

  useEffect(() => {
    dispatch(setTheme(themeMode));
  }, [themeMode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        // dispatch(setTheme(themeMode === "light" ? "dark" : "light"));
        dispatch(setTheme(themeMode === "light" ? "dark" : "light"));
        dispatch(setUserTheme(true));
      },
    }),
    [themeMode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode,
          mode: themeMode,
          primary: primaryStatic,
          secondary: {
            light: "#cfff95",
            main: "#9ccc65",
            dark: "#6b9b37",
            contrastText: "#000",
          },
          // addToCart: {
          //   light: "#cfff95",
          //   main: "#9ccc65",
          //   dark: "#9CCC65",
          //   contrastText: "#000"
          // }
        },
      }),
    // [mode]
    [themeMode, primaryStatic]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {/* <App /> */}
        {/* <SeoData /> */}
        <BrowserRouter>
          <Routes>
            <Route index={true} path="/" element={<App />} />
            <Route path="/kiosk" element={<App />} />

            <Route path="/paymentPage" element={<RedirectPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <SocketContext.Provider value={superSocket}>
      <PersistGate persistor={persistor}>
        {/* <ThemeProvider theme={theme}> */}
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3500}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
            autoHideDuration: "3000",
          }}
          ref={notistackRef}
          action={(key) => <Button onClick={onClickDismiss(key)}>Schließen</Button>}
        >
          <Suspense fallback={<Loader />}>
            <I18nextProvider i18n={i18n}>
              <Sentry.ErrorBoundary fallback={<ErrorFallbackSimple />} showDialog>
                {/* <App /> */}
                <ToggleColorMode />
              </Sentry.ErrorBoundary>
            </I18nextProvider>
          </Suspense>
        </SnackbarProvider>
        {/* </ThemeProvider> */}
      </PersistGate>
    </SocketContext.Provider>
  </Provider>
);
