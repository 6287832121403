import React from "react";

import { FiAlertTriangle } from "react-icons/fi";
import "../../Cart/CartItem/CartItem.css";

const CartItem = (props) => {
  const { item, discounts, orderType, userDiscount, itemDiscount } = props;
  // console.log("-------------------------------------------------");
  // console.log("item", item);
  // console.log("userDiscount", userDiscount);
  // console.log("itemDiscount", itemDiscount);
  // console.log("-------------------------------------------------");
  const priceFormat = window.$priceFormater;
  const itemIndividualDiscount = itemDiscount?.discount ? itemDiscount?.discount : null;
  // console.log("itemIndividualDiscount", itemIndividualDiscount);
  const itemDiscountCheck = () => {
    let priceToRender = "";
    if (itemIndividualDiscount !== null && itemIndividualDiscount !== undefined) {
      // console.log("orderType", orderType);
      if (orderType === "delivery") {
        if (itemIndividualDiscount?.deliveryType === "amount") {
          // console.log("itemIndividualDiscount?.delivery", itemIndividualDiscount?.delivery);
          priceToRender = (
            <div className="flex ">
              <span>-</span>
              <span>{itemIndividualDiscount?.delivery}</span>
              <span>€</span>
            </div>
          );
          // return "-" + itemIndividualDiscount?.delivery + " €";
        } else if (itemIndividualDiscount?.deliveryType === "percentage") {
          priceToRender = (
            <div className="flex ">
              <span>-</span>
              <span>{itemIndividualDiscount?.delivery}</span>
              <span>%</span>
            </div>
          );
          // return "-" + itemIndividualDiscount?.delivery + " %";
        }
      } else if (orderType === "pickup") {
        if (itemIndividualDiscount?.pickupType === "amount") {
          priceToRender = (
            <div className="flex ">
              <span>-</span>
              <span>{itemIndividualDiscount?.pickup}</span>
              <span>€</span>
            </div>
          );
          // return "-" + itemIndividualDiscount?.pickup + " €";
        } else if (itemIndividualDiscount?.pickupType === "percentage") {
          priceToRender = (
            <div className="flex ">
              <span>-</span>
              <span>{itemIndividualDiscount?.pickup}</span>
              <span>%</span>
            </div>
          );
          // return "-" + itemIndividualDiscount?.pickup + " %";
        }
      }
    } else {
      priceToRender = "";
    }

    return priceToRender;
  };

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "left",
          margin: "2px",
          padding: "5px",
          display: "inline-flex",
          paddingBottom: "8px",
          width: "100%"
        }}
      >
        <span
          style={{
            // paddingRight: "15px",
            fontSize: "12px",
            paddingTop: "5px",
            minWidth: "24px",
          }}
        >
          {item.articleNo}
        </span>
        <div style={{ width: "65%" }}>
          <span
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              fontWeight: "bold",
            }}
          >
            {item.quantity}x
          </span>
          <span>{item.name}&nbsp; </span>
          <span style={{ fontSize: "10px" }}>{item.size ? "(" + item.size + ")" : null}</span>
          {item.toppings
            ? item.toppings.map((topping, index) => (
                <p
                  key={index}
                  style={{
                    fontSize: "12px",
                    listStyle: "none",
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  {topping.name} {priceFormat.format(topping.price)}
                </p>
              ))
            : null}
        </div>

        <div style={{ width: "40%" }}>
          <span style={{ float: "right" }}>
            {item.discPrice && item.totalDiscPrice !== item.totalPrice ? (
              <div>
                <span style={{ textDecoration: "line-through", fontSize: "11px" }}>
                  {priceFormat.format(item.totalPrice)}{" "}
                </span>
                <span>{priceFormat.format(item.totalDiscPrice)}</span>
              </div>
            ) : (
              <span>{priceFormat.format(item.totalPrice)}</span>
            )}
          </span>
          <span
            style={{
              fontSize: "12px",
              textAlign: "right",
              color: "#00bf5e",
              float: "right",
            }}
          >
            {item?.isDiscount
              ? itemDiscountCheck()
              : item.discPrice && item.totalDiscPrice !== item.totalPrice
              ? "-" +
                (orderType === "delivery"
                  ? userDiscount
                    ? userDiscount.deliveryDiscount
                    : discounts.deliveryDiscount
                  : userDiscount
                  ? userDiscount.pickupDiscount
                  : discounts.pickupDiscount) +
                "%"
              : null}
            {/* {item.discPrice
              ? "-" +
                (orderType === "delivery"
                  ? userDiscount
                    ? userDiscount.deliveryDiscount
                    : discounts.deliveryDiscount
                  : userDiscount
                  ? userDiscount.pickupDiscount
                  : discounts.pickupDiscount) +
                "%"
              : null} */}
          </span>
        </div>
      </div>

      {/* {item.id === 1 && (
        <span
          style={{
            fontSize: "12px",
            fontStyle: "italic",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            gap: "10px",
            marginBottom: "8px",
          }}
        >
          <FiAlertTriangle /> {item.chefNote}
        </span>
      )} */}

      {item.chefNote ? (
        <span
          style={{
            fontSize: "12px",
            fontStyle: "italic",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            gap: "10px",
            marginBottom: "8px",
          }}
        >
          <FiAlertTriangle /> {item.chefNote}
        </span>
      ) : null}
      <div
        style={{
          height: "1px",
          borderBottom: "1px solid #ccc",
        }}
      ></div>
    </React.Fragment>
  );
};

export default CartItem;
