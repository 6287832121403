import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import ShowTime from "./ShowTime";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../UI/ScreenSize/ScreenSize";

const HolidayTimings = () => {
  const { t } = useTranslation();
  const { screenWidth } = useWindowDimensions();

  const comingHolidays = useSelector((state) => state.shop.comingHolidays);

  const dateOptions = window.$dateOptions;

  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={"bold"}>{t("Holiday Timings")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="overflow-auto">
            {comingHolidays?.map((holiday) => {
              return (
                <>
                  {/* ${holiday?.timings.length > 1 && "flex-col"} */}
                  <Box
                    key={holiday?.date}
                    className={` grid ${screenWidth < 640 ? "grid-col-1" : "grid-cols-[70%_30%]"} p-2`}
                  >
                    {/* <Box key={holiday?.date} className={` flex items-center justify-between p-2`}> */}
                    <Box className="flex items-center gap-2 w-full">
                      <p style={{ fontSize: ".9rem", fontWeight: "900" }} className="">
                        {new Date(holiday?.date).toLocaleDateString("de-DE", dateOptions)}
                      </p>

                      <p style={{ fontSize: ".9rem", fontWeight: "900" }} className="text-left">
                        {holiday?.day}
                      </p>

                      <Typography style={{ fontSize: ".9rem" }} className="text-left">
                        ( {t(`days.${holiday?.holidayDay}`)})
                      </Typography>
                    </Box>
                    <Box className="w-full ">
                      {holiday?.open ? (
                        <>
                          <ShowTime timings={holiday?.timings} fontWeight={"400"} />
                          
                        </>
                      ) : (
                        t("shopTimings.closed")
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HolidayTimings;
