import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCountToppings = () => {
  // local state
  const [selectedToppingCount, setSelectedToppingCount] = useState([]);

  //   redux state
  const selectedToppings = useSelector((state) => state.topping.currToppings.toppings);


  // intializing temporary Array
  const tempArray = [];

  let duplicateToppings = selectedToppings;

  useEffect(() => {
    // loop through each topping

    duplicateToppings.map((element) => {
      // counting occurences of each of topping

      const tCount = duplicateToppings.filter((f) => f.tId === element.tId).length;
      // push to temporary Array
      tempArray.push({ item: element, count: tCount });
    });

    // removing duplicate occurences
    const itemTIds = tempArray.map((tA) => tA.item.tId);
    const filteredArray = tempArray.filter((f, index) => !itemTIds.includes(f.item.tId, index + 1));

    // setting filteredArray to toppingCount state(local)
    setSelectedToppingCount(filteredArray);
  }, [selectedToppings]);

  return { selectedToppingCount };
};

export default useCountToppings;
