import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "react-rating";

import ReactDOMServer from "react-dom/server";

import FeedBackHtml from "./FeedbackHtml";
import { useTranslation } from "react-i18next";
import { TextField, Button, useTheme, Box, Divider } from "@mui/material";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import { setFeedbackSent } from "../../../ToolKit/Slices/UserSlice";

function StarRatings(props) {
  const { order } = props;
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState();
  const [rating2, setRating2] = useState(0);
  const [comments2, setComments2] = useState();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop.shopData);
  const sendEmail = useSelector((state) =>
    state.shop.settings.sendFeedbackEmail ? state.shop.settings.sendFeedbackEmail : false
  );

  const handleSubmit = () => {
    setLoading(true);
    let feedbackHtml1 = null;
    let feedbackHtml2 = null;

    if (sendEmail) {
      feedbackHtml1 = ReactDOMServer.renderToString(
        <FeedBackHtml
          userData={order.userData}
          rating={rating}
          comments={comments}
          shop={shop}
          order={order}
        />
      );
    }

    if (sendEmail) {
      feedbackHtml2 = ReactDOMServer.renderToString(
        <FeedBackHtml
          userData={order.userData}
          rating={rating2}
          comments={comments2}
          shop={shop}
          order={order}
        />
      );
    }

    const data1 = {
      feedbackData: {
        user: {
          name: order.userData.name,
          email: order.userData.email,
          phone: order.userData.phone,
          orderId: order.id,
        },
        rating,
        comments,
        time: new Date(),
        feedbackFor: "shop",
      },

      feedbackHtml: feedbackHtml1,
    };

    const data2 = {
      feedbackData: {
        user: {
          name: order.userData.name,
          email: order.userData.email,
          phone: order.userData.phone,
          orderId: order.id,
        },
        rating: rating2,
        comments: comments2,
        time: new Date(),
        feedbackFor: "website",
      },

      feedbackHtml: feedbackHtml2,
    };
    axios
      .post(`${import.meta.env.VITE_API_URL}/feedbacks/add`, data1)
      .then((res) => {
        axios
          .post(`${import.meta.env.VITE_API_URL}/feedbacks/add`, data2)
          .then((res) => {
            // console.log("feddback added", res);
            setLoading(false);
            dispatch(setFeedbackSent(true));
          })
          .catch((err) => console.log("feedback failed", err));
      })
      .catch((err) => console.log("feedback failed", err));
  };

  const theme = useTheme();
  return (
    <div
      style={{
        display: "inline-grid",
        padding: "20px",
        width: "100%",
        textAlign: "center",
        // justifyContent: "center",
      }}
    >
      {loading ? (
        <div style={{ alignSelf: "center", textAlign: "center", padding: 30 }}>
          <PulseLoader color={theme.palette.primary.main} />
          {/* <p>{t("orderSummary.pleaseWait")}</p> */}
        </div>
      ) : (
        <React.Fragment>
          <p style={{ marginBottom: "16px", marginTop: "16px" }}>
            {t("feedback.pleaseRateYourExperience")}
          </p>
          {/* shop/service rating */}
          <Box>
            <Box className="flex flex-col items-start">
              <p className="font-bold"> {t("Leave your rating for food service")}</p>
              <Rating
                initialRating={rating}
                emptySymbol={<AiOutlineStar color="#5b5b5b" fontSize="30px" />}
                fullSymbol={<AiFillStar color={theme.palette.primary.main} fontSize="30px" />}
                onChange={(rate) => setRating(rate)}
              />
            </Box>
            <TextField
              fullWidth
              style={{ marginTop: "10px", marginBottom: "10px" }}
              variant="outlined"
              label={t("anySuggestionsShop")}
              multiline
              rows={2}
              size="small"
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          {/* website rating */}
          <Box>
            <Box className="flex flex-col items-start">
              <p className="font-bold"> {t("Leave your rating for website")}</p>
              <Rating
                initialRating={rating2}
                emptySymbol={<AiOutlineStar color="#5b5b5b" fontSize="30px" />}
                fullSymbol={<AiFillStar color={theme.palette.primary.main} fontSize="30px" />}
                onChange={(rate) => setRating2(rate)}
              />
            </Box>
            <TextField
              fullWidth
              style={{ marginTop: "10px", marginBottom: "10px" }}
              variant="outlined"
              label={t("anySuggestionsWebsite")}
              multiline
              rows={2}
              size="small"
              onChange={(e) => setComments2(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth={false}
            style={{ width: "40%", margin: "auto" }}
            color="secondary"
            disabled={rating === 0 || rating2 === 0 ? true : false}
            onClick={handleSubmit}
          >
            {t("button.submit")}
          </Button>
        </React.Fragment>
      )}
    </div>
  );
}

export default StarRatings;
