import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { compareValues, fillBestSeller } from "./utils";
import axios from "axios";

const initialState = {
  categories: [],
  items: null,
  prices: null,
  sizes: null,
  allergies: null,

  bestSellers: null,
  isCategoryManuallySelected: false,
  selectedCategory: null,
  categoryProgress: null,
  categoryToggle: false,
  totalShopCategories: null,
  loading: true,
  error: null,
  isCategorySideBar: false,
};

//   THUNKS

// 1. FETCH MENU

export const fetchMenu = createAsyncThunk("/menu", async (_, thunkApi) => {
  const { rejectWithValue } = thunkApi;
  try {
    // dispatch(fetchMenuRequest())
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/menu`);
    return response.data;
  } catch (error) {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    return rejectWithValue(error.response.data);
  }
});

//  THE SLICE

export const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setIsCategorySideBar(state, action) {
      state.isCategorySideBar = action.payload;
    },
    setIsCategoryManuallySelected(state, action) {
      state.isCategoryManuallySelected = action.payload;
    },
    setAllShopCategories(state, action) {
      state.totalShopCategories = action.payload;
    },
    increaseProgress(state, action) {
      state.categoryProgress = state.categoryProgress + 1;
    },
    decreaseProgress(state, action) {
      state.categoryProgress = state.categoryProgress - 1;
    },
    setProgress(state, action) {
      state.categoryProgress = action.payload;
    },
    viewCategory(state, action) {
      return {
        ...state,

        selectedCategory: state.categories.find((cat) => cat.id === action.payload),
        categoryToggle: true,
      };
    },
    viewFavCategory(state, action) {
      return {
        ...state,
        selectedCategory: {
          id: 123456,
          name: "Favorites",
          description: "",
          hasSize: true,
          image: "default.jpg",
        },
        categoryToggle: true,
      };
    },
    viewBestCategory(state, action) {
      return {
        ...state,
        selectedCategory: {
          id: 999,
          name: "best",
          description: "",
          hasSize: true,
          image: "default.jpg",
        },
        categoryToggle: true,
      };
    },
    viewSearchCategory(state, action) {
      return {
        ...state,
        selectedCategory: {
          id: 1111,
          name: "search",
          description: "Enter item name to search",
          hasSize: true,
          image: "default.jpg",
        },
        categoryToggle: true,
      };
    },
    viewFirstCategory(state, action) {
      return {
        ...state,
        selectedCategory: state.categories[0],
        categoryToggle: true,
      };
    },
  },
  extraReducers: {
    [fetchMenu.pending]: (state, action) => {
      return { ...state, loading: true };
    },
    [fetchMenu.fulfilled]: (state, action) => {
      const sortedCats = action.payload[0].filter((c) => c.isActive);
      const sortedItems = action.payload[1];
      return {
        ...state,
        categories: sortedCats,
        items: sortedItems,
        prices: action.payload[2],
        sizes: action.payload[3],
        allergies: action.payload[4],
        bestSellers: fillBestSeller(action.payload[1], sortedCats),
        selectedCategory: sortedCats.find((cat) => cat.isDefault === true)
          ? sortedCats.find((cat) => cat.isDefault === true)
          : sortedCats[0],
        loading: false,
        error: null,
      };
    },
    [fetchMenu.rejected]: (state, action) => {
      // console.log("payload menu slice", action);
      return {
        ...state,
        error: action?.error?.message || "Error",
        loading: false,
        categories: [],
        items: null,
        prices: null,
        allergies: null,
      };
    },
  },
});

// some asyn remaining

export const {
  setIsCategoryManuallySelected,
  setAllShopCategories,
  setProgress,
  increaseProgress,
  decreaseProgress,
  viewCategory,
  viewFavCategory,
  viewBestCategory,
  setIsCategorySideBar,
  viewSearchCategory,
  viewFirstCategory,
} = MenuSlice.actions;
