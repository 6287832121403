import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { TextField, Button, useTheme } from "@mui/material";
import { Done, Edit, Close, DeleteOutline } from "@mui/icons-material";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { addChefNote } from "../../../ToolKit/Slices/CartSlice";
// import ItemMsg from "../../UI/ToolTips/ItemMsg";

export const ChefNote = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  // const [note, setNote] = useState(props.chefNote);
  const [addNote, setAddNote] = useState(false);

  return (
    <div className="ChefNoteContainer" style={{ textAlign: "center", margin: "auto" }}>
      {addNote ? (
        <div style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            color="secondary"
            label={t("checkout.noteForChef")}
            placeholder={t("checkout.chefNotePlaceholder")}
            style={{ minWidth: "300px" }}
            fullWidth
            autoFocus
            multiline
            rows={2}
            size="small"
            value={props.chefNote}
            onChange={(e) => dispatch(addChefNote({ id: props.cartId, note: e.target.value }))}
          />
          <div style={{ textAlign: "center", padding: "5px" }}>
            <Button
              size="small"
              variant="outlined"
              style={{ margin: "5px", paddingRight: "18px" }}
              onClick={() => {
                dispatch(addChefNote({ id: props.cartId, note: "" }));
                setAddNote(false);
              }}
              endIcon={props.chefNote !== "" ? <DeleteOutline /> : <Close />}
            >
              {""}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              style={{ margin: "5px", paddingRight: "18px" }}
              disabled={props.chefNote !== "" ? false : true}
              onClick={() => {
                dispatch(addChefNote({ id: props.cartId, note: props.chefNote }));
                setAddNote(false);
              }}
              endIcon={<Done />}
            >
              {""}
            </Button>
          </div>
        </div>
      ) : props.chefNote ? (
        <div className="ChefNote">
          <b>{t("checkout.noteForChef")}</b>: {props.chefNote} &nbsp;
          <Edit
            fontSize="small"
            color="action"
            className="NoteIcons"
            onClick={() => setAddNote(true)}
          />
        </div>
      ) : (
        <Button
          startIcon={<FiEdit />}
          size="small"
          // variant="outlined"
          onClick={() => setAddNote(true)}
        >
          {t("checkout.noteForChef")}
        </Button>
        // <div onClick={() => setAddNote(true)} style={{ display: "contents" }}>
        //   {/* <FiEdit sx={{
        //     color:theme
        //   }} fontSize="20px" />
        //   <p className="NoteTip">{t("checkout.noteForChef")}</p> */}
        // </div>
      )}
    </div>
  );
};

export default ChefNote;
