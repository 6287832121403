import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../Slices/rootReducer";
import { persistStore } from "redux-persist";

// if (
//   localStorage.getItem("cacheExpiry") === null ||
//   localStorage.getItem("cacheExpiry") === undefined
// ) {
//   console.log("First time execution!!! new device detected...");
//   localStorage.clear();
// }

// const cacheExpiry = 168;
// // const cacheExpiry = 0.1;

// localStorage.setItem("cacheExpiry", cacheExpiry);
// const now = new Date().getTime();
// const startExpiryTime = localStorage.getItem("startExpiryTime");
// if (startExpiryTime == null || startExpiryTime == undefined) {
//   localStorage.setItem("startExpiryTime", now);
// } else {
//   // cacheExpiry * 60 * 60 * 1000
//   if (now - startExpiryTime > cacheExpiry * 60 * 60 * 1000) {
//     localStorage.clear();
//     localStorage.setItem("cacheExpiry", cacheExpiry);
//     localStorage.setItem("startExpiryTime", now);
//   }
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([]),
});
//  "version": "2.0.001"

const persistor = persistStore(store);

export { store, persistor };
